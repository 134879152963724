import React from "react";
import styled from "styled-components";
import { Grid, IconButton } from "@material-ui/core";
import {
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight
} from "@material-ui/icons";
import { IPaginationView } from "../models/shared/IShared";

export const PaginationWrapper = styled(Grid)`
  && {
    width: 300px;
  }
  & > span {
    margin-right: 15px;
  }
`;
PaginationWrapper.displayName = "PaginationWrapper";

export interface IPaginationComponent {
  isLoading?: boolean;
  totalItems: number;
  onChangePage: (postfix: string) => void;
  pagination: IPaginationView;
}
export const isDisabled = (
  link: string,
  current: string,
  isLoading?: boolean
): boolean =>
  !Boolean(link) ||
  Boolean(link && current && link === current) ||
  Boolean(isLoading);

export const Pagination: React.FunctionComponent<IPaginationComponent> = ({
  onChangePage,
  totalItems,
  isLoading,
  pagination: { first, last, next, previous, "@id": id }
}) => {
  const pageChangeHandler = (page: string) => (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onChangePage(page);
  };
  return (
    <PaginationWrapper container={true} alignItems="center">
      <span>Total items: {isLoading ? "..." : totalItems}</span>
      <IconButton
        disabled={isDisabled(first, id, isLoading)}
        onClick={pageChangeHandler(first)}>
        <FirstPage />
      </IconButton>
      <IconButton
        disabled={isDisabled(previous, id, isLoading)}
        onClick={pageChangeHandler(previous)}>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        disabled={isDisabled(next, id, isLoading)}
        onClick={pageChangeHandler(next)}>
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        disabled={isDisabled(last, id, isLoading)}
        onClick={pageChangeHandler(last)}>
        <LastPage />
      </IconButton>
    </PaginationWrapper>
  );
};
