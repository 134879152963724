import { AnyAction, Dispatch } from "redux";

export const ServiceUtilities = {
  getShortId: (id: string): string => {
    if (id.indexOf("/") > -1) {
      return id.split("/").pop() as string;
    }
    return id;
  },
  capitalizeFirstLetter: (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1)
};

export function createBlockableDispatch(
  dispatch: Dispatch<AnyAction>,
  key?: string
) {
  return (action: AnyAction): void => {
    dispatch({
      ...action,
      id: key
    });
  };
}
