import {
  calibrationVariants,
  IDevice,
  IDeviceCalibration,
  IDeviceCalibrationTable,
  initialDeviceCalibration,
  IDeviceCalibrationProcessGetFromApi,
  IDeviceCalibrationDetailsLoading
} from "../models/IDevice";
import { ActionType } from "../../models/Action.model";

export type deviceCalibrationReducerTypes =
  | { type: ActionType.INITIALIZE_CALIBRATION; payload: IDevice }
  | { type: ActionType.CALIBRATION_LOADING; payload: boolean }
  | {
      type: ActionType.GET_DEVICE_CALIBRATION_NEW_FORMULA;
      payload: IDeviceCalibrationProcessGetFromApi;
    }
  | {
      type: ActionType.SET_DEVICE_CALIBRATION_TABLE_FORM_VALUE;
      payload: IDeviceCalibrationTable;
    }
  | { type: ActionType.CALIBRATION_NEW_FORMULA_LOADING; payload: boolean }
  | {
      type: ActionType.CALIBRATION_HISTORY_DETAILS_LOADING;
      payload: IDeviceCalibrationDetailsLoading;
    }
  | {
      type: ActionType.UPDATE_DEVICE_CALIBRATION_VALUE_LOADING;
      payload: boolean;
    }
  | { type: ActionType.CLEAR_CALIBRATION_CURRENT_TABLE }
  | { type: ActionType.CLEAR_CALIBRATION }
  | {
      type: ActionType.GET_DEVICE_CALIBRATION_VALUE;
      payload: { index: number; value: string };
    }
  | {
      type: ActionType.GET_CREATE_CALIBRATION;
      payload: IDeviceCalibrationTable;
    };

export const deviceCalibrationReducer = (
  state: IDeviceCalibration = { ...initialDeviceCalibration },
  action: deviceCalibrationReducerTypes
): IDeviceCalibration => {
  switch (action.type) {
    case ActionType.INITIALIZE_CALIBRATION:
      state = {
        ...state,
        currentCalibrationTable: {
          ...state.currentCalibrationTable,
          calibrationVariant: calibrationVariants,
          date: "",
          deviceId: action.payload.shortId,
          parameter: "",
          parameterExpression: "",
          parameterUnit: "",
          parameters: action.payload.basicCalculationProperties || [],
          rows: [],
          selectedCalibrationVariant: [...calibrationVariants].pop() || ""
        }
      };
      break;

    case ActionType.GET_CREATE_CALIBRATION:
      state = {
        ...state,
        currentCalibrationTable: {
          ...state.currentCalibrationTable,
          parameterExpression: action.payload.formula,
          parameters: state.currentCalibrationTable.parameters.map(item => {
            if (
              item.name === action.payload.parameter &&
              item.method.kind === "BasicCalculation"
            ) {
              return {
                ...item,
                method: { ...item.method, expression: action.payload.formula }
              };
            }
            return item;
          })
        }
      };
      break;

    case ActionType.CALIBRATION_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
    case ActionType.SET_DEVICE_CALIBRATION_TABLE_FORM_VALUE:
      state = {
        ...state,
        currentCalibrationTable: {
          ...action.payload
        }
      };
      break;
    case ActionType.GET_DEVICE_CALIBRATION_NEW_FORMULA:
      state = {
        ...state,
        currentCalibrationTable: {
          ...state.currentCalibrationTable,
          formula: action.payload.newFormula
        }
      };
      break;
    case ActionType.CALIBRATION_NEW_FORMULA_LOADING:
      state = {
        ...state,
        calibrationFormulaLoading: action.payload
      };
      break;
    case ActionType.CLEAR_CALIBRATION_CURRENT_TABLE:
      state = {
        ...initialDeviceCalibration,
        currentCalibrationTable: {
          ...state.currentCalibrationTable,
          rows: [{ actualValue: "", deviceValue: "" }],
          formula: ""
        }
      };
      break;
    case ActionType.CLEAR_CALIBRATION:
      state = { ...initialDeviceCalibration };
      break;
    case ActionType.UPDATE_DEVICE_CALIBRATION_VALUE_LOADING:
      state = {
        ...state,
        calibrationDeviceValueLoading: action.payload
      };
      break;
    case ActionType.GET_DEVICE_CALIBRATION_VALUE:
      state = {
        ...state,
        currentCalibrationTable: {
          ...state.currentCalibrationTable,
          rows: state.currentCalibrationTable.rows.map((item, index) => {
            return index === action.payload.index
              ? { ...item, deviceValue: action.payload.value }
              : item;
          })
        }
      };
      break;
  }
  return state;
};
