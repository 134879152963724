import React from "react";
import { Card } from "@material-ui/core";
import styled from "styled-components";

import { theme } from "../../util/withThemeProvider";

export const HightlightedCard = styled(Card)`
  &&{
  border-radius: 0;
  border: 1px solid ${theme.palette.primary.main}
  padding: ${theme.spacing(2)}px ${theme.spacing()}px ${theme.spacing()}px;
  overflow: inherit;
  }
`;
HightlightedCard.displayName = "HightlightedCard";
export const HighlightedCardTitle = styled.div`
  position: absolute;
  top: -${theme.spacing()}px;
  color: ${theme.palette.primary.main}
  left: 20px;
  background: white;
  padding: 0 8px;
  font-size: 0.8rem;
`;
HighlightedCardTitle.displayName = "HighlightedCardTitle";

export const HighlightedBoxContainer = styled.div`
  position: relative;
`;
HighlightedBoxContainer.displayName = "HighlightedBoxContainer";

interface IHighlightedBox {
  title?: string;
}

export const HighlightedBox: React.FunctionComponent<IHighlightedBox> = ({
  children,
  title
}) => (
  <HighlightedBoxContainer>
    {title && <HighlightedCardTitle>{title}</HighlightedCardTitle>}
    <HightlightedCard>{children}</HightlightedCard>
  </HighlightedBoxContainer>
);
HighlightedBox.displayName = "HighlightedBox";
