import React from "react";
import { FieldArray, Field, Form, FormikProps } from "formik";
import {
  Button,
  LinearProgress,
  NoSsr,
  Grid,
  Typography,
  CardContent
} from "@material-ui/core";
import { Help } from "@material-ui/icons";

import { IDeviceModel } from "../models/IDeviceModel";
import { Validators } from "../../util/validators";
import { StyledTextField, TextField } from "../../components/TextField";
import {
  DeviceModelPayloadFieldParametersFormArray,
  DeviceModelBasicCalculationParametersFormArray,
  DeviceModelPayloadFormatFormArray,
  DeviceModelValueParametersFormArray,
  DeviceModelDownlinkFormatFormArray
} from "./DeviceModelParameters";
import {
  StyledTitle,
  StyledAutocompleteSelectLabel,
  StyledPayloadFormatsCard
} from "../../components/sharedStyledComponents";
import {
  AutocompleteSelect,
  StyledAutocomplete
} from "../../components/AutocompleteSelect";
import { initialDeviceModel } from "../models/IDeviceModel";

export const EditDeviceModelForm: React.FunctionComponent<
  FormikProps<IDeviceModel>
> = ({ submitForm, isSubmitting, values }: FormikProps<IDeviceModel>) => {
  const { isLoading, subscriptionList } = values;
  const { name, description, subscription } = Validators.editDeviceModel;

  return (
    <Form>
      <StyledTextField
        type="text"
        label="Name"
        name="name"
        multiline={false}
        component={TextField}
        disabled={isSubmitting || isLoading}
        value={values.name}
        validate={name}
      />
      <br />
      <StyledTextField
        type="text"
        label="Description"
        name="description"
        multiline={true}
        component={TextField}
        disabled={isSubmitting || isLoading}
        value={values.description}
        validate={description}
      />
      <br />
      <br />
      <NoSsr>
        {Boolean(values.subscription.id) && !isLoading && (
          <StyledAutocompleteSelectLabel htmlFor="subscription">
            Subscription
          </StyledAutocompleteSelectLabel>
        )}

        <StyledAutocomplete>
          <Field
            name="subscription"
            options={subscriptionList}
            component={AutocompleteSelect}
            isDisabled={isSubmitting || isLoading}
            validate={subscription}
            labelField="name"
            identityKey="id"
            placeholder="Select Subscription"
            isClearable={true}
            clearValue={initialDeviceModel}
          />
        </StyledAutocomplete>
      </NoSsr>

      <br />

      <StyledTitle>DEVICEMODEL PARAMETERS</StyledTitle>
      <br />
      <Typography variant="body1">Payload Formats</Typography>
      <br />
      <Grid container>
        <Grid item xs={12} sm={10}>
          <StyledPayloadFormatsCard>
            <CardContent>
              <Grid container wrap="nowrap" spacing={8}>
                <Grid item>
                  <Help color="primary" />
                </Grid>
                <Grid item xs>
                  <Typography color="primary">
                    {`Payload format contains one or more payload parameters with format: <ParameterName>:<DataType>:[OptionalValue]`}
                    <br />
                    {`For example: “PayloadType:uint8:0 PressureRaw:int16: TemperatureRaw:int16: ChargeLevelRaw:uint16_le:”`}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </StyledPayloadFormatsCard>
        </Grid>
      </Grid>
      <br />
      <FieldArray
        name="payloadFormats"
        render={DeviceModelPayloadFormatFormArray}
      />
      <br />
      <br />

      <Typography variant="body1">Downlink Formats</Typography>
      <br />
      <FieldArray
        name="downlinkFormats"
        render={DeviceModelDownlinkFormatFormArray}
      />
      <br />
      <br />

      <Typography variant="body1">Payload Parameters</Typography>
      <br />
      <FieldArray
        name="payloadFieldProperties"
        render={DeviceModelPayloadFieldParametersFormArray}
      />
      <br />
      <br />

      <Typography variant="body1">Value Parameters</Typography>
      <br />
      <FieldArray
        name="valueParameters"
        render={DeviceModelValueParametersFormArray}
      />
      <br />
      <br />

      <Typography variant="body1">Calculated Parameters</Typography>
      <br />
      <FieldArray
        name="basicCalculationProperties"
        render={DeviceModelBasicCalculationParametersFormArray}
      />
      {(isSubmitting || isLoading) && <LinearProgress />}

      <br />
      <Grid
        container={true}
        item={true}
        direction="row"
        justify="flex-start"
        alignItems="center">
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting || isLoading}
          onClick={submitForm}>
          Save
        </Button>
      </Grid>
    </Form>
  );
};
