import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

import { withThemeProvider } from "../util/withThemeProvider";
import {
  StyledBackground,
  StyledTitle,
  StyledFormWrapper
} from "../components/sharedStyledComponents";
import { IRootState } from "../store";
import { IUserProfile } from "../Profile/models/IUserProfile";

interface IDashboardStateProps {
  profile: IUserProfile;
}

export const DashboardStyledBackground = styled(StyledBackground)`
  && {
    padding-bottom: 400px;
  }
`;
DashboardStyledBackground.displayName = "DashboardStyledBackground";

export class Dashboard extends React.Component<IDashboardStateProps> {
  public render() {
    const {
      profile: { name }
    } = this.props;
    return (
      <React.Fragment>
        <StyledFormWrapper>
          <StyledTitle marginbottom="10px">Dashboard</StyledTitle>
          <DashboardStyledBackground>
            <Typography variant="h5" align="center">
              Welcome, {name}
            </Typography>
          </DashboardStyledBackground>
        </StyledFormWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IDashboardStateProps => ({
  profile: state.userProfileReducer
});

export default connect(
  mapStateToProps,
  null
)(withThemeProvider(Dashboard));
