import React from "react";
import { connect } from "react-redux";
import {
  Grid,
  Hidden,
  Button,
  LinearProgress,
  FormControl
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Select from "react-select";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { ValueType } from "react-select/src/types";

import { IRootState } from "../store";
import { withThemeProvider } from "../util/withThemeProvider";
import { IRoleCollection, IRole } from "./models/IRole";
import { ITableDeleteCell } from "../models/shared/IShared";
import {
  getRoleCollection,
  changeRoleCollectionPage,
  setSubscriptionSelectionValue
} from "./actions/roleCollectionAction";
import { MobileTable } from "../components/MobileTable/MobileTable";
import { RolesTable } from "./components/RolesTable";
import {
  NoStyleLink,
  StyledTitle,
  StyledFormWrapper,
  NewStyledPaper
} from "../components/sharedStyledComponents";
import {
  alertMessages,
  SnackbarError,
  isSnackbarError,
  getStyledSnackbarOptions
} from "../core/utilities/AlertUtilities";
import { RoleDeleteDialog } from "./components/RoleDeleteDialog";
import { roleDeleteConfirmDialog, deleteRole } from "./actions/roleAction";
import {
  StyledInputLabel,
  SubSelectorForm
} from "../UsersBySubscription/UsersBySubscription";
import { IGlobalState } from "../models/GlobalState";
import { ISubscriptionSelection } from "../models/shared/IShared";
import { IUserProfile } from "../Profile/models/IUserProfile";
import { rolesToMobileTableRow } from "./services/RoleService";

interface IRolesStateProps {
  roleCollection: IRoleCollection;
  role: IRole;
  global: IGlobalState;
  profile: IUserProfile;
}

interface IRolesDispatchProps {
  getRoleCollection: (
    subId: string,
    masterAdmin: boolean
  ) => Promise<IRoleCollection | SnackbarError>;
  changePage: (
    page: string,
    subId: string,
    masterAdmin: boolean
  ) => Promise<IRoleCollection | SnackbarError>;
  roleDeleteConfirmDialog: (roleInfo: ITableDeleteCell) => void;
  deleteRole: (roleInfo: ITableDeleteCell) => Promise<number | SnackbarError>;
  setSubscriptionSelectionValue: (
    selectedValue: ISubscriptionSelection
  ) => void;
}

type RolesType = IRolesStateProps & IRolesDispatchProps & WithSnackbarProps;

export class Roles extends React.Component<RolesType> {
  public fetchRoles = async (page?: string) => {
    const {
      getRoleCollection,
      changePage,
      enqueueSnackbar,
      roleCollection: {
        subscriptionSelection: { value: roleSubscriptionSelectionValue }
      },
      global: {
        masterAdmin,
        user: { subId }
      }
    } = this.props;
    let collection: IRoleCollection | SnackbarError | undefined;
    if (page) {
      collection = await changePage(
        page,
        roleSubscriptionSelectionValue || subId,
        masterAdmin
      );
    } else {
      collection = await getRoleCollection(
        roleSubscriptionSelectionValue || subId,
        masterAdmin
      );
    }
    if (collection && isSnackbarError(collection)) {
      enqueueSnackbar(collection.message, collection.options);
    }
  };

  public changePage = (page: string) => {
    this.fetchRoles(page);
  };

  public async componentDidMount() {
    this.fetchRoles();
  }

  public handleRoleDeleteDialogOpen = (roleInfo: ITableDeleteCell) => (
    _: React.MouseEvent
  ) => {
    const { roleDeleteConfirmDialog } = this.props;
    roleDeleteConfirmDialog(roleInfo);
  };

  public handleRoleDeleteDialogClose = (roleInfo: ITableDeleteCell) => {
    const { roleDeleteConfirmDialog } = this.props;
    roleDeleteConfirmDialog({ ...roleInfo, visible: false });
  };

  public deleteRole = async (roleInfo: ITableDeleteCell) => {
    const { deleteRole, enqueueSnackbar } = this.props;
    const deleteRoleResult = await deleteRole(roleInfo);
    if (deleteRoleResult === 204) {
      enqueueSnackbar(
        alertMessages.ROLE_DELETE_SUCCESS,
        getStyledSnackbarOptions("success")
      );
    }
    if (isSnackbarError(deleteRoleResult)) {
      enqueueSnackbar(deleteRoleResult.message, deleteRoleResult.options);
    }
  };

  public handleChange = (
    selectedValue: ValueType<{ value: string; label: string }>
  ) => {
    const {
      getRoleCollection,
      setSubscriptionSelectionValue,
      roleCollection: {
        subscriptionSelection: { value: subscriptionSelectionValue }
      },
      global: { masterAdmin }
    } = this.props;

    if (
      selectedValue &&
      selectedValue["value"] !== subscriptionSelectionValue
    ) {
      setSubscriptionSelectionValue({
        label: selectedValue["label"],
        value: selectedValue["value"]
      });
      getRoleCollection(selectedValue["value"], masterAdmin);
    }
  };

  public render() {
    const {
      roleCollection: {
        members,
        view,
        totalItems,
        isLoading,
        subscriptionSelectionList,
        subscriptionSelection
      },
      role: { roleDelete, isLoading: roleIsLoading },
      global: { masterAdmin },
      profile: {
        activeSubscription: { name }
      }
    } = this.props;

    return (
      <React.Fragment>
        <StyledFormWrapper>
          <Grid container={true}>
            <Grid item={true} xs={5} sm={8} md={8}>
              {masterAdmin ? (
                <StyledTitle margintop="8px">
                  {`Roles of "${subscriptionSelection.label || "..."}"`}
                </StyledTitle>
              ) : (
                <StyledTitle margintop="8px">
                  {`Roles of "${name || "..."}"`}
                </StyledTitle>
              )}
            </Grid>
            <Grid item={true} xs={3} sm={1} md={2} />
            <Grid item={true} xs={4} sm={3} md={2}>
              <StyledTitle textalign="right">
                <NoStyleLink to="/roles/create">
                  <Button color="default">
                    <AddIcon />
                    Create
                  </Button>
                </NoStyleLink>
              </StyledTitle>
            </Grid>
          </Grid>
          <NewStyledPaper>
            {masterAdmin && (
              <React.Fragment>
                <StyledInputLabel htmlFor="subscription">
                  subscription
                </StyledInputLabel>
                <SubSelectorForm>
                  <FormControl>
                    <Select
                      options={subscriptionSelectionList}
                      value={subscriptionSelection}
                      onChange={this.handleChange}
                      isClearable={false}
                      isDisabled={isLoading}
                    />
                  </FormControl>
                </SubSelectorForm>
              </React.Fragment>
            )}

            <Hidden smDown={true}>
              <RolesTable
                isLoading={isLoading}
                pagination={view}
                rows={members}
                totalItems={totalItems}
                onChangePage={this.changePage}
                handleDialogOpen={this.handleRoleDeleteDialogOpen}
              />
            </Hidden>
            <Hidden mdUp={true}>
              <MobileTable
                rows={rolesToMobileTableRow(members)}
                pagination={view}
                totalItems={totalItems}
                onChangePage={this.changePage}
                handleDialogOpen={this.handleRoleDeleteDialogOpen}
                isLoading={isLoading}
              />
            </Hidden>
            {isLoading && (
              <React.Fragment>
                <br /> <LinearProgress /> <br />
              </React.Fragment>
            )}
          </NewStyledPaper>
        </StyledFormWrapper>
        <br />
        <br />
        <RoleDeleteDialog
          roleInfo={roleDelete}
          handleDeleteDialogClose={this.handleRoleDeleteDialogClose}
          deleteRole={this.deleteRole}
          isLoading={roleIsLoading}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IRolesStateProps => ({
  roleCollection: state.roleCollection,
  role: state.role,
  global: state.globalState,
  profile: state.userProfileReducer
});

const mapDispatchToProps = (dispatch: any): IRolesDispatchProps => {
  return {
    getRoleCollection: (
      subId: string,
      masterAdmin: boolean
    ): Promise<IRoleCollection | SnackbarError> => {
      return dispatch(getRoleCollection(subId, masterAdmin));
    },
    changePage: (
      page: string,
      subId: string,
      masterAdmin: boolean
    ): Promise<IRoleCollection | SnackbarError> => {
      return dispatch(changeRoleCollectionPage(page, subId, masterAdmin));
    },
    roleDeleteConfirmDialog: (roleInfo: ITableDeleteCell) => {
      return dispatch(roleDeleteConfirmDialog(roleInfo));
    },
    deleteRole: (
      roleInfo: ITableDeleteCell
    ): Promise<number | SnackbarError> => {
      return dispatch(deleteRole(roleInfo));
    },
    setSubscriptionSelectionValue: (selectedValue: ISubscriptionSelection) => {
      dispatch(setSubscriptionSelectionValue(selectedValue));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(Roles)));
