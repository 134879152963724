import React from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import { Typography, Card, CardActions, IconButton } from "@material-ui/core";
import { Close as CloseIcon, Warning } from "@material-ui/icons";

export const StyledWrapper = styled.div`
  && {
    @media (min-width: 960) {
      min-width: 288px;
      max-width: 568px;
      border-radius: 4px;
    }

    @media (max-width: 960) {
      flex-grow: 1;
    }
  }
`;
StyledWrapper.displayName = "StyledWrapper";

export const StyledCardActions = styled(CardActions)`
  && {
    padding: 8px 8px 8px 16px;
    background-color: #ffa000;
  }
`;
StyledCardActions.displayName = "StyledCardActions";

interface IStyledDiv {
  marginright?: string;
  marginleft?: string;
}

export const StyledDiv = styled.div<IStyledDiv>`
  && {
    ${props => (props.marginright ? `margin-right: ${props.marginright}` : "")};
    ${props => (props.marginleft ? `margin-left: ${props.marginleft}` : "")}
  }
`;
StyledDiv.displayName = "StyledDiv";

export const StyledTypography = styled(Typography)`
  && {
    color: white;
  }
`;
StyledTypography.displayName = "StyledTypography";

export const StyledWarningIcon = styled(Warning)`
  && {
    color: white;
    font-size: 20px;
  }
`;
StyledWarningIcon.displayName = "StyledWarningIcon";

export const StyledCloseIcon = styled(CloseIcon)`
  && {
    color: white;
  }
`;
StyledCloseIcon.displayName = "StyledCloseIcon";

interface IProps {
  id: string | number | undefined;
  errorResource: string;
  errorURL: string;
  activeSub: string;
  activeRole: string;
}

export const CustomAccessDeniedMessage = React.forwardRef(
  ({ id, errorResource, errorURL, activeSub, activeRole }: IProps, ref) => {
    const { closeSnackbar } = useSnackbar();
    const handleClose = () => {
      closeSnackbar(id);
    };

    return (
      <StyledWrapper>
        <Card ref={ref}>
          <StyledCardActions>
            <StyledDiv marginright="8px">
              <StyledWarningIcon />
            </StyledDiv>
            <StyledTypography>
              As <b>"{activeRole}"</b> of subscription <b>"{activeSub}"</b>, you
              do not have access to <b>{errorResource}</b> ({errorURL}). Please,
              contact your administrator if you need access.
            </StyledTypography>
            <StyledDiv marginleft="auto">
              <IconButton onClick={handleClose}>
                <StyledCloseIcon />
              </IconButton>
            </StyledDiv>
          </StyledCardActions>
        </Card>
      </StyledWrapper>
    );
  }
);
