import React from "react";
import { connect } from "react-redux";
import { Grid, Hidden, Button, LinearProgress } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { withSnackbar, WithSnackbarProps } from "notistack";

import { IRootState } from "../store";
import { withThemeProvider } from "../util/withThemeProvider";
import { IUserCollection } from "./models/IUser";
import {
  getUserCollection,
  changeUserCollectionPage
} from "./actions/UserCollectionAction";
import { UserTable } from "./components/UserTable";
import {
  NoStyleLink,
  StyledTitle,
  StyledFormWrapper,
  NewStyledPaper
} from "../components/sharedStyledComponents";
import {
  SnackbarError,
  isSnackbarError
} from "../core/utilities/AlertUtilities";
import { IGlobalState } from "../models/GlobalState";
import { usersToMobileTableRow } from "./services/UserService";
import { MobileTable } from "../components/MobileTable/MobileTable";

interface IUserStateProps {
  userCollection: IUserCollection;
  global: IGlobalState;
}

interface IUserDispatchProps {
  getUserCollection: () => Promise<IUserCollection | SnackbarError>;
  changePage: (page: string) => Promise<IUserCollection | SnackbarError>;
}

export type UsersType = IUserStateProps &
  IUserDispatchProps &
  WithSnackbarProps;

export class Users extends React.Component<UsersType> {
  public fetchUsers = async (page?: string) => {
    const { changePage, getUserCollection, enqueueSnackbar } = this.props;
    let collection: IUserCollection | SnackbarError | undefined;
    if (page) {
      collection = await changePage(page);
    } else {
      collection = await getUserCollection();
    }
    if (collection && isSnackbarError(collection)) {
      enqueueSnackbar(collection.message, collection.options);
    }
  };

  public changePage = (page: string) => {
    this.fetchUsers(page);
  };

  public async componentDidMount() {
    this.fetchUsers();
  }

  public render() {
    const {
      userCollection: { members, view, totalItems, isLoading },
      global: { normalUser }
    } = this.props;

    return (
      <React.Fragment>
        <StyledFormWrapper>
          <Grid container={true}>
            <Grid item={true} xs={3} sm={2} md={2}>
              <StyledTitle
                margintop="8px"
                marginbottom={normalUser ? "10px" : ""}
              >
                Users
              </StyledTitle>
            </Grid>
            <Grid item={true} xs={5} sm={7} md={8} />
            <Grid item={true} xs={4} sm={3} md={2}>
              {!normalUser && (
                <StyledTitle textalign="right">
                  <NoStyleLink to="/users/create">
                    <Button color="default">
                      <AddIcon />
                      Create
                    </Button>
                  </NoStyleLink>
                </StyledTitle>
              )}
            </Grid>
          </Grid>
          <NewStyledPaper>
            <Hidden smDown={true}>
              <UserTable
                isLoading={isLoading}
                pagination={view}
                rows={members}
                totalItems={totalItems}
                onChangePage={this.changePage}
              />
            </Hidden>
            <Hidden mdUp={true}>
              <MobileTable
                rows={usersToMobileTableRow(members)}
                pagination={view}
                totalItems={totalItems}
                onChangePage={this.changePage}
                isLoading={isLoading}
              />
            </Hidden>

            {isLoading && (
              <React.Fragment>
                <br /> <LinearProgress /> <br />
              </React.Fragment>
            )}
          </NewStyledPaper>
        </StyledFormWrapper>
        <br />
        <br />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IUserStateProps => ({
  userCollection: state.userCollection,
  global: state.globalState
});

const mapDispatchToProps = (dispatch: any): IUserDispatchProps => {
  return {
    getUserCollection: (): Promise<IUserCollection | SnackbarError> => {
      return dispatch(getUserCollection());
    },
    changePage: (page: string): Promise<IUserCollection | SnackbarError> => {
      return dispatch(changeUserCollectionPage(page));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(Users)));
