import axios, { AxiosResponse } from "axios";

import { ActionType } from "../../models/Action.model";
import { IRole, IRoleApi, initialRole } from "../models/IRole";
import { ITableDeleteCell } from "../../models/shared/IShared";
import {
  Configuration,
  eramaApiHeaders,
  esmaApiHeaders,
  rolesPrefixURL,
  subscriptionsPrefixURL
} from "../../config";
import { Thunk } from "../../store";
import { roleReducerTypes } from "../reducers/roleReducer";
import { roleFromApi, roleToApi } from "../services/RoleService";
import {
  createBlockableDispatch,
  ServiceUtilities
} from "../../core/utilities/ServiceUtilities";
import {
  subscriptionCollectionFromApi,
  subscriptionFromApi
} from "../../Subscriptions/services/SubscriptionService";
import { policyCollectionFromApi } from "../../Policies/services/PolicyService";
import { checkError, SnackbarError } from "../../core/utilities/AlertUtilities";
import { getRoleCollection } from "./roleCollectionAction";

export const getRoleById: Thunk<roleReducerTypes> = (
  id: string,
  initializedRole?: IRole
) => {
  return async (dispatch, _, opt): Promise<IRole | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.ROLE_LOADING,
      payload: true
    });
    try {
      const response: AxiosResponse<IRoleApi> = await axios.get(
        Configuration.EramaAPIBaseUrl + rolesPrefixURL + id,
        eramaApiHeaders
      );

      const role = roleFromApi(response.data, initializedRole);

      if (role.subscription.name === "") {
        try {
          const responseSubById = await axios.get(
            Configuration.EsmaAPIBaseUrl + role.subscription.id,
            esmaApiHeaders
          );

          role.subscription = subscriptionFromApi(responseSubById.data);
        } catch (err) {
          return checkError(err);
        }
      }

      blockableDispatch({
        type: ActionType.GET_ROLE,
        payload: role
      });
      return role;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.ROLE_LOADING,
        payload: false
      });
    }
  };
};

export const initializeRole: Thunk<roleReducerTypes> = (
  setLoadingState: boolean,
  subId: string,
  masterAdmin: boolean
) => {
  return async (dispatch, _, opt): Promise<IRole | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.CLEAR_ROLE
    });
    dispatch({
      type: ActionType.ROLE_LOADING,
      payload: true
    });
    try {
      let response;
      if (masterAdmin) {
        response = await axios.all([
          axios.get(
            Configuration.EsmaAPIUrl + "/subscriptions",
            esmaApiHeaders
          ),
          axios.get(Configuration.EramaAPIUrl + "/policies", {
            ...eramaApiHeaders,
            params: {
              subscription:
                subscriptionsPrefixURL + ServiceUtilities.getShortId(subId)
            }
          })
        ]);
      } else {
        response = await axios.all([
          axios.get(
            Configuration.EsmaAPIUrl + "/subscriptions",
            esmaApiHeaders
          ),
          axios.get(Configuration.EramaAPIUrl + "/policies", eramaApiHeaders)
        ]);
      }

      const role = { ...initialRole };
      role.subscriptionList = subscriptionCollectionFromApi(
        response[0].data
      ).members;
      role.policyList = policyCollectionFromApi(response[1].data).members;

      blockableDispatch({
        type: ActionType.INITIALIZE_ROLE_EDIT,
        payload: role
      });
      return role;
    } catch (err) {
      return checkError(err);
    } finally {
      if (setLoadingState) {
        dispatch({
          type: ActionType.ROLE_LOADING,
          payload: false
        });
      }
    }
  };
};

export const updateRoleById: Thunk<roleReducerTypes> = (
  id: string,
  role: IRole
) => {
  return async (dispatch): Promise<IRole | SnackbarError> => {
    dispatch({
      type: ActionType.SET_ROLE_FORM_VALUE,
      payload: role
    });
    dispatch({
      type: ActionType.ROLE_LOADING,
      payload: true
    });
    try {
      const response: AxiosResponse<IRoleApi> = await axios.put(
        Configuration.EramaAPIBaseUrl + id,
        roleToApi(role),
        eramaApiHeaders
      );

      const updatedRole = roleFromApi(response.data, role);

      if (updatedRole.subscription.name === "") {
        try {
          const responseSubById = await axios.get(
            Configuration.EsmaAPIBaseUrl + updatedRole.subscription.id,
            esmaApiHeaders
          );

          updatedRole.subscription = subscriptionFromApi(responseSubById.data);
        } catch (err) {
          return checkError(err);
        }
      }

      dispatch({
        type: ActionType.UPDATE_ROLE,
        payload: updatedRole
      });
      return updatedRole;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.ROLE_LOADING,
        payload: false
      });
    }
  };
};

export const createRole: Thunk<roleReducerTypes> = (role: IRole) => {
  return async (dispatch): Promise<IRole | SnackbarError> => {
    dispatch({
      type: ActionType.SET_ROLE_FORM_VALUE,
      payload: role
    });
    dispatch({
      type: ActionType.ROLE_LOADING,
      payload: true
    });
    try {
      const response: AxiosResponse<IRoleApi> = await axios.post(
        Configuration.EramaAPIUrl + "/roles",
        roleToApi(role),
        eramaApiHeaders
      );
      dispatch({
        type: ActionType.CREATE_ROLE,
        payload: roleFromApi(response.data, role)
      });
      return roleFromApi(response.data);
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.ROLE_LOADING,
        payload: false
      });
    }
  };
};

export const deleteRole: Thunk<roleReducerTypes> = (
  roleInfo: ITableDeleteCell
) => {
  return async (dispatch, getState): Promise<number | SnackbarError> => {
    dispatch({
      type: ActionType.ROLE_LOADING,
      payload: true
    });
    try {
      const response = await axios.delete(
        Configuration.EramaAPIBaseUrl + roleInfo.id,
        eramaApiHeaders
      );

      dispatch({
        type: ActionType.DELETE_ROLE_DIALOG,
        payload: { ...roleInfo, visible: false }
      });

      const {
        roleCollection: {
          subscriptionSelection: { value }
        },
        globalState: { masterAdmin }
      } = getState();

      dispatch(getRoleCollection(value, masterAdmin));

      return response.status;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.ROLE_LOADING,
        payload: false
      });
    }
  };
};

export const clearRole: Thunk<roleReducerTypes> = () => {
  return async dispatch => {
    dispatch({ type: ActionType.CLEAR_ROLE });
  };
};

export const roleDeleteConfirmDialog: Thunk<roleReducerTypes> = (
  roleInfo: ITableDeleteCell
) => {
  return async dispatch => {
    dispatch({ type: ActionType.DELETE_ROLE_DIALOG, payload: roleInfo });
  };
};
