import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  Grid,
  Hidden,
  Button,
  FormControl,
  InputLabel,
  LinearProgress
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import Select from "react-select";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { ValueType } from "react-select/src/types";

import { IRootState } from "../store";
import { withThemeProvider } from "../util/withThemeProvider";
import {
  IUserBySubscriptionCollection,
  IUserBySubscription
} from "./models/IUserBySubscription";
import {
  getUserCollection,
  changeUserCollectionPage
} from "./actions/UsersBySubscriptionCollectionAction";
import { UsersBySubscriptionTable } from "./components/UsersBySubscriptionTable";
import {
  NoStyleLink,
  StyledTitle,
  StyledFormWrapper,
  NewStyledPaper
} from "../components/sharedStyledComponents";
import {
  alertMessages,
  SnackbarError,
  isSnackbarError,
  getStyledSnackbarOptions
} from "../core/utilities/AlertUtilities";
import { IUserProfile } from "../Profile/models/IUserProfile";
import { IGlobalState } from "../models/GlobalState";
import { userBySubscriptionDeleteConfirmDialog } from "./actions/UsersBySubscriptionAction";
import {
  currentSubscriptionCustomerAdmin,
  removeUserBySubscription
} from "./actions/UsersBySubscriptionAction";
import { clearUserBySubscriptionCollection } from "./actions/UsersBySubscriptionCollectionAction";
import { UsersBySubRemoveDialog } from "./components/UserBySubRemoveDialog";
import { ITableDeleteCell } from "../models/shared/IShared";
import { MobileTable } from "../components/MobileTable/MobileTable";
import { userBySubscriptionToMobileTableRow } from "./services/UsersBySubscriptionService";
import { subscriptionsPrefixURL } from "../config";

export const SubSelectorForm = styled.form`
  display: flex;
  justify-content: flex-start;
  padding-left: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  & > div {
    width: 300px;
  }
`;
SubSelectorForm.displayName = "SubSelectorForm";

export const StyledInputLabel = styled(InputLabel)`
  && {
    margin-left: 25px;
  }
`;
StyledInputLabel.displayName = "StyledInputLabel";

interface IUserStateProps {
  userBySubscription: IUserBySubscription;
  userBySubscriptionCollection: IUserBySubscriptionCollection;
  profile: IUserProfile;
  global: IGlobalState;
}

interface IUserDispatchProps {
  getUserCollection: (subId: string) => Promise<undefined | SnackbarError[]>;
  changePage: (page: string) => Promise<undefined | SnackbarError[]>;
  changeSubscription: (
    subId: string
  ) => Promise<IUserBySubscriptionCollection | SnackbarError>;
  userBySubscriptionDeleteConfirmDialog: (userInfo: ITableDeleteCell) => void;
  removeUserBySubscription: (
    userInfo: ITableDeleteCell,
    subId: string
  ) => Promise<IUserBySubscription | SnackbarError>;
  currentSubscriptionCustomerAdmin: (sub: string, initial?: boolean) => void;
  clearUserBySubscriptionCollection: () => void;
}

export type UsersType = IUserStateProps &
  IUserDispatchProps &
  WithSnackbarProps;

export class UsersBySubscription extends React.Component<UsersType> {
  public checkCollectionErrors = (
    response?: SnackbarError[] | IUserBySubscriptionCollection
  ) => {
    const { enqueueSnackbar } = this.props;
    if (response && Array.isArray(response) && response.length > 0) {
      response.forEach(response => {
        if (isSnackbarError(response)) {
          enqueueSnackbar(response.message, response.options);
        }
      });
    }
  };

  public fetchUserBySubscriptions = async (
    page?: string,
    changePageCalled?: boolean
  ) => {
    const {
      getUserCollection,
      currentSubscriptionCustomerAdmin,
      changePage,
      userBySubscription: { subscription },
      global: {
        user: { subId }
      }
    } = this.props;

    let collection: IUserBySubscriptionCollection | SnackbarError[] | undefined;
    if (page) {
      collection = await changePage(page);
    } else {
      changePageCalled
        ? (collection = await getUserCollection(subscription.value))
        : currentSubscriptionCustomerAdmin(
            subscription.value || subscriptionsPrefixURL + subId,
            true
          );
    }
    if (collection) {
      this.checkCollectionErrors(collection);
    }
  };

  public changePage = (page: string) => {
    this.fetchUserBySubscriptions(page, true);
  };

  public async componentDidMount() {
    this.fetchUserBySubscriptions();
  }

  public handleChange = (
    selectedValue: ValueType<{ value: string; label: string }>
  ) => {
    const {
      currentSubscriptionCustomerAdmin,
      clearUserBySubscriptionCollection,
      userBySubscription: { subscription }
    } = this.props;

    if (selectedValue && selectedValue["value"] !== subscription.value) {
      clearUserBySubscriptionCollection();
      currentSubscriptionCustomerAdmin(selectedValue["value"]);
    }
  };

  public handleRemoveDialogOpen = (userInfo: ITableDeleteCell) => (
    _: React.MouseEvent
  ) => {
    const { userBySubscriptionDeleteConfirmDialog } = this.props;
    userBySubscriptionDeleteConfirmDialog(userInfo);
  };

  public handleRemoveDialogClose = (userInfo: ITableDeleteCell) => {
    const { userBySubscriptionDeleteConfirmDialog } = this.props;
    userBySubscriptionDeleteConfirmDialog({ ...userInfo, visible: false });
  };

  public removeUserFromSubscription = async (userInfo: ITableDeleteCell) => {
    const {
      removeUserBySubscription,
      enqueueSnackbar,
      userBySubscription: { subscription }
    } = this.props;
    const removeUserBySubscriptionResult = await removeUserBySubscription(
      userInfo,
      subscription.value
    );

    if (!isSnackbarError(removeUserBySubscriptionResult)) {
      enqueueSnackbar(
        alertMessages.CUSTOMER_USER_REMOVE_SUCCESS,
        getStyledSnackbarOptions("success")
      );
    } else if (isSnackbarError(removeUserBySubscriptionResult)) {
      enqueueSnackbar(
        removeUserBySubscriptionResult.message,
        removeUserBySubscriptionResult.options
      );
    }
  };

  public render() {
    const {
      userBySubscription,
      userBySubscription: {
        subscription,
        subscriptionList,
        isLoadingSelection,
        userBySubscriptionDelete
      },
      userBySubscriptionCollection: { members, view, totalItems, isLoading }
    } = this.props;

    return (
      <React.Fragment>
        <StyledFormWrapper>
          <Grid container={true}>
            <Grid item={true} xs={3} sm={2} md={2}>
              <StyledTitle margintop="8px">Users</StyledTitle>
            </Grid>
            <Grid item={true} xs={3} sm={3} md={5} />
            <Grid item={true} xs={6} sm={7} md={5}>
              <StyledTitle textalign="right">
                <NoStyleLink
                  to={{
                    pathname: "/users/add",
                    state: {
                      userBySubscription: true
                    }
                  }}>
                  <Button color="default">
                    <AddIcon />
                    Add User
                  </Button>
                </NoStyleLink>
              </StyledTitle>
            </Grid>
          </Grid>
          <NewStyledPaper>
            <React.Fragment>
              <StyledInputLabel htmlFor="subscription">
                subscription
              </StyledInputLabel>
              <SubSelectorForm>
                <FormControl>
                  <Select
                    options={subscriptionList}
                    value={subscription}
                    onChange={this.handleChange}
                    isClearable={false}
                    isDisabled={isLoading || isLoadingSelection}
                  />
                </FormControl>
              </SubSelectorForm>
            </React.Fragment>

            <Hidden smDown={true}>
              <UsersBySubscriptionTable
                pagination={view}
                rows={members}
                totalItems={totalItems}
                onChangePage={this.changePage}
                handleDialogOpen={this.handleRemoveDialogOpen}
                isLoading={isLoading || isLoadingSelection}
              />
            </Hidden>

            <Hidden mdUp={true}>
              <MobileTable
                rows={userBySubscriptionToMobileTableRow(members)}
                pagination={view}
                totalItems={totalItems}
                onChangePage={this.changePage}
                handleDialogOpen={this.handleRemoveDialogOpen}
                isLoading={isLoading || isLoadingSelection}
              />
            </Hidden>

            {(isLoading || isLoadingSelection) && (
              <React.Fragment>
                <br /> <LinearProgress /> <br />
              </React.Fragment>
            )}
          </NewStyledPaper>
        </StyledFormWrapper>
        <br />
        <br />

        <UsersBySubRemoveDialog
          userBySubInfo={userBySubscriptionDelete}
          handleRemoveDialogClose={this.handleRemoveDialogClose}
          removeUserFromSubscription={this.removeUserFromSubscription}
          isLoading={userBySubscription.isLoading}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IUserStateProps => ({
  userBySubscription: state.userBySubscription,
  userBySubscriptionCollection: state.userBySubscriptionCollection,
  profile: state.userProfileReducer,
  global: state.globalState
});

const mapDispatchToProps = (dispatch: any): IUserDispatchProps => {
  return {
    getUserCollection: (
      subId: string
    ): Promise<undefined | SnackbarError[]> => {
      return dispatch(getUserCollection(subId));
    },
    changePage: (page: string): Promise<undefined | SnackbarError[]> => {
      return dispatch(changeUserCollectionPage(page));
    },
    changeSubscription: (
      subId: string
    ): Promise<IUserBySubscriptionCollection | SnackbarError> => {
      return dispatch(getUserCollection(subId));
    },
    userBySubscriptionDeleteConfirmDialog: (userInfo: ITableDeleteCell) => {
      return dispatch(userBySubscriptionDeleteConfirmDialog(userInfo));
    },
    removeUserBySubscription: (
      userInfo: ITableDeleteCell,
      subId: string
    ): Promise<IUserBySubscription | SnackbarError> => {
      return dispatch(removeUserBySubscription(userInfo, subId));
    },
    currentSubscriptionCustomerAdmin: (sub: string, initial?: boolean) => {
      dispatch(currentSubscriptionCustomerAdmin(sub, initial));
    },
    clearUserBySubscriptionCollection: () => {
      dispatch(clearUserBySubscriptionCollection());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(UsersBySubscription)));
