import {
  INetwork,
  ITemplate,
  IParameters,
  IPayloadFormats,
  ITableDeleteCell,
  ICollection,
  IDownlinkFormats
} from "../../models/shared/IShared";
import { IApiResponseHeader } from "../../models/IApiResponseHeader";
import {
  IDeviceModel,
  initialDeviceModel
} from "../../DeviceModels/models/IDeviceModel";
import {
  initialSubscription,
  ISubscription
} from "../../Subscriptions/models/ISubscription";
import { IDetermination, ILoadable } from "../../core/models/MixinInterfaces";
import { initialUser, IUser } from "../../Users/models/IUser";

export interface IDeviceModelNetwork {
  name: string;
}

export interface IGeolocation {
  latitude?: string;
  longitude?: string;
}

export interface IAddress {
  addressCountry?: string;
  addressLocality?: string;
  addressRegion?: string;
  postalCode?: string;
  streetAddress?: string;
}

export interface IPosition {
  address: IAddress;
  geo: IGeolocation;
}

export interface IWeather {
  bom?: {
    forecastArea: string;
  };
}

export interface IDeviceApi extends IApiResponseHeader {
  serial: string;
  name: string;
  note?: string;
  owner: string;
  subscription: string;
  deviceModel: string;
  networks: INetwork[];
  manualLocation?: IPosition;
  autoLocation?: IPosition;
  template: ITemplate;
  transferCode: string;
  weather: IWeather;
}

export interface IDeviceCollectionApi extends IApiResponseHeader, ICollection {
  "@context": string;
  member: IDeviceApi[];
  totalItems: number;
  page?: number;
}

export interface IDevice extends IDetermination, ILoadable {
  serial: string;
  name: string;
  note: string;
  deviceModel: IDeviceModel;
  subscription: ISubscription;
  owner: IUser;
  deviceModelList: IDeviceModel[];
  subscriptionList: ISubscription[];
  userList: IUser[];
  template: ITemplate;
  payloadFieldProperties: IParameters[];
  basicCalculationProperties: IParameters[];
  valueParameters: IParameters[];
  payloadFormats: IPayloadFormats[];
  downlinkFormats: IDownlinkFormats[];
  transferCode: string;
  deviceDelete: ITableDeleteCell;
  manufacturer: ISubscription;
  manufacturersList: ISubscription[];
  deviceModelListIsLoading: boolean;
  autoLocation?: IPosition;
  manualLocation?: IPosition;
  weather: IWeather;
  networks?: IDeviceModelNetwork[];
}
export interface IDeviceCollection
  extends IDetermination,
    ILoadable,
    ICollection {
  context?: string;
  members: IDevice[];
  totalItems: number;
  page: number;
  searchBySerial: string;
}

export interface IDeviceCalibrationTableRow {
  actualValue: string;
  deviceValue: string;
}

export interface IDeviceCalibrationTable {
  id: string;
  date?: string;
  deviceId: string;
  calibrationVariant: string[];
  formula: string;
  selectedCalibrationVariant: string;
  parameters: IParameters[];
  parameter: string;
  parameterExpression: string;
  parameterUnit: string;
  rows: IDeviceCalibrationTableRow[];
}

export interface IDeviceCalibration {
  calibrationDeviceValueLoading: boolean;
  calibrationFormulaLoading: boolean;
  isLoading: boolean;
  currentCalibrationTable: IDeviceCalibrationTable;
  calibrationCreated: boolean;
}

export interface IDeviceCalibrationApi extends IApiResponseHeader {
  "@context": string;
  dateCreated?: string;
  device: string;
  parameter: string;
  currentFormula: string;
  newFormula: string;
  algorithm: string;
  dataSet: IDeviceCalibrationTableRow[];
}

export interface IDeviceCalibrationHistoryTable
  extends IDeviceCalibrationTable,
    ILoadable {
  historyId: string;
  createdItem: boolean;
}

export interface IDeviceCalibrationDetailsLoading {
  historyId: string;
  state: boolean;
}

export interface IDeviceCalibrationProcessToApi {
  "@context": string;
  "@type": string;
  device: string;
  parameter: string;
  algorithm: string;
  dataSet: IDeviceCalibrationTableRow[];
}

export interface IDeviceCalibrationProcessGetFromApi
  extends IApiResponseHeader {
  "@context": string;
  dateCreated?: string;
  device: string;
  parameter: string;
  currentFormula: string;
  newFormula: string;
  algorithm: string;
  dataSet: IDeviceCalibrationTableRow[];
}

export interface IDeviceCalibrationHistoryCollection {
  "@id": string;
  dateCreated: string;
}

export interface IDeviceCalibrationHistoryApi extends IApiResponseHeader {
  "@context": string;
  calibrationMember: IDeviceCalibrationHistoryCollection[];
  totalItems: number;
}

export interface IDeviceCalibrationHistory {
  calibrationHistoryLoading: boolean;
  calibrationHistory: IDeviceCalibrationHistoryTable[];
  calibrationHistoryState: boolean;
}

export const calibrationVariants: string[] = ["Least Mean Square"];

export const initialDeviceCalibration: IDeviceCalibration = {
  isLoading: false,
  currentCalibrationTable: {
    id: "",
    calibrationVariant: calibrationVariants,
    formula: "",
    deviceId: "",
    parameter: "",
    parameterExpression: "",
    parameterUnit: "",
    parameters: [],
    rows: [],
    selectedCalibrationVariant: calibrationVariants[0]
  },
  calibrationFormulaLoading: false,
  calibrationDeviceValueLoading: false,
  calibrationCreated: false
};

export const initialDeviceCalibrationHistory: IDeviceCalibrationHistory = {
  calibrationHistory: [],
  calibrationHistoryLoading: false,
  calibrationHistoryState: false
};

export const initialDevice: IDevice = {
  deviceModel: { ...initialDeviceModel },
  id: "",
  isLoading: false,
  name: "",
  note: "",
  owner: { ...initialUser },
  serial: "",
  shortId: "",
  subscription: { ...initialSubscription },
  type: "",
  deviceModelList: [],
  subscriptionList: [],
  userList: [],
  template: {},
  payloadFieldProperties: [],
  basicCalculationProperties: [],
  valueParameters: [],
  payloadFormats: [],
  downlinkFormats: [],
  transferCode: "",
  deviceDelete: {
    visible: false,
    id: "",
    title: ""
  },
  manufacturer: { ...initialSubscription },
  manufacturersList: [],
  deviceModelListIsLoading: false,
  weather: {}
};

export const initialDeviceCollection: IDeviceCollection = {
  context: "",
  id: "",
  isLoading: false,
  members: [],
  page: 0,
  shortId: "",
  totalItems: 0,
  type: "",
  searchBySerial: "",
  view: {
    "@id": "",
    "@type": "",
    first: "",
    last: "",
    next: "",
    previous: ""
  }
};

export interface IDeviceCalibrationValueToApi {
  "@context": string;
  "@type": string;
  kind: string;
  limit: number;
  timeOrder: string;
  granularity: string;
  dimensions: string[];
  filter: {
    "@type": string;
    kind: string;
    fields: [
      {
        "@type": string;
        kind: string;
        dimension: string;
        value: string;
      }
    ];
  };
  intervals: string;
}
