export const checkOrder = <T>(
  sortableKey: string,
  collection?: T | null
): T | T[] | undefined | null => {
  if (!Array.isArray(collection)) {
    return collection;
  }
  if (collection.some(item => Boolean(item[sortableKey]))) {
    let maxIndex =
      Math.max(
        ...collection
          .filter(item => item[sortableKey])
          .map(item => item[sortableKey])
      ) || 0;
    return collection
      .map(item =>
        item[sortableKey] ? item : { ...item, [sortableKey]: ++maxIndex }
      )
      .sort((itemA, itemB) => itemA[sortableKey] - itemB[sortableKey])
      .map((item, index) => ({ ...item, [sortableKey]: index + 1 }));
  }
  return collection.map((item, index) => ({
    ...item,
    [sortableKey]: index + 1
  }));
};

export const changeOrder = <T>(
  oldIndex: number,
  newIndex: number,
  sortableKey: string,
  collection: T[]
): T[] => {
  const positionShifter = (item: T, index: number, arr: T[]) => {
    if (
      oldIndex > newIndex &&
      item[sortableKey] >= newIndex &&
      item[sortableKey] < oldIndex
    ) {
      return { ...item, [sortableKey]: arr[index + 1][sortableKey] };
    } else if (
      oldIndex < newIndex &&
      item[sortableKey] <= newIndex &&
      item[sortableKey] > oldIndex
    ) {
      return { ...item, [sortableKey]: arr[index - 1][sortableKey] };
    } else if (item[sortableKey] === oldIndex) {
      return { ...item, [sortableKey]: newIndex };
    }
    return item;
  };

  const comparator = (itemA: T, itemB: T) =>
    itemA[sortableKey] - itemB[sortableKey];

  if (collection.length > 1) {
    return collection.map(positionShifter).sort(comparator);
  }
  return collection;
};
