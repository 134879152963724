import axios from "axios";

import { ActionType } from "../../models/Action.model";
import { IUserCollection, IUserCollectionApi } from "../models/IUser";
import { Configuration, esmaApiHeaders } from "../../config";
import { Thunk } from "../../store";
import { userCollectionReducerTypes } from "../reducers/userCollectionReducer";
import { createBlockableDispatch } from "../../core/utilities/ServiceUtilities";
import { userCollectionFromApi } from "../services/UserService";
import { checkError, SnackbarError } from "../../core/utilities/AlertUtilities";

export const getUserCollection: Thunk<userCollectionReducerTypes> = () => {
  return async (
    dispatch,
    getState,
    opt
  ): Promise<IUserCollection | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.USER_COLLECTION_LOADING,
      payload: true
    });
    try {
      const response = await axios.get(
        Configuration.EsmaAPIUrl + "/users",
        esmaApiHeaders
      );

      const users: IUserCollection = userCollectionFromApi(
        response.data as IUserCollectionApi
      );

      blockableDispatch({
        type: ActionType.GET_USER_COLLECTION,
        payload: users
      });
      return users;
    } catch (err) {
      const {
        globalState: { normalUser }
      } = getState();

      if (normalUser) {
        return undefined;
      }

      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.USER_COLLECTION_LOADING,
        payload: false
      });
    }
  };
};

export const changeUserCollectionPage: Thunk<userCollectionReducerTypes> = (
  page: string
) => {
  return async (
    dispatch,
    getState,
    opt
  ): Promise<IUserCollection | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.USER_COLLECTION_LOADING,
      payload: true
    });
    try {
      const response = await axios.get(Configuration.EsmaAPIBaseUrl + page, {
        ...esmaApiHeaders
      });

      const users: IUserCollection = userCollectionFromApi(
        response.data as IUserCollectionApi
      );

      blockableDispatch({
        type: ActionType.GET_USER_COLLECTION,
        payload: users
      });
      return users;
    } catch (err) {
      const {
        globalState: { normalUser }
      } = getState();

      if (normalUser) {
        return undefined;
      }

      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.USER_COLLECTION_LOADING,
        payload: false
      });
    }
  };
};
