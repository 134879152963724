import {
  IDeviceModelCollection,
  initialDeviceModelCollection
} from "../models/IDeviceModel";
import { ActionType } from "../../models/Action.model";

export type deviceModelCollectionReducerTypes =
  | {
      type: ActionType.GET_DEVICEMODEL_COLLECTION;
      payload: IDeviceModelCollection;
    }
  | { type: ActionType.DEVICEMODEL_COLLECTION_LOADING; payload: boolean };

export function deviceModelCollectionReducer(
  state: IDeviceModelCollection = { ...initialDeviceModelCollection },
  action: deviceModelCollectionReducerTypes
): IDeviceModelCollection {
  switch (action.type) {
    case ActionType.GET_DEVICEMODEL_COLLECTION:
      state = {
        ...state,
        ...action.payload
      };
      break;
    case ActionType.DEVICEMODEL_COLLECTION_LOADING:
      state = {
        ...state,
        ...(action.payload && initialDeviceModelCollection),
        isLoading: action.payload
      };
      break;
  }
  return state;
}
