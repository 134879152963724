import { IApiResponseHeader } from "../../core/models/MixinInterfaces";
import { IDetermination, ILoadable } from "../../core/models/MixinInterfaces";
import {
  ISubscription,
  initialSubscription
} from "../../Subscriptions/models/ISubscription";
import { IRole, initialRole } from "../../Roles/models/IRole";
import { ISubscriptionWithRole } from "../../Users/models/IUser";

interface IUIPreferences {
  devices: {
    includeParameters: string[] | null; // todo: find out possible types for that variable
  };
}

export interface IUserProfileAPI extends IApiResponseHeader {
  email: string;
  activeRole: string;
  activeSubscription: string;
  hasRoles: {
    [subscription: string]: string[];
  };
  mobile: string;
  name: string;
  notificationChannels?: string[];
  uiPreferences: IUIPreferences;
}

export interface IUserProfile extends IDetermination, ILoadable {
  email: string;
  activeRole: IRole;
  activeSubscription: ISubscription;
  hasRoles: ISubscriptionWithRole[];
  mobile: string;
  name: string;
  smsNotification: boolean;
  emailNotification: boolean;
  uiPreferences: IUIPreferences;
  subscriptionList: ISubscription[];
  roleList: IRole[];
}

export const initialUserProfile: IUserProfile = {
  isLoading: true,
  id: "",
  shortId: "",
  type: "",
  email: "",
  emailNotification: false,
  activeRole: { ...initialRole },
  activeSubscription: { ...initialSubscription },
  hasRoles: [],
  mobile: "",
  name: "",
  smsNotification: false,
  subscriptionList: [],
  roleList: [],
  uiPreferences: {
    devices: { includeParameters: [] }
  }
};
