import { IApiResponseHeader } from "../../models/IApiResponseHeader";
import { IDetermination, ILoadable } from "../../core/models/MixinInterfaces";
import {
  INetwork,
  ITemplate,
  IParameters,
  IPayloadFormats,
  ITableDeleteCell,
  ICollection,
  IDownlinkFormats
} from "../../models/shared/IShared";
import {
  ISubscription,
  initialSubscription
} from "../../Subscriptions/models/ISubscription";

export interface IDeviceModelApi extends IApiResponseHeader {
  name: string;
  description?: string;
  owner?: string;
  subscription: string;
  networks: INetwork[];
  template: ITemplate;
}

export interface IDeviceModelCollectionApi
  extends IApiResponseHeader,
    ICollection {
  "@context"?: string;
  member: IDeviceModelApi[];
  totalItems: number;
  page?: number;
}

export interface IDeviceModel extends ILoadable, IDetermination {
  name: string;
  description?: string;
  owner?: string;
  subscription: ISubscription;
  subscriptionList: ISubscription[];
  template: ITemplate;
  payloadFieldProperties: IParameters[];
  basicCalculationProperties: IParameters[];
  valueParameters: IParameters[];
  payloadFormats: IPayloadFormats[];
  downlinkFormats: IDownlinkFormats[];
  deviceModelDelete: ITableDeleteCell;
}

export interface IDeviceModelCollection
  extends ILoadable,
    IDetermination,
    ICollection {
  context?: string;
  members: IDeviceModel[];
  totalItems: number;
  page: number;
}

export const initialDeviceModel: IDeviceModel = {
  description: "",
  id: "",
  isLoading: false,
  name: "",
  owner: "",
  shortId: "",
  subscription: { ...initialSubscription },
  subscriptionList: [],
  type: "",
  template: {},
  payloadFieldProperties: [],
  basicCalculationProperties: [],
  valueParameters: [],
  payloadFormats: [],
  downlinkFormats: [],
  deviceModelDelete: {
    visible: false,
    id: "",
    title: ""
  }
};

export const initialDeviceModelCollection: IDeviceModelCollection = {
  context: "",
  id: "",
  isLoading: false,
  members: [],
  page: 0,
  shortId: "",
  totalItems: 0,
  type: "",
  view: {
    "@id": "",
    "@type": "",
    first: "",
    last: "",
    next: "",
    previous: ""
  }
};
