import React from "react";
import { Field, Form, FormikProps } from "formik";
import { Button, LinearProgress, Grid, NoSsr } from "@material-ui/core";

import { store } from "../../store";
import { ISubscription, initialSubscription } from "../models/ISubscription";
import { Validators } from "../../util/validators";
import { StyledTextField, TextField } from "../../components/TextField";
import { StyledAutocompleteSelectLabel } from "../../components/sharedStyledComponents";
import {
  AutocompleteSelect,
  StyledAutocomplete
} from "../../components/AutocompleteSelect";

export const EditSubForm: React.FunctionComponent<
  FormikProps<ISubscription>
> = ({ submitForm, isSubmitting, values }: FormikProps<ISubscription>) => {
  const { isLoading, userList } = values;
  const { name, owner } = Validators.editSubscription;
  const {
    globalState: { masterAdmin }
  } = store.getState();

  return (
    <Form>
      <StyledTextField
        type="text"
        label="Name"
        name="name"
        multiline={false}
        component={TextField}
        disabled={isSubmitting || isLoading}
        validate={name}
      />
      <br />

      {masterAdmin ? (
        <NoSsr>
          {Boolean(values.owner.id) && !isLoading && (
            <StyledAutocompleteSelectLabel htmlFor="owner">
              Owner
            </StyledAutocompleteSelectLabel>
          )}

          <StyledAutocomplete>
            <Field
              name="owner"
              options={userList}
              component={AutocompleteSelect}
              isDisabled={isSubmitting || isLoading}
              validate={owner}
              labelField="name"
              identityKey="id"
              placeholder="Select Owner"
              isClearable={true}
              clearValue={initialSubscription}
            />
          </StyledAutocomplete>
        </NoSsr>
      ) : (
        <StyledTextField
          type="text"
          label="Owner"
          name="owner.name"
          disabled={true}
          component={TextField}
        />
      )}

      {(isSubmitting || isLoading) && <LinearProgress />}
      <br />
      <Grid
        container={true}
        item={true}
        direction="row"
        justify="flex-start"
        alignItems="center">
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting || isLoading}
          onClick={submitForm}>
          Save
        </Button>
      </Grid>
    </Form>
  );
};
