import { IPolicyCollection, initialPolicyCollection } from "../models/IPolicy";
import { ActionType } from "../../models/Action.model";
import { ISubscriptionSelection } from "../../models/shared/IShared";

export type policyCollectionReducerTypes =
  | {
      type: ActionType.GET_POLICY_COLLECTION;
      payload: IPolicyCollection;
    }
  | { type: ActionType.POLICY_COLLECTION_LOADING; payload: boolean }
  | {
      type: ActionType.SET_POLICY_SUBSCRIPTION_SELECTION;
      payload: ISubscriptionSelection;
    };

export function policyCollectionReducer(
  state: IPolicyCollection = { ...initialPolicyCollection },
  action: policyCollectionReducerTypes
): IPolicyCollection {
  switch (action.type) {
    case ActionType.GET_POLICY_COLLECTION:
      state = {
        ...action.payload,
        page: 0
      };
      break;
    case ActionType.POLICY_COLLECTION_LOADING:
      state = {
        ...state,
        ...(action.payload && initialPolicyCollection),
        isLoading: action.payload,
        policySubscriptionSelection: state.policySubscriptionSelection
      };
      break;
    case ActionType.SET_POLICY_SUBSCRIPTION_SELECTION:
      state = {
        ...state,
        policySubscriptionSelection: action.payload,
        policySubscriptionSelectionList: state.policySubscriptionSelectionList
      };
      break;
  }
  return state;
}
