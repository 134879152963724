import {
  IPolicy,
  IPolicyApi,
  IPolicyCollection,
  IPolicyCollectionApi,
  initialPolicy,
  initialPolicyCollection
} from "../models/IPolicy";
import { ServiceUtilities } from "../../core/utilities/ServiceUtilities";
import {
  ISubscriptionCollectionApi,
  initialSubscription
} from "../../Subscriptions/models/ISubscription";
import { subscriptionFromApi } from "../../Subscriptions/services/SubscriptionService";
import { IMobileTableRow } from "../../models/shared/IShared";

export const policyFromApi = (
  policy: IPolicyApi,
  initializedPolicy?: IPolicy
): IPolicy => {
  const result = initializedPolicy ? initializedPolicy : { ...initialPolicy };

  result.id = policy["@id"];
  result.shortId = ServiceUtilities.getShortId(policy["@id"]);
  result.type = policy["@type"];
  result.name = policy.name;
  result.subscription = {
    ...initialSubscription,
    id: policy.subscription
  };
  result.permissions = policy.permissions;

  if (initializedPolicy) {
    result.subscriptionList = initializedPolicy.subscriptionList;
    const subscription = initializedPolicy.subscriptionList
      .filter(subscription => subscription.id === policy.subscription)
      .pop();

    if (subscription) {
      result.subscription = subscription;
    }
  }

  return result;
};

export const policyToApi = (policy: IPolicy): IPolicyApi => {
  return {
    "@id": policy.id,
    "@type": policy.type,
    name: policy.name,
    subscription: policy.subscription.id,
    permissions: policy.permissions
  };
};

export const policyCollectionFromApi = (
  collection: IPolicyCollectionApi
): IPolicyCollection => ({
  ...initialPolicyCollection,
  context: collection["@context"],
  id: collection["@id"],
  isLoading: false,
  members: collection.member.map(policy => policyFromApi(policy)),
  shortId: ServiceUtilities.getShortId(collection["@id"]),
  totalItems: collection.totalItems,
  type: collection["@type"],
  view: collection.view
});

export const addPolicySubscription = (
  model: IPolicy,
  subscriptions: ISubscriptionCollectionApi
): IPolicy => {
  const filteredSubscription = subscriptions.member
    .filter(subs => subs["@id"] === model.subscription.id)
    .pop();
  return {
    ...model,
    subscription: filteredSubscription
      ? subscriptionFromApi(filteredSubscription)
      : model.subscription
  };
};

export const addPolicyCollectionSubscription = (
  collection: IPolicyCollection,
  subscriptions: ISubscriptionCollectionApi
) => {
  return {
    ...collection,
    members: collection.members.map(model =>
      addPolicySubscription(model, subscriptions)
    )
  };
};

export const policiesToMobileTableRow = (
  members: IPolicy[]
): IMobileTableRow[] => {
  const result = members.map(item => {
    return {
      id: item.id,
      title: item.name,
      fields: [{ label: "Subscription", value: item.subscription.name }],
      editPageLink: `/policies/${ServiceUtilities.getShortId(item.id)}`
    };
  });
  return result;
};
