import React from "react";
import styled from "styled-components";
import { Formik, FormikActions } from "formik";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";

import { InviteUser } from "./InviteUser";
import { getSubscriptionName } from "../services/UsersBySubscriptionService";
import { IInviteUserBySubscription } from "../models/IUserBySubscription";
import { ISubscriptionSelection } from "../../models/shared/IShared";

export const StyledIconButton = styled(IconButton)`
  && {
    position: absolute;
    right: 5px;
    top: 5px;
  }
`;
StyledIconButton.displayName = "StyledIconButton";

export const StyledDialogContentText = styled(DialogContentText)`
  && {
    color: black;
  }
`;
StyledDialogContentText.displayName = "StyledDialogContentText";

interface IProps {
  inviteUserDialogOpen: boolean;
  subscription: string;
  subscriptionList: ISubscriptionSelection[];
  inviteUserInitialValue: IInviteUserBySubscription;
  handleInviteUserDialogClose: () => void;
  onInviteSubmit: (
    values: IInviteUserBySubscription,
    { setSubmitting }: FormikActions<IInviteUserBySubscription>
  ) => Promise<void>;
}

export const UsersBySubInviteDialog: React.FunctionComponent<IProps> = ({
  inviteUserDialogOpen,
  subscription,
  subscriptionList,
  inviteUserInitialValue,
  handleInviteUserDialogClose,
  onInviteSubmit
}: IProps) => (
  <React.Fragment>
    <Dialog open={inviteUserDialogOpen} aria-labelledby="form-dialog-title">
      <DialogTitle id="customized-dialog-title">
        <StyledIconButton
          aria-label="Close"
          onClick={handleInviteUserDialogClose}
        >
          <CloseIcon />
        </StyledIconButton>
      </DialogTitle>
      <DialogContent>
        <br />
        <StyledDialogContentText>
          {`Adding user failed because this email address is not signed up. Do
              you want to invite this email address to subscription "${getSubscriptionName(
                subscription,
                subscriptionList
              )}"?`}
        </StyledDialogContentText>
        <br />
        <Formik
          enableReinitialize={true}
          initialValues={inviteUserInitialValue}
          onSubmit={onInviteSubmit}
          render={InviteUser}
        />
      </DialogContent>
    </Dialog>
  </React.Fragment>
);
