import axios, { AxiosResponse, AxiosError } from "axios";

import { ActionType } from "../../models/Action.model";
import { IUserProfile, IUserProfileAPI } from "../models/IUserProfile";
import {
  Configuration,
  esmaApiHeaders,
  eramaApiHeaders,
  usersPrefixURL
} from "../../config";
import {
  fromApiFormat,
  toApiFormat,
  getSubscriptionsWithRoles,
  getUserActiveSubRole,
  getNonEmptyHasRoles
} from "../services/UserProfileService";
import { Thunk } from "../../store";
import { createBlockableDispatch } from "../../core/utilities/ServiceUtilities";
import { UserProfileActionTypes } from "../reducers/userProfileReducer";
import { subscriptionFromApi } from "../../Subscriptions/services/SubscriptionService";
import { roleFromApi } from "../../Roles/services/RoleService";
import { checkError, SnackbarError } from "../../core/utilities/AlertUtilities";
import { refreshToken } from "../../actions/globalStateAction";

export const getUserById: Thunk<UserProfileActionTypes> = (
  userId: string,
  calledByRoot: boolean = false
) => {
  return async (dispatch, _, opt): Promise<undefined | SnackbarError[]> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({ type: ActionType.USER_PROFILE_LOADING, payload: true });
    const error: (AxiosError | Error)[] = [];

    try {
      const response: AxiosResponse<IUserProfileAPI> = await axios.get(
        Configuration.EsmaAPIBaseUrl + usersPrefixURL + userId,
        esmaApiHeaders
      );

      let user = fromApiFormat(response.data);
      blockableDispatch({
        type: ActionType.GET_USER_PROFILE,
        payload: user
      });

      const subscriptionQueries = Object.keys(response.data.hasRoles).map(
        subscriptionId =>
          axios
            .get(Configuration.EsmaAPIBaseUrl + subscriptionId, {
              ...esmaApiHeaders
            })
            .catch(err => {
              if (err.response.status !== 404) {
                error.push(err);
              }
            })
      );
      const roleQueries = Object.keys(response.data.hasRoles)
        .map(subscription => response.data.hasRoles[subscription])
        .reduce((flatten, arr) => [...flatten, ...arr])
        .filter((role, position, arr) => arr.indexOf(role) === position)
        .map(roleId =>
          axios
            .get(Configuration.EramaAPIBaseUrl + roleId, {
              ...eramaApiHeaders
            })
            .catch(err => {
              if (err.response.status !== 404) {
                error.push(err);
              }
            })
        );
      let [subscriptions, roles] = await Promise.all([
        Promise.all(subscriptionQueries),
        Promise.all(roleQueries)
      ]);

      subscriptions = subscriptions.filter(sub => sub);
      roles = roles.filter(role => role);

      user.hasRoles = getNonEmptyHasRoles(
        getSubscriptionsWithRoles(
          response.data.hasRoles,
          subscriptions.map(subscription =>
            subscriptionFromApi(subscription && subscription.data)
          ),
          roles.map(role => roleFromApi(role && role.data))
        )
      );

      user = getUserActiveSubRole(user);

      blockableDispatch({
        type: ActionType.GET_USER_PROFILE_HASROLES_ACTIVE_SUB_ROLE,
        payload: user
      });

      dispatch(refreshToken(user.activeSubscription.id, user.activeRole.id));

      if (error.length > 0) {
        return error.map(error => checkError(error));
      }
    } catch (err) {
      return [checkError(err)];
    } finally {
      if (!calledByRoot) {
        dispatch({ type: ActionType.USER_PROFILE_LOADING, payload: false });
      }
    }
  };
};

export const updateUserById: Thunk<UserProfileActionTypes> = (
  profile: IUserProfile
) => {
  return async (dispatch, _, opt): Promise<undefined | SnackbarError[]> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    blockableDispatch({
      type: ActionType.SET_USER_PROFILE_FORM_VALUE,
      payload: profile
    });
    dispatch({ type: ActionType.USER_PROFILE_LOADING, payload: true });
    const error: (AxiosError | Error)[] = [];

    try {
      const response: AxiosResponse<IUserProfileAPI> = await axios.put(
        Configuration.EsmaAPIBaseUrl + profile.id,
        toApiFormat(profile),
        esmaApiHeaders
      );

      dispatch(
        refreshToken(response.data.activeSubscription, response.data.activeRole)
      );

      let user = fromApiFormat(response.data);
      blockableDispatch({
        type: ActionType.UPDATE_USER_PROFILE,
        payload: user
      });

      const subscriptionQueries = Object.keys(response.data.hasRoles).map(
        subscriptionId =>
          axios
            .get(Configuration.EsmaAPIBaseUrl + subscriptionId, {
              ...esmaApiHeaders
            })
            .catch(err => {
              if (err.response.status !== 404) {
                error.push(err);
              }
            })
      );
      const roleQueries = Object.keys(response.data.hasRoles)
        .map(subscription => response.data.hasRoles[subscription])
        .reduce((flatten, arr) => [...flatten, ...arr])
        .filter((role, position, arr) => arr.indexOf(role) === position)
        .map(roleId =>
          axios
            .get(Configuration.EramaAPIBaseUrl + roleId, {
              ...eramaApiHeaders
            })
            .catch(err => {
              if (err.response.status !== 404) {
                error.push(err);
              }
            })
        );
      let [subscriptions, roles] = await Promise.all([
        Promise.all(subscriptionQueries),
        Promise.all(roleQueries)
      ]);

      subscriptions = subscriptions.filter(sub => sub);
      roles = roles.filter(role => role);

      user.hasRoles = getNonEmptyHasRoles(
        getSubscriptionsWithRoles(
          response.data.hasRoles,
          subscriptions.map(subscription =>
            subscriptionFromApi(subscription && subscription.data)
          ),
          roles.map(role => roleFromApi(role && role.data))
        )
      );

      user = getUserActiveSubRole(user);

      blockableDispatch({
        type: ActionType.GET_USER_PROFILE_HASROLES_ACTIVE_SUB_ROLE,
        payload: user
      });

      if (error.length > 0) {
        return error.map(error => checkError(error));
      }
    } catch (err) {
      return [checkError(err)];
    } finally {
      dispatch({ type: ActionType.USER_PROFILE_LOADING, payload: false });
    }
  };
};
