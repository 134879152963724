import React from "react";
import { TableBody } from "@material-ui/core";

import { ISubscription } from "../models/ISubscription";
import { StyledTable } from "../../components/sharedStyledComponents";
import { IPaginationView } from "../../models/shared/IShared";
import { SubscriptionTableHeader } from "./SubscriptionTable/SubscriptionTableHeader";
import { SubscriptionTableFooter } from "./SubscriptionTable/SubscriptionTableFooter";
import { SubscriptionTableRow } from "./SubscriptionTable/SubscriptionTableRow";

export interface ISubscriptionTableProps {
  pagination?: IPaginationView;
  isLoading?: boolean;
  rows: ISubscription[];
  totalItems?: number;
  onChangePage?: (page: string) => void;
}

export const SubTable: React.FunctionComponent<
  ISubscriptionTableProps
> = props => (
  <React.Fragment>
    <StyledTable>
      <SubscriptionTableHeader />
      {!props.isLoading && (
        <React.Fragment>
          <TableBody>
            {props.rows.map(row => (
              <SubscriptionTableRow key={row.id} {...row} />
            ))}
          </TableBody>
          <SubscriptionTableFooter {...props} />
        </React.Fragment>
      )}
    </StyledTable>
  </React.Fragment>
);
