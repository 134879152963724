import React from "react";
import { Field, Form, FormikProps } from "formik";
import { Button, LinearProgress, Grid, NoSsr } from "@material-ui/core";

import { StyledTextField, TextField } from "../../components/TextField";
import { StyledAutocompleteSelectLabel } from "../../components/sharedStyledComponents";
import {
  AutocompleteSelect,
  StyledAutocomplete
} from "../../components/AutocompleteSelect";
import { Validators } from "../../util/validators";
import { IUserBySubscription } from "../models/IUserBySubscription";
import { IRole } from "../../Roles/models/IRole";

export const EditUserForm: React.FunctionComponent<
  FormikProps<IUserBySubscription>
> = ({
  submitForm,
  isSubmitting,
  values,
  setFieldValue
}: FormikProps<IUserBySubscription>) => {
  const {
    addMember: { email, role }
  } = Validators;
  const { isLoading, roleList, roles } = values;

  const roleSelectionHandleChange = (selectedRole: IRole[]) => {
    if (selectedRole !== null) {
      setFieldValue("roles", selectedRole);
    } else {
      setFieldValue("roles", []);
    }
  };

  return (
    <Form>
      <StyledTextField
        type="text"
        label="Email"
        name="email"
        multiline={false}
        component={TextField}
        validate={email}
        disabled={true}
      />
      <br />

      <NoSsr>
        {roles[0] && !isLoading && (
          <StyledAutocompleteSelectLabel htmlFor="roles">
            Roles
          </StyledAutocompleteSelectLabel>
        )}
        <StyledAutocomplete>
          <Field
            name="roles"
            options={roleList}
            component={AutocompleteSelect}
            isDisabled={isSubmitting || isLoading}
            labelField="name"
            identityKey="id"
            placeholder="Select Roles"
            isMulti={true}
            isClearable={true}
            clearValue={[]}
            validate={role}
            onChange={roleSelectionHandleChange}
          />
        </StyledAutocomplete>
      </NoSsr>

      <br />
      {(isSubmitting || isLoading) && <LinearProgress />}
      <br />

      <Grid
        container={true}
        item={true}
        direction="row"
        justify="flex-start"
        alignItems="center">
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting || isLoading}
          onClick={submitForm}>
          Save
        </Button>
      </Grid>
    </Form>
  );
};
