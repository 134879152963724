import { IDeviceModel } from "../DeviceModels/models/IDeviceModel";
import { ISubscription } from "../Subscriptions/models/ISubscription";
import { IParameters, IPayloadFormats } from "../models/shared/IShared";
import { IUser } from "../Users/models/IUser";
import { IRole } from "../Roles/models/IRole";

const EMAIL_REGEX: RegExp = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
const PAYLOAD_FORMATS_REGEX: RegExp = new RegExp(
  /^[a-zA-Z_0-9]+:[a-zA-Z_0-9]+:(-?[0-9]\d*(\.\d+)*)?$/
);

const VALUE_PARAMETERS_REGEX: RegExp = new RegExp(/^(-?[0-9]\d*(\.\d+)*)?$/);

const PHONE_REGEX: RegExp = new RegExp(/^\+?[a-zA-Z0-9\-().\s]{10,16}$/);

export const UUID: RegExp = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

const propertiesLength = {
  SERIAL_MAX: 64,
  DEVIE_NAME_MAX: 64,
  NAME_MAX: 64,
  NOTE_MAX: 512,
  DESCRIPTION_MAX: 512,
  USER_NAME_MAX: 64,
  PHONE_MAX: 16,
  EXPRESSION_MAX_LENGTH: 256
};

export const Validators = {
  editDevice: {
    serial: (serial: string) => {
      let error: string | null = null;
      if (!serial && !error) {
        error = "Required";
      } else if (serial.length > propertiesLength.SERIAL_MAX && !error) {
        error =
          "Serial should be maximum " +
          propertiesLength.SERIAL_MAX +
          " characters";
      }
      return error;
    },
    name: (name: string) => {
      let error: string | null = null;
      if (!name && !error) {
        error = "Required";
      } else if (name.length > propertiesLength.NAME_MAX && !error) {
        error =
          "Name should be maximum " + propertiesLength.NAME_MAX + " characters";
      }
      return error;
    },
    note: (note: string) => {
      let error: string | null = "";
      if (note.length > propertiesLength.NOTE_MAX && !error) {
        error =
          "Note should be maximum " + propertiesLength.NOTE_MAX + " characters";
      }
      return error;
    },
    deviceModel: (deviceModel: IDeviceModel) =>
      deviceModel.id.length > 0 ? null : "Device Model is required",
    subscription: (subscription: ISubscription) =>
      subscription.id.length > 0 ? null : "Subscription is required",
    manufacturer: (manufacturer: ISubscription) =>
      manufacturer.id.length > 0 ? null : "Manufacturer is required"
  },

  editDeviceModel: {
    name: (name: string) => {
      let error: string | null = null;
      if (!name && !error) {
        error = "Required";
      } else if (name.length > propertiesLength.NAME_MAX && !error) {
        error =
          "Name should be maximum " + propertiesLength.NAME_MAX + " characters";
      }
      return error;
    },
    description: (description: string) => {
      let error: string | null = "";
      if (description.length > propertiesLength.DESCRIPTION_MAX && !error) {
        error =
          "Description should be maximum " +
          propertiesLength.DESCRIPTION_MAX +
          " characters";
      }
      return error;
    },
    subscription: (subscription: ISubscription) =>
      subscription.id.length > 0 ? null : "Subscription is required"
  },

  editDeviceModelParameters: {
    name: (fields: IParameters[], index: number) => {
      const properties = fields
        .map(measuredProperty => measuredProperty.name.toUpperCase())
        .filter((_: string, i: number) => i !== index);
      return (name: string) => {
        if (!name) {
          return "Name is required";
        }
        return properties.indexOf(name.toUpperCase()) >= 0
          ? "Names should be unique"
          : null;
      };
    },
    expression: (expression: string) => {
      let error: string | null = null;
      if (!expression) {
        return "Expression is required";
      }
      if (expression.length > propertiesLength.EXPRESSION_MAX_LENGTH) {
        error =
          "Expression should be maximum " +
          propertiesLength.EXPRESSION_MAX_LENGTH +
          " characters";
      }
      return error;
    }
  },

  editDeviceModelPayloadFormats: {
    payloadFormat: (fields: IPayloadFormats[], index: number) => {
      return (payloadFormat: string) => {
        if (!payloadFormat) {
          return "PayloadFormat is required";
        }

        let format = payloadFormat.split(" ");

        for (let index = 0; index < format.length; index++) {
          if (!PAYLOAD_FORMATS_REGEX.test(format[index])) {
            return "PayloadFormat is invalid";
          }
        }
      };
    }
  },

  editDeviceModelDownlinkFormats: {
    downlinkFormat: (fields: IPayloadFormats[], index: number) => {
      return (payloadFormat: string) => {
        if (!payloadFormat) {
          return "DownlinkFormat is required";
        }

        let format = payloadFormat.split(" ");

        for (let index = 0; index < format.length; index++) {
          if (!PAYLOAD_FORMATS_REGEX.test(format[index])) {
            return "DownlinkFormat is invalid";
          }
        }
      };
    },
    downlinkName: (fields: IParameters[], index: number) => {
      return (name: string) => {
        if (!name) {
          return "Name is required";
        }
      };
    }
  },

  editDeviceModelValueParameters: {
    name: (fields: IParameters[], index: number) => {
      return (name: string) => {
        if (!name) {
          return "Name is required";
        }
      };
    },
    value: (fields: IParameters[], index: number) => {
      return (value: string) => {
        if (!value) {
          return "Value is required";
        }

        if (!VALUE_PARAMETERS_REGEX.test(value)) {
          return "Value is invalid";
        }
      };
    }
  },
  editSubscription: {
    name: (name: string) => {
      let error: string | null = null;
      if (!name && !error) {
        error = "Required";
      } else if (name.length > propertiesLength.NAME_MAX && !error) {
        error =
          "Name should be maximum " + propertiesLength.NAME_MAX + " characters";
      }
      return error;
    },
    owner: (user: IUser) => (user.id.length > 0 ? null : "Owner is required")
  },
  editUser: {
    name: (name: string) => {
      let error: string | null = null;
      if (!name && !error) {
        error = "Required";
      } else if (name.length > propertiesLength.NAME_MAX && !error) {
        error =
          "Name should be maximum " + propertiesLength.NAME_MAX + " characters";
      }
      return error;
    },
    mobile: (mobile: string) => {
      let error: string | null = null;
      if (!mobile && !error) {
        error = "Required";
      } else if (mobile.length > 0 && !PHONE_REGEX.test(mobile)) {
        error = "Invalid phone number";
      }
      return error;
    },
    email: (email: string) => {
      let error: string | null = null;
      if (!email && !error) {
        error = "Required";
      } else if (email.length > 0 && !EMAIL_REGEX.test(email)) {
        error = "Invalid email address";
      }
      return error;
    }
  },
  editUserProfile: {
    name: (name: string) => {
      let error: string | null = null;
      if (!name) {
        error = "Required";
      } else if (name.length > propertiesLength.USER_NAME_MAX) {
        error =
          "Name should be maximum " +
          propertiesLength.USER_NAME_MAX +
          " characters";
      }
      return error;
    },
    email: (email: string) => {
      let error: string | null = null;
      if (!email) {
        error = "Required";
      } else if (email.length > 0 && !EMAIL_REGEX.test(email)) {
        error = "Please enter valid email address";
      }
      return error;
    },
    phone: (phone: string) => {
      let error: string | null = null;
      if (phone.length > propertiesLength.PHONE_MAX) {
        error =
          "Phone number should be maximum " +
          propertiesLength.PHONE_MAX +
          " characters";
      } else if (phone.length > 0 && !PHONE_REGEX.test(phone)) {
        error = "Please enter valid phone number";
      }
      return error;
    },
    role: (role: IRole) =>
      role && role.id && role.id.length > 0 ? null : "Active Role is required"
  },
  editRole: {
    name: (name: string) => {
      let error: string | null = null;
      if (!name && !error) {
        error = "Required";
      } else if (name.length > propertiesLength.NAME_MAX && !error) {
        error =
          "Name should be maximum " + propertiesLength.NAME_MAX + " characters";
      }
      return error;
    },
    subscription: (subscription: ISubscription) =>
      subscription.id.length > 0 ? null : "Subscription is required"
  },
  editPolicy: {
    name: (name: string) => {
      let error: string | null = null;
      if (!name && !error) {
        error = "Required";
      } else if (name.length > propertiesLength.NAME_MAX && !error) {
        error =
          "Name should be maximum " + propertiesLength.NAME_MAX + " characters";
      }
      return error;
    },
    subscription: (subscription: ISubscription) =>
      subscription.id.length > 0 ? null : "Subscription is required",
    permissions: {
      access: (access: string) => {
        if (!access) {
          return "Access is required";
        }
      },
      methods: (methods: []) => {
        if (!(methods.length > 0)) {
          return "Methods is required";
        }
      },
      resource: (resource: string) => {
        if (!resource) {
          return "Resource is required";
        }
      }
    }
  },
  policyPermissions: {
    access: (access: string) => {
      if (!access) {
        return "Access is required";
      }
    },
    methods: (methods: []) => {
      if (!(methods.length > 0)) {
        return "Methods is required";
      }
    },
    resource: (resource: string) => {
      if (!resource) {
        return "Resource is required";
      }
    }
  },
  addMember: {
    email: (email: string) => {
      let error: string | null = null;
      if (!email && !error) {
        error = "Email is required";
      } else if (email.length > 0 && !EMAIL_REGEX.test(email)) {
        error = "Invalid email address";
      }
      return error;
    },
    role: (roles: IRole[]) => (roles.length > 0 ? null : "Role is required")
  },
  deviceCalibration: {
    parameter: (parameter: string) => {
      let error: string | null = null;
      if (!parameter && !error) {
        error = "Parameter is required";
      }
      return error;
    },
    newFormula: (newFormula: string) => {
      let error: string | null = null;
      if (!newFormula && !error) {
        error = "New Formula is required";
      }
      return error;
    },
    actualValue: (fields: any[], index: number) => {
      return (actualValue: string) => {
        if (!actualValue) {
          return "Actual Value is required";
        }
      };
    },
    deviceValue: (fields: any[], index: number) => {
      return (deviceValue: string) => {
        if (!deviceValue) {
          return "Device Value is required";
        }
      };
    }
  }
};
