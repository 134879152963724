import axios from "axios";

import { ActionType } from "../../models/Action.model";
import {
  IDeviceModelCollection,
  IDeviceModelCollectionApi
} from "../models/IDeviceModel";
import { Configuration, ediApiHeaders, esmaApiHeaders } from "../../config";

import {
  ISubscriptionCollectionApi,
  ISubscription
} from "../../Subscriptions/models/ISubscription";
import { Thunk } from "../../store";
import { deviceModelCollectionReducerTypes } from "../reducers/deviceModelCollectionReducer";
import { createBlockableDispatch } from "../../core/utilities/ServiceUtilities";
import {
  addDeviceModelCollectionSubscription,
  deviceModelCollectionFromApi
} from "../services/DeviceModelsService";
import { checkError, SnackbarError } from "../../core/utilities/AlertUtilities";
import { subscriptionFromApi } from "../../Subscriptions/services/SubscriptionService";

export const getDeviceModelCollection: Thunk<
  deviceModelCollectionReducerTypes
> = () => {
  return async (
    dispatch,
    _,
    opt
  ): Promise<IDeviceModelCollection | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.DEVICEMODEL_COLLECTION_LOADING,
      payload: true
    });
    try {
      const response = await axios.all([
        axios.get(Configuration.EdiAPIUrl + "/devicemodels", ediApiHeaders),
        axios.get(Configuration.EsmaAPIUrl + "/subscriptions", esmaApiHeaders)
      ]);

      const deviceModels: IDeviceModelCollection = deviceModelCollectionFromApi(
        response[0].data as IDeviceModelCollectionApi
      );
      const subs: ISubscriptionCollectionApi = response[1]
        .data as ISubscriptionCollectionApi;
      const deviceModelsWithSubscriptions: IDeviceModelCollection = addDeviceModelCollectionSubscription(
        deviceModels,
        subs
      );

      let tmpSubscriptions: ISubscription[] = [];
      try {
        for (let deviceModelIndex in deviceModelsWithSubscriptions.members) {
          if (
            deviceModelsWithSubscriptions.members.hasOwnProperty(
              deviceModelIndex
            ) &&
            deviceModelsWithSubscriptions.members[deviceModelIndex].subscription
              .name === ""
          ) {
            const selectedSub = tmpSubscriptions
              .filter(
                item =>
                  item.id ===
                  deviceModelsWithSubscriptions.members[deviceModelIndex]
                    .subscription.id
              )
              .pop();
            if (selectedSub) {
              deviceModelsWithSubscriptions.members[
                deviceModelIndex
              ].subscription = {
                ...selectedSub
              };
            } else {
              const responseSubById = await axios.get(
                Configuration.EsmaAPIBaseUrl +
                  deviceModelsWithSubscriptions.members[deviceModelIndex]
                    .subscription.id,
                esmaApiHeaders
              );

              const fetchedSub = subscriptionFromApi(responseSubById.data);

              tmpSubscriptions = [...tmpSubscriptions, fetchedSub];
              deviceModelsWithSubscriptions.members[
                deviceModelIndex
              ].subscription = {
                ...fetchedSub
              };
            }
          }
        }
      } catch (err) {
        return checkError(err);
      }

      blockableDispatch({
        type: ActionType.GET_DEVICEMODEL_COLLECTION,
        payload: deviceModelsWithSubscriptions
      });
      return deviceModelsWithSubscriptions;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.DEVICEMODEL_COLLECTION_LOADING,
        payload: false
      });
    }
  };
};

export const changeDeviceModelCollectionPage: Thunk<
  deviceModelCollectionReducerTypes
> = (page: string) => {
  return async (
    dispatch,
    _,
    opt
  ): Promise<IDeviceModelCollection | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.DEVICEMODEL_COLLECTION_LOADING,
      payload: true
    });
    try {
      const response = await axios.all([
        axios.get(Configuration.EdiAPIBaseUrl + page, {
          ...ediApiHeaders
        }),
        axios.get(Configuration.EsmaAPIUrl + "/subscriptions", esmaApiHeaders)
      ]);
      const subs: ISubscriptionCollectionApi = response[1]
        .data as ISubscriptionCollectionApi;
      const deviceModels: IDeviceModelCollection = deviceModelCollectionFromApi(
        response[0].data as IDeviceModelCollectionApi
      );
      const deviceModelsWithSubscriptions: IDeviceModelCollection = addDeviceModelCollectionSubscription(
        deviceModels,
        subs
      );

      let tmpSubscriptions: ISubscription[] = [];
      try {
        for (let deviceModelIndex in deviceModelsWithSubscriptions.members) {
          if (
            deviceModelsWithSubscriptions.members.hasOwnProperty(
              deviceModelIndex
            ) &&
            deviceModelsWithSubscriptions.members[deviceModelIndex].subscription
              .name === ""
          ) {
            const selectedSub = tmpSubscriptions
              .filter(
                item =>
                  item.id ===
                  deviceModelsWithSubscriptions.members[deviceModelIndex]
                    .subscription.id
              )
              .pop();
            if (selectedSub) {
              deviceModelsWithSubscriptions.members[
                deviceModelIndex
              ].subscription = {
                ...selectedSub
              };
            } else {
              const responseSubById = await axios.get(
                Configuration.EsmaAPIBaseUrl +
                  deviceModelsWithSubscriptions.members[deviceModelIndex]
                    .subscription.id,
                esmaApiHeaders
              );

              const fetchedSub = subscriptionFromApi(responseSubById.data);

              tmpSubscriptions = [...tmpSubscriptions, fetchedSub];
              deviceModelsWithSubscriptions.members[
                deviceModelIndex
              ].subscription = {
                ...fetchedSub
              };
            }
          }
        }
      } catch (err) {
        return checkError(err);
      }

      blockableDispatch({
        type: ActionType.GET_DEVICEMODEL_COLLECTION,
        payload: deviceModelsWithSubscriptions
      });
      return deviceModelsWithSubscriptions;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.DEVICEMODEL_COLLECTION_LOADING,
        payload: false
      });
    }
  };
};
