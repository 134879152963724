import { ActionType } from "../../models/Action.model";
import { IUserProfile, initialUserProfile } from "../models/IUserProfile";

export type UserProfileActionTypes =
  | { type: ActionType.GET_USER_PROFILE; payload: IUserProfile }
  | {
      type: ActionType.GET_USER_PROFILE_HASROLES_ACTIVE_SUB_ROLE;
      payload: IUserProfile;
    }
  | { type: ActionType.USER_PROFILE_LOADING; payload: boolean }
  | { type: ActionType.UPDATE_USER_PROFILE; payload: IUserProfile }
  | { type: ActionType.SET_USER_PROFILE_FORM_VALUE; payload: IUserProfile };

export function userProfileReducer(
  state: IUserProfile = { ...initialUserProfile },
  action: UserProfileActionTypes
): IUserProfile {
  switch (action.type) {
    case ActionType.GET_USER_PROFILE:
      state = {
        ...state,
        ...action.payload,
        activeSubscription: { ...state.activeSubscription },
        activeRole: { ...state.activeRole }
      };
      break;
    case ActionType.GET_USER_PROFILE_HASROLES_ACTIVE_SUB_ROLE:
      state = {
        ...state,
        ...action.payload
      };
      break;
    case ActionType.UPDATE_USER_PROFILE:
      state = {
        ...state,
        ...action.payload,
        activeSubscription: { ...state.activeSubscription },
        activeRole: { ...state.activeRole }
      };
      break;
    case ActionType.USER_PROFILE_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
    case ActionType.SET_USER_PROFILE_FORM_VALUE:
      state = { ...action.payload };
      break;
  }
  return state;
}
