import React from "react";
import { Field, FieldArrayRenderProps } from "formik";
import {
  InputLabel,
  MenuItem,
  Grid,
  IconButton,
  Tooltip,
  FormHelperText
} from "@material-ui/core";
import { Delete as DeleteIcon, Add as AddIcon } from "@material-ui/icons";

import { StyledTextField, TextField } from "../../components/TextField";
import { Validators } from "../../util/validators";
import {
  IPayloadFormats,
  IParameters,
  IParameterMethod,
  IUnits,
  IDownlinkFormats
} from "../../models/shared/IShared";
import {
  ActionButton,
  ParameterFieldContainer,
  AnimatedGrid
} from "../../components/sharedStyledComponents";
import { Select, StyledSelect } from "../../components/Select";
import { Configuration } from "../../config";

export const getBasicCalculationFieldName = (
  index: number,
  method?: IParameterMethod
) => {
  const start = `basicCalculationProperties[${index}].method.`;
  if (!method) {
    return `basicCalculationProperties[${index}].text`;
  }
  switch (method.kind) {
    case "BasicCalculation":
      return start + "expression";
    case "PayloadField":
      return start + "expression";
    case "Value":
      return start + "value";
    default:
      return start + "value";
  }
};

export const getValueParametersFieldName = (
  index: number,
  method?: IParameterMethod
) => {
  const start = `valueParameters[${index}].method.`;
  if (!method) {
    return `valueParameters[${index}].text`;
  }
  switch (method.kind) {
    case "BasicCalculation":
      return start + "expression";
    case "PayloadField":
      return start + "expression";
    case "Value":
      return start + "value";
    default:
      return start + "value";
  }
};

export const DevicePayloadFieldParametersFormArray: React.FunctionComponent<
  FieldArrayRenderProps
> = ({
  form: {
    isSubmitting,
    values: { isLoading, payloadFieldProperties }
  },
  push,
  remove
}) => {
  const removeProperty = (index: number) => () => remove(index);

  const addProperty = (e: React.MouseEvent) => {
    e.preventDefault();
    push({
      "@type": "Parameter",
      name: "",
      dataType: "Number",
      method: {
        "@type": "MeasurementMethod",
        kind: "PayloadField"
      },
      uiAttributes: {
        editable: {
          name: true,
          "method.expression": true,
          unitText: true
        },
        deleteable: true
      }
    });
  };

  const { name } = Validators.editDeviceModelParameters;

  return (
    <Grid container={true} item={true} justify="flex-start">
      {payloadFieldProperties.map((property: IParameters, index: number) => {
        const { uiAttributes } = property;
        if (uiAttributes) {
          return (
            <AnimatedGrid
              item={true}
              container={true}
              direction="row"
              xs={12}
              alignItems="center"
              key={index}>
              <Grid item={true} container={true} direction="row" xs={4}>
                <ParameterFieldContainer item={true} xs={12}>
                  <StyledTextField
                    disabled={isLoading}
                    type="text"
                    label="Name"
                    validate={name(payloadFieldProperties, index)}
                    name={`payloadFieldProperties[${index}].name`}
                    component={TextField}
                  />
                </ParameterFieldContainer>
              </Grid>
              {!(isSubmitting || isLoading) && (
                <Tooltip title="Delete">
                  <ActionButton
                    visibility="visible"
                    onClick={removeProperty(index)}
                    aria-label="Delete Property">
                    <DeleteIcon />
                  </ActionButton>
                </Tooltip>
              )}
            </AnimatedGrid>
          );
        }
        return null;
      })}

      {!(isSubmitting || isLoading) &&
        (payloadFieldProperties.length <= 70 ? (
          <IconButton onClick={addProperty}>
            <AddIcon />
          </IconButton>
        ) : (
          <FormHelperText error={true}>70 parameters is maximum</FormHelperText>
        ))}
    </Grid>
  );
};
DevicePayloadFieldParametersFormArray.displayName =
  "DevicePayloadFieldParametersFormArray";

export const DeviceBasicCalculationParametersFormArray: React.FunctionComponent<
  FieldArrayRenderProps
> = ({
  form: {
    isSubmitting,
    values: { isLoading, basicCalculationProperties },
    setFieldValue
  },
  push,
  remove
}) => {
  const removeProperty = (index: number) => () => remove(index);

  const addProperty = (e: React.MouseEvent) => {
    e.preventDefault();
    push({
      "@type": "Parameter",
      name: "",
      dataType: "Number",
      unitCode: "",
      unitText: "",
      featureOf: "",
      method: {
        "@type": "MeasurementMethod",
        kind: "BasicCalculation",
        expression: ""
      },
      uiAttributes: {
        editable: {
          name: true,
          "method.expression": true,
          unitText: true
        },
        deleteable: true
      }
    });
  };

  const { name, expression } = Validators.editDeviceModelParameters;

  const handleUnitChange = (
    event: React.ChangeEvent<{ name?: string; value: string }>
  ) => {
    const { name, value } = event.target;
    const units = Configuration.units
      .filter(unit => unit.unitCode === value)
      .pop();
    if (name && units) {
      setFieldValue(name, units.unitCode);
      setFieldValue(
        name
          .split(".")
          .slice(0, -1)
          .join(".") + ".unitText",
        units.unitText
      );
    }
  };

  return (
    <Grid container={true} item={true} justify="flex-start">
      {basicCalculationProperties.map(
        (property: IParameters, index: number) => {
          const { uiAttributes } = property;
          if (uiAttributes) {
            return (
              <AnimatedGrid
                item={true}
                container={true}
                direction="row"
                xs={12}
                alignItems="center"
                key={index}>
                <Grid item={true} container={true} direction="row" xs={true}>
                  <ParameterFieldContainer item={true} xs={3}>
                    <StyledTextField
                      disabled={isLoading}
                      type="text"
                      label="Name"
                      validate={name(basicCalculationProperties, index)}
                      name={`basicCalculationProperties[${index}].name`}
                      component={TextField}
                    />
                  </ParameterFieldContainer>
                  <ParameterFieldContainer item={true} xs={6}>
                    <StyledTextField
                      type="text"
                      disabled={isLoading}
                      validate={expression}
                      multiline={true}
                      label="Expression"
                      name={getBasicCalculationFieldName(
                        index,
                        property.method
                      )}
                      component={TextField}
                    />
                  </ParameterFieldContainer>
                  <ParameterFieldContainer item={true} xs={3}>
                    <StyledSelect>
                      <InputLabel htmlFor="device.id">Unit</InputLabel>
                      <Field
                        name={`basicCalculationProperties[${index}].unitCode`}
                        label="Unit"
                        onChange={handleUnitChange}
                        component={Select}
                        disabled={isLoading}>
                        {Configuration.units.map((unit: IUnits) => (
                          <MenuItem key={unit.unitCode} value={unit.unitCode}>
                            {unit.unitText}
                          </MenuItem>
                        ))}
                      </Field>
                    </StyledSelect>
                  </ParameterFieldContainer>
                </Grid>
                {!(isSubmitting || isLoading) && (
                  <Tooltip title="Delete">
                    <ActionButton
                      visibility="visible"
                      onClick={removeProperty(index)}
                      aria-label="Delete Property">
                      <DeleteIcon />
                    </ActionButton>
                  </Tooltip>
                )}
              </AnimatedGrid>
            );
          }
          return null;
        }
      )}

      {!(isSubmitting || isLoading) &&
        (basicCalculationProperties.length <= 70 ? (
          <IconButton onClick={addProperty}>
            <AddIcon />
          </IconButton>
        ) : (
          <FormHelperText error={true}>70 parameters is maximum</FormHelperText>
        ))}
    </Grid>
  );
};
DeviceBasicCalculationParametersFormArray.displayName =
  "DeviceBasicCalculationParametersFormArray";

export const DevicePayloadFormatFormArray: React.FunctionComponent<
  FieldArrayRenderProps
> = ({
  form: {
    isSubmitting,
    values: { isLoading, payloadFormats }
  },
  push,
  remove
}) => {
  const removeProperty = (index: number) => () => remove(index);

  const addProperty = (e: React.MouseEvent) => {
    e.preventDefault();
    push({
      contentType: "text/plain;hex",
      format: ""
    });
  };

  const { payloadFormat } = Validators.editDeviceModelPayloadFormats;

  return (
    <Grid container={true} item={true} justify="flex-start">
      {payloadFormats.map((property: IPayloadFormats, index: number) => {
        return (
          <AnimatedGrid
            item={true}
            container={true}
            direction="row"
            xs={12}
            alignItems="center"
            key={index}>
            <Grid item={true} container={true} direction="row" xs={9}>
              <ParameterFieldContainer item={true} xs={12}>
                <StyledTextField
                  disabled={isLoading}
                  type="text"
                  label="Format"
                  validate={payloadFormat(payloadFormats, index)}
                  name={`payloadFormats[${index}].format`}
                  component={TextField}
                  multiline={true}
                />
              </ParameterFieldContainer>
            </Grid>

            {!(isSubmitting || isLoading) && (
              <Tooltip title="Delete">
                <ActionButton
                  visibility="visible"
                  onClick={removeProperty(index)}
                  aria-label="Delete Property">
                  <DeleteIcon />
                </ActionButton>
              </Tooltip>
            )}
          </AnimatedGrid>
        );
      })}

      {!(isSubmitting || isLoading) &&
        (payloadFormats.length <= 70 ? (
          <IconButton onClick={addProperty}>
            <AddIcon />
          </IconButton>
        ) : (
          <FormHelperText error={true}>70 formats is maximum</FormHelperText>
        ))}
    </Grid>
  );
};
DevicePayloadFormatFormArray.displayName = "DevicePayloadFormatFormArray";

export const DeviceValueParametersFormArray: React.FunctionComponent<
  FieldArrayRenderProps
> = ({
  form: {
    isSubmitting,
    values: { isLoading, valueParameters },
    setFieldValue
  },
  push,
  remove
}) => {
  const removeProperty = (index: number) => () => remove(index);

  const addProperty = (e: React.MouseEvent) => {
    e.preventDefault();
    push({
      "@type": "Parameter",
      name: "",
      unitCode: "",
      unitText: "",
      featureOf: "",
      dataType: "Number",
      method: {
        "@type": "MeasurementMethod",
        kind: "Value",
        value: ""
      },
      uiAttributes: {
        editable: {
          name: true,
          "method.expression": true,
          unitText: true
        },
        deleteable: true
      }
    });
  };

  const { value, name } = Validators.editDeviceModelValueParameters;

  const handleUnitChange = (
    event: React.ChangeEvent<{ name?: string; value: string }>
  ) => {
    const { name, value } = event.target;
    const units = Configuration.units
      .filter(unit => unit.unitCode === value)
      .pop();
    if (name && units) {
      setFieldValue(name, units.unitCode);
      setFieldValue(
        name
          .split(".")
          .slice(0, -1)
          .join(".") + ".unitText",
        units.unitText
      );
    }
  };

  return (
    <Grid container={true} item={true} justify="flex-start">
      {valueParameters.map((property: IParameters, index: number) => {
        const { uiAttributes } = property;
        if (uiAttributes) {
          return (
            <AnimatedGrid
              item={true}
              container={true}
              direction="row"
              xs={12}
              alignItems="center"
              key={index}>
              <Grid item={true} container={true} direction="row" xs={true}>
                <ParameterFieldContainer item={true} xs={3}>
                  <StyledTextField
                    disabled={isLoading}
                    type="text"
                    label="Name"
                    validate={name(valueParameters, index)}
                    name={`valueParameters[${index}].name`}
                    component={TextField}
                  />
                </ParameterFieldContainer>
                <ParameterFieldContainer item={true} xs={6}>
                  <StyledTextField
                    type="text"
                    disabled={isLoading}
                    multiline={true}
                    label="Value"
                    validate={value(valueParameters, index)}
                    name={getValueParametersFieldName(index, property.method)}
                    component={TextField}
                  />
                </ParameterFieldContainer>
                <ParameterFieldContainer item={true} xs={3}>
                  <StyledSelect>
                    <InputLabel htmlFor="device.id">Unit</InputLabel>
                    <Field
                      name={`valueParameters[${index}].unitCode`}
                      label="Unit"
                      onChange={handleUnitChange}
                      component={Select}
                      disabled={isLoading}>
                      {Configuration.units.map((unit: IUnits) => (
                        <MenuItem key={unit.unitCode} value={unit.unitCode}>
                          {unit.unitText}
                        </MenuItem>
                      ))}
                    </Field>
                  </StyledSelect>
                </ParameterFieldContainer>
              </Grid>

              {!(isSubmitting || isLoading) && (
                <Tooltip title="Delete">
                  <ActionButton
                    visibility="visible"
                    onClick={removeProperty(index)}
                    aria-label="Delete Property">
                    <DeleteIcon />
                  </ActionButton>
                </Tooltip>
              )}
            </AnimatedGrid>
          );
        }
        return null;
      })}

      {!(isSubmitting || isLoading) &&
        (valueParameters.length <= 70 ? (
          <IconButton onClick={addProperty}>
            <AddIcon />
          </IconButton>
        ) : (
          <FormHelperText error={true}>70 parameters is maximum</FormHelperText>
        ))}
    </Grid>
  );
};
DeviceValueParametersFormArray.displayName = "DeviceValueParametersFormArray";

export const DeviceDownlinkFormatFormArray: React.FunctionComponent<
  FieldArrayRenderProps
> = ({
  form: {
    isSubmitting,
    values: { isLoading, downlinkFormats }
  },
  push,
  remove
}) => {
  const removeProperty = (index: number) => () => remove(index);

  const addProperty = (e: React.MouseEvent) => {
    e.preventDefault();
    push({
      "@type": "DownlinkFormat",
      contentType: "text/plain;hex",
      name: "",
      format: ""
    });
  };

  const {
    downlinkName,
    downlinkFormat
  } = Validators.editDeviceModelDownlinkFormats;

  return (
    <Grid container={true} item={true} justify="flex-start">
      {downlinkFormats.map((property: IDownlinkFormats, index: number) => {
        return (
          <AnimatedGrid
            item={true}
            container={true}
            direction="row"
            xs={12}
            alignItems="center"
            key={index}>
            <Grid item={true} container={true} direction="row" xs={true}>
              <ParameterFieldContainer item={true} xs={3}>
                <StyledTextField
                  disabled={isLoading}
                  type="text"
                  label="Name"
                  validate={downlinkName(downlinkFormats, index)}
                  name={`downlinkFormats[${index}].name`}
                  component={TextField}
                />
              </ParameterFieldContainer>
              <ParameterFieldContainer item={true} xs={9}>
                <StyledTextField
                  disabled={isLoading}
                  type="text"
                  label="Format"
                  validate={downlinkFormat(downlinkFormats, index)}
                  name={`downlinkFormats[${index}].format`}
                  component={TextField}
                  multiline={true}
                />
              </ParameterFieldContainer>
            </Grid>

            {!(isSubmitting || isLoading) && (
              <Tooltip title="Delete">
                <ActionButton
                  visibility="visible"
                  onClick={removeProperty(index)}
                  aria-label="Delete Property">
                  <DeleteIcon />
                </ActionButton>
              </Tooltip>
            )}
          </AnimatedGrid>
        );
      })}
      {!(isSubmitting || isLoading) &&
        (downlinkFormats.length <= 70 ? (
          <IconButton onClick={addProperty}>
            <AddIcon />
          </IconButton>
        ) : (
          <FormHelperText error={true}>70 downlinks is maximum</FormHelperText>
        ))}
    </Grid>
  );
};
DeviceDownlinkFormatFormArray.displayName = "DeviceDownlinkFormatFormArray";
