import {
  IAddUserBySubscription,
  initialInviteUserBySubscription,
  IInviteUserBySubscription,
  initialUserBySubscription,
  IUserBySubscriptionCollectionApi,
  IUserBySubscriptionCollection,
  initialUserBySubscriptionCollection,
  IUserBySubscriptionApi,
  IUserBySubscription,
  initialAddUserBySubscription
} from "../models/IUserBySubscription";
import {
  ISubscriptionCollection,
  ISubscription
} from "../../Subscriptions/models/ISubscription";
import { ServiceUtilities } from "../../core/utilities/ServiceUtilities";
import {
  ISubscriptionSelection,
  ITableDeleteCell,
  IMobileTableRow
} from "../../models/shared/IShared";
import { initialRole, IRole } from "../../Roles/models/IRole";

export const userBySubscriptionCollectionFromApi = (
  collection: IUserBySubscriptionCollectionApi
): IUserBySubscriptionCollection => ({
  ...initialUserBySubscriptionCollection,
  context: collection["@context"],
  type: collection["@type"],
  totalItems: collection.totalItems,
  members: collection.member.map(user => userBySubscriptionFromApi(user)),
  view: collection.view
});

export const userBySubscriptionFromApi = (
  user: IUserBySubscriptionApi
): IUserBySubscription => {
  const result = { ...initialUserBySubscription };
  result.context = user["@context"];
  result.type = user["@type"];
  result.id = user["@id"];
  result.shortId = ServiceUtilities.getShortId(user["@id"]);
  result.email = user.email;

  result.roles = user.roles.map(roleId => {
    return {
      ...initialRole,
      id: roleId
    };
  });

  return result;
};

export const addUserBySubscriptionToApi = (
  user: IUserBySubscription
): IAddUserBySubscription => {
  return {
    ...initialAddUserBySubscription,
    email: user.email,
    roles: user.roles.map(item => {
      return item["id"];
    })
  };
};

export const removeUserBySubscriptionToApi = (
  user: ITableDeleteCell
): IAddUserBySubscription => {
  return {
    ...initialAddUserBySubscription,
    email: user.title,
    roles: []
  };
};

export const inviteUserBySubscriptionToApi = (
  email: string
): IInviteUserBySubscription => {
  return {
    ...initialInviteUserBySubscription,
    email: email
  };
};

export const getSubscriptionName = (
  subId: string,
  subscriptionList: ISubscriptionSelection[]
): string => {
  return (
    subscriptionList
      .filter(sub => sub.value === subId)
      .map(item => item.label)
      .pop() || ""
  );
};

export const getSubscriptionObject = (
  subId: string,
  subscriptionList: ISubscriptionSelection[]
): ISubscriptionSelection => {
  return (
    subscriptionList.filter(sub => sub.value === subId).pop() || {
      ...initialUserBySubscription.subscription
    }
  );
};

export const getSubscriptionCollectionObject = (
  subscriptionList: ISubscriptionCollection
): ISubscriptionSelection[] => {
  return subscriptionList.members.map(item => {
    return {
      value: item.id,
      label: item.name
    };
  });
};

export const userBySubscriptionToMobileTableRow = (
  members: IUserBySubscription[]
): IMobileTableRow[] => {
  return members.map(item => {
    return {
      id: item.id,
      title: item.email,
      fields: [
        { label: "Roles", value: item.roles.map(role => role.name).join(", ") }
      ],
      editPageLink: {
        pathname: "/users/" + ServiceUtilities.getShortId(item.id),
        state: {
          userBySubscription: true
        }
      }
    };
  });
};

export const getRoleName = (
  users: IUserBySubscription[],
  roles: IRole[]
): IUserBySubscription[] =>
  users.map(user => ({
    ...user,
    roles: user.roles.map(role => {
      return roles.filter(item => item.id === role.id).pop() || initialRole;
    })
  }));

export const onlyUnique = (value: string, index: number, array: string[]) =>
  array.indexOf(value) === index;

export const getActiveSubscriptionObject = (
  subscription: ISubscription
): ISubscriptionSelection => ({
  value: subscription.id,
  label: subscription.name
});

export const uniqueSubCollection = (
  subscriptions: ISubscriptionCollection,
  activeSubscription: ISubscription
): ISubscriptionSelection[] =>
  getSubscriptionCollectionObject(subscriptions)
    .concat(getActiveSubscriptionObject(activeSubscription))
    .filter(
      (subscription, index, subscriptionsList) =>
        subscriptionsList
          .map(item => item.value)
          .indexOf(subscription.value) === index
    );
