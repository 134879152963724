import React from "react";
import { TableRow, Button } from "@material-ui/core";

import { IDevice } from "../../models/IDevice";
import { ITableDeleteCell } from "../../../models/shared/IShared";
import {
  NoStyleLink,
  ButtonTableCell,
  SmallIcon,
  SmallDeleteIcon,
  StyledTableCell,
  SmallBuildIcon
} from "../../../components/sharedStyledComponents";

interface IDeviceTableRowProps {
  device: IDevice;
  handleDialogOpen: (
    deviceInfo: ITableDeleteCell
  ) => (_: React.MouseEvent) => void;
}

export const DeviceTableRow: React.FunctionComponent<IDeviceTableRowProps> = ({
  device: {
    id,
    shortId,
    name,
    serial,
    deviceModel: { name: modelName },
    note
  },
  handleDialogOpen
}: IDeviceTableRowProps) => (
  <TableRow key={id}>
    <StyledTableCell>{name}</StyledTableCell>
    <StyledTableCell>{serial}</StyledTableCell>
    <StyledTableCell>{modelName}</StyledTableCell>
    <StyledTableCell>{note}</StyledTableCell>
    <ButtonTableCell>
      <NoStyleLink to={"/devices/" + shortId}>
        <Button color="primary">
          Edit <SmallIcon />
        </Button>
      </NoStyleLink>
      <br />
      <NoStyleLink to={`/devices/${shortId}/calibrate`}>
        <Button color="primary">
          Calibrate <SmallBuildIcon />
        </Button>
      </NoStyleLink>
    </ButtonTableCell>
    <ButtonTableCell>
      <Button
        color="primary"
        onClick={handleDialogOpen({
          visible: true,
          id: id,
          title: name
        })}>
        Delete <SmallDeleteIcon />
      </Button>
    </ButtonTableCell>
  </TableRow>
);
