import { IDeviceCollection, initialDeviceCollection } from "../models/IDevice";
import { ActionType } from "../../models/Action.model";

export type deviceCollectionReducerTypes =
  | { type: ActionType.GET_DEVICE_COLLECTION; payload: IDeviceCollection }
  | { type: ActionType.DEVICE_COLLECTION_LOADING; payload: boolean }
  | { type: ActionType.DEVICE_SEARCH_BY_SERIAL_SET_VALUE; payload: string };

export function deviceCollectionReducer(
  state: IDeviceCollection = { ...initialDeviceCollection },
  action: deviceCollectionReducerTypes
): IDeviceCollection {
  switch (action.type) {
    case ActionType.GET_DEVICE_COLLECTION:
      state = {
        ...action.payload,
        searchBySerial: state.searchBySerial
      };
      break;
    case ActionType.DEVICE_COLLECTION_LOADING:
      state = {
        ...state,
        ...(action.payload && initialDeviceCollection),
        searchBySerial: state.searchBySerial,
        isLoading: action.payload
      };
      break;
    case ActionType.DEVICE_SEARCH_BY_SERIAL_SET_VALUE:
      state = {
        ...state,
        searchBySerial: action.payload
      };
      break;
  }
  return state;
}
