import React from "react";
import { connect } from "react-redux";
import {
  Grid,
  Hidden,
  Button,
  LinearProgress,
  FormControl
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import Select from "react-select";
import { ValueType } from "react-select/src/types";
import { withSnackbar, WithSnackbarProps } from "notistack";

import { IRootState } from "../store";
import { withThemeProvider } from "../util/withThemeProvider";
import { IPolicyCollection, IPolicy } from "./models/IPolicy";
import { ITableDeleteCell } from "../models/shared/IShared";
import {
  getPolicyCollection,
  changePolicyCollectionPage,
  setPolicySubscriptionSelectionValue
} from "./actions/policyCollectionAction";
import { MobileTable } from "../components/MobileTable/MobileTable";
import { PoliciesTable } from "./components/PoliciesTable";
import {
  NoStyleLink,
  StyledTitle,
  StyledFormWrapper,
  NewStyledPaper
} from "../components/sharedStyledComponents";
import {
  alertMessages,
  isSnackbarError,
  SnackbarError,
  getStyledSnackbarOptions
} from "../core/utilities/AlertUtilities";
import { PolicyDeleteDialog } from "./components/PolicyDeleteDialog";
import {
  policyDeleteConfirmDialog,
  deletePolicy
} from "./actions/policyAction";
import { IGlobalState } from "../models/GlobalState";
import { ISubscriptionSelection } from "../models/shared/IShared";
import {
  StyledInputLabel,
  SubSelectorForm
} from "../UsersBySubscription/UsersBySubscription";
import { IUserProfile } from "../Profile/models/IUserProfile";
import { policiesToMobileTableRow } from "./services/PolicyService";

interface IPoliciesStateProps {
  policyCollection: IPolicyCollection;
  policy: IPolicy;
  global: IGlobalState;
  profile: IUserProfile;
}

interface IPoliciesDispatchProps {
  getPolicyCollection: (
    subId: string,
    masterAdmin: boolean
  ) => Promise<IPolicyCollection | SnackbarError>;
  changePage: (
    page: string,
    subId: string,
    masterAdmin: boolean
  ) => Promise<IPolicyCollection | SnackbarError>;
  policyDeleteConfirmDialog: (policyInfo: ITableDeleteCell) => void;
  deletePolicy: (
    policyInfo: ITableDeleteCell
  ) => Promise<number | SnackbarError>;
  setPolicySubscriptionSelectionValue: (
    selectedValue: ISubscriptionSelection
  ) => void;
}

type PoliciesType = IPoliciesStateProps &
  IPoliciesDispatchProps &
  WithSnackbarProps;

export class Policies extends React.Component<PoliciesType> {
  public fetchPolicies = async (page?: string) => {
    const {
      getPolicyCollection,
      changePage,
      enqueueSnackbar,
      policyCollection: {
        policySubscriptionSelection: { value: policySubscriptionSelectionValue }
      },
      global: {
        masterAdmin,
        user: { subId }
      }
    } = this.props;
    let collection: IPolicyCollection | SnackbarError | undefined;
    if (page) {
      collection = await changePage(
        page,
        policySubscriptionSelectionValue || subId,
        masterAdmin
      );
    } else {
      collection = await getPolicyCollection(
        policySubscriptionSelectionValue || subId,
        masterAdmin
      );
    }
    if (collection && isSnackbarError(collection)) {
      enqueueSnackbar(collection.message, collection.options);
    }
  };

  public changePage = (page: string) => {
    this.fetchPolicies(page);
  };

  public async componentDidMount() {
    this.fetchPolicies();
  }

  public handlePolicyDeleteDialogOpen = (policyInfo: ITableDeleteCell) => (
    _: React.MouseEvent
  ) => {
    const { policyDeleteConfirmDialog } = this.props;
    policyDeleteConfirmDialog(policyInfo);
  };

  public handlePolicyDeleteDialogClose = (policyInfo: ITableDeleteCell) => {
    const { policyDeleteConfirmDialog } = this.props;
    policyDeleteConfirmDialog({ ...policyInfo, visible: false });
  };

  public deletePolicy = async (policyInfo: ITableDeleteCell) => {
    const { deletePolicy, enqueueSnackbar } = this.props;
    const deletePolicyResult = await deletePolicy(policyInfo);
    if (deletePolicyResult === 204) {
      enqueueSnackbar(
        alertMessages.POLICY_DELETE_SUCCESS,
        getStyledSnackbarOptions("success")
      );
    }
    if (isSnackbarError(deletePolicyResult)) {
      enqueueSnackbar(deletePolicyResult.message, deletePolicyResult.options);
    }
  };

  public handleChange = (
    selectedValue: ValueType<{ value: string; label: string }>
  ) => {
    const {
      getPolicyCollection,
      setPolicySubscriptionSelectionValue,
      policyCollection: {
        policySubscriptionSelection: { value: policySubscriptionSelectionValue }
      },
      global: { masterAdmin }
    } = this.props;

    if (
      selectedValue &&
      selectedValue["value"] !== policySubscriptionSelectionValue
    ) {
      setPolicySubscriptionSelectionValue({
        label: selectedValue["label"],
        value: selectedValue["value"]
      });
      getPolicyCollection(selectedValue["value"], masterAdmin);
    }
  };

  public render() {
    const {
      policyCollection: {
        members,
        view,
        totalItems,
        isLoading,
        policySubscriptionSelection,
        policySubscriptionSelectionList
      },
      policy: { policyDelete, isLoading: policyIsLoading },
      global: { masterAdmin },
      profile: {
        activeSubscription: { name }
      }
    } = this.props;

    return (
      <React.Fragment>
        <StyledFormWrapper>
          <Grid container={true}>
            <Grid item={true} xs={5} sm={8} md={8}>
              {masterAdmin ? (
                <StyledTitle margintop="8px">
                  {`Policies of "${policySubscriptionSelection.label ||
                    "..."}"`}
                </StyledTitle>
              ) : (
                <StyledTitle margintop="8px">
                  {`Policies of "${name || "..."}"`}
                </StyledTitle>
              )}
            </Grid>
            <Grid item={true} xs={3} sm={1} md={2} />
            <Grid item={true} xs={4} sm={3} md={2}>
              <StyledTitle textalign="right">
                <NoStyleLink to="/policies/create">
                  <Button color="default">
                    <AddIcon />
                    Create
                  </Button>
                </NoStyleLink>
              </StyledTitle>
            </Grid>
          </Grid>
          <NewStyledPaper>
            {masterAdmin && (
              <React.Fragment>
                <StyledInputLabel htmlFor="subscription">
                  subscription
                </StyledInputLabel>
                <SubSelectorForm>
                  <FormControl>
                    <Select
                      options={policySubscriptionSelectionList}
                      value={policySubscriptionSelection}
                      onChange={this.handleChange}
                      isClearable={false}
                      isDisabled={isLoading}
                    />
                  </FormControl>
                </SubSelectorForm>
              </React.Fragment>
            )}
            <Hidden smDown={true}>
              <PoliciesTable
                isLoading={isLoading}
                pagination={view}
                rows={members}
                totalItems={totalItems}
                onChangePage={this.changePage}
                handleDialogOpen={this.handlePolicyDeleteDialogOpen}
              />
            </Hidden>
            <Hidden mdUp={true}>
              <MobileTable
                rows={policiesToMobileTableRow(members)}
                pagination={view}
                totalItems={totalItems}
                onChangePage={this.changePage}
                handleDialogOpen={this.handlePolicyDeleteDialogOpen}
                isLoading={isLoading}
              />
            </Hidden>
            {isLoading && (
              <React.Fragment>
                <br /> <LinearProgress /> <br />
              </React.Fragment>
            )}
          </NewStyledPaper>
        </StyledFormWrapper>
        <br />
        <br />
        <PolicyDeleteDialog
          policyInfo={policyDelete}
          handleDeleteDialogClose={this.handlePolicyDeleteDialogClose}
          deletePolicy={this.deletePolicy}
          isLoading={policyIsLoading}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IPoliciesStateProps => ({
  policyCollection: state.policyCollection,
  policy: state.policy,
  global: state.globalState,
  profile: state.userProfileReducer
});

const mapDispatchToProps = (dispatch: any): IPoliciesDispatchProps => {
  return {
    getPolicyCollection: (
      subId: string,
      masterAdmin: boolean
    ): Promise<IPolicyCollection | SnackbarError> => {
      return dispatch(getPolicyCollection(subId, masterAdmin));
    },
    changePage: (
      page: string,
      subId: string,
      masterAdmin
    ): Promise<IPolicyCollection | SnackbarError> => {
      return dispatch(changePolicyCollectionPage(page, subId, masterAdmin));
    },
    policyDeleteConfirmDialog: (policyInfo: ITableDeleteCell) => {
      return dispatch(policyDeleteConfirmDialog(policyInfo));
    },
    deletePolicy: (
      policyInfo: ITableDeleteCell
    ): Promise<number | SnackbarError> => {
      return dispatch(deletePolicy(policyInfo));
    },
    setPolicySubscriptionSelectionValue: (
      selectedValue: ISubscriptionSelection
    ) => {
      dispatch(setPolicySubscriptionSelectionValue(selectedValue));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(Policies)));
