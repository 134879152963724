import { theme } from "./util/withThemeProvider";
import { AxiosRequestConfig } from "axios";


//const development = process.env.NODE_ENV === "test"; 

// nodeEnv is supposed to be development, test or production, the default is test
const nodeEnv = process.env.NODE_ENV ;  
const development = nodeEnv === "development";

const heramesApiUrl = "https://api.herames.net";
const ellenexApiUrl = "https://api.ellenex.net"; 

export const usersPrefixURL = "/v1/esma/users/";
export const subscriptionsPrefixURL = "/v1/esma/subscriptions/";
export const rolesPrefixURL = "/v1/erama/roles/";
export const policiesPrefixURL = "/v1/erama/policies/";
export const deviceModelsPrefixURL = "/v1/edi/devicemodels/";
export const devicesPrefixURL = "/v1/edi/devices/";

function getValueBasedonEnv( development:string, test: string, production:string){

    return production; 
    /*
    switch(nodeEnv){
      case 'development':{
        return development;
      }
      case 'test':{
        return test;
      }
      case 'production':{
        return production; 
      }
      default:{
        return test; 
      }
    }
    */
}; 

export const Configuration = {
  development,
  EdiAPIBaseUrl: getValueBasedonEnv(
    "https://zgw860m7j4.execute-api.ap-southeast-2.amazonaws.com/dev", 
    heramesApiUrl, 
    ellenexApiUrl
  ),

  EdiAPIUrl: getValueBasedonEnv(
    "https://zgw860m7j4.execute-api.ap-southeast-2.amazonaws.com/dev/v1/edi", 
    heramesApiUrl + "/v1/edi", 
    ellenexApiUrl + "/v1/edi"
  ),
  
  EsmaAPIBaseUrl: getValueBasedonEnv(
    "https://x99oimnc68.execute-api.ap-southeast-2.amazonaws.com/dev", 
    heramesApiUrl , 
    ellenexApiUrl 
  ),

  EsmaAPIUrl: getValueBasedonEnv(
    "https://x99oimnc68.execute-api.ap-southeast-2.amazonaws.com/dev/v1/esma", 
    heramesApiUrl + "/v1/esma", 
    ellenexApiUrl + "/v1/esma"
  ),
 
  EramaAPIBaseUrl: getValueBasedonEnv(
    "https://vaxsuaptwb.execute-api.ap-southeast-2.amazonaws.com/dev", 
    heramesApiUrl , 
    ellenexApiUrl 
  ),
  
  EramaAPIUrl: getValueBasedonEnv(
    "https://vaxsuaptwb.execute-api.ap-southeast-2.amazonaws.com/dev/v1/erama", 
    heramesApiUrl + "/v1/erama", 
    ellenexApiUrl + "/v1/erama"
  ),

  AuthApiUrl: getValueBasedonEnv(
    "https://r2fypacxjf.execute-api.ap-southeast-2.amazonaws.com/dev/v1/auth/token", 
    heramesApiUrl + "/v1/auth/token", 
    ellenexApiUrl + "/v1/auth/token"
  ),
  
  EcaAPIBaseUrl: getValueBasedonEnv(
    "https://k4ecd40g1d.execute-api.ap-southeast-2.amazonaws.com/dev", 
    heramesApiUrl , 
    ellenexApiUrl 
  ),

  EcaAPIUrl: getValueBasedonEnv(
    "https://k4ecd40g1d.execute-api.ap-southeast-2.amazonaws.com/dev/v1/eca", 
    heramesApiUrl + "/v1/eca", 
    ellenexApiUrl + "/v1/eca"
  ),

  EdsaAPIBaseUrl: getValueBasedonEnv(
    "https://6o9vlm6gfl.execute-api.ap-southeast-2.amazonaws.com/dev", 
    heramesApiUrl , 
    ellenexApiUrl 
  ),
  
  EdsaAPIUrl: getValueBasedonEnv(
    "https://6o9vlm6gfl.execute-api.ap-southeast-2.amazonaws.com/dev/v1/edsa", 
    heramesApiUrl + "/v1/edsa", 
    ellenexApiUrl + "/v1/edsa"
  ),
  
  menuWidth: 300,
  menuHoverColor: "rgba(0, 0, 0, 0.12)",
  rowsPerTablePage: 30,
  SubscriptionsRowsPerTablePage: 100,  /// edited by Fred
  RolesRowsPerTablePage: 100,
  PoliciesRowsPerTablePage: 100,
  isMobile: window.innerWidth > theme.breakpoints.values.md,
  clientVersionCheckIntervalMs: 10000,
  snackbarAutohideDuration: {
    success: 4000,
    error: 15000
  },

  auth:{
    cognitoRoot: getValueBasedonEnv(
      "https://nimausers.auth.ap-southeast-2.amazoncognito.com/",
      "https://beta-ellenex-net.auth.ap-southeast-2.amazoncognito.com/",
      "https://auth.ellenex.net/"
    ),
    login: "login",
    logout: "logout",
    redirectUri: getValueBasedonEnv(
      "http://localhost:3000/",
      document.location ? document.location.origin : "",
      document.location ? document.location.origin : "",
    ),
    clientId: getValueBasedonEnv(
      "56475e2uadjv4jae48ls3p5gdg",
      "ibq5s4341ha3iscp5qk9v5i10",
      "cevmm3qli86u7j4lsu6hks520"
    )
  },

  permissions: {
    access: [
      { text: "Allow", value: "Allow" },
      { text: "Deny", value: "Deny" }
    ],
    methods: [
      { text: "GET", value: "GET" },
      { text: "POST", value: "POST" },
      { text: "DELETE", value: "DELETE" },
      { text: "PUT", value: "PUT" }
    ]
  },
  units: [
    { unitCode: "", unitText: "" },
    { unitCode: "MTR", unitText: "m" },
    { unitCode: "CEL", unitText: "°C" },
    { unitCode: "FAH", unitText: "°F" },
    { unitCode: "VLT", unitText: "V" },
    { unitCode: "BAR", unitText: "bar" },
    { unitCode: "PAL", unitText: "Pa" },
    { unitCode: "UGA", unitText: "U.S. Gallon" },
    { unitCode: "L", unitText: "L" },
    { unitCode: "MAL", unitText: "ML" },
    { unitCode: "G/ML", unitText: "g/mL" },
    { unitCode: "%", unitText: "%" }
  ],
  calibration: {
    historyTableDateFormat: "LL"
  }
};

export const ediApiHeaders: AxiosRequestConfig = {
  headers: {
    "x-api-key": getValueBasedonEnv(
      "3HUp0NiyTLpslBkrhVEt7pNMvh9oSul4WGKgbrl4",
      "6QYFxhuG7e8EASKxD6BpF2SFGv1WyEZx2i6BQGr4",
      "UmEgY1iPtp7fRw2WLNHvd97POL5qFJAoaAjLs9SP"
    )
  }
  

};

export const esmaApiHeaders: AxiosRequestConfig = {
  headers: {
    "x-api-key": getValueBasedonEnv(
      "wuO5LFbN7c7HyiKMStpAO2AdyaqYJv9mDduvz6Z0",
      "lHXlMHYfC39dFUqkHcBdralH7fggUNRuSGOnzZTa",
      "UmEgY1iPtp7fRw2WLNHvd97POL5qFJAoaAjLs9SP"
    )
  }
  
};

export const authApiHeaders: AxiosRequestConfig = {
  headers: {
    "x-api-key": getValueBasedonEnv(
      "J6i8kwTLcfamIeC2aXZOc5oBcU19yjYQuyZ8nBnc",
      "Nmgs3RYCB2zi9wLfFMvR1BU8qEtf3QiaMdVALocj",
      "UmEgY1iPtp7fRw2WLNHvd97POL5qFJAoaAjLs9SP"
    )
  }

};

export const eramaApiHeaders: AxiosRequestConfig = {
  headers: {
    "x-api-key": getValueBasedonEnv(
      "DpIUFJ7KpzaDr96kIedRM440mRJq2DTO9L0e9hQq",
      "CVhPZkMS538XDP8ELbGzuaEU063cMain790tk38W",
      "UmEgY1iPtp7fRw2WLNHvd97POL5qFJAoaAjLs9SP"
    )
  }
  
};

export const ecaApiHeaders: AxiosRequestConfig = {
  headers: {
    "x-api-key": getValueBasedonEnv(
      "2gwzF765zhxVxgqTS7TrP4JVrMfsYyHAxA8dc9eN",
      "soosaKo6Bu5ofoZoaHauhie9phoo9Xeeop1Feiz7",
      ""
    )
  }
  
};

export const edsaApiHeaders: AxiosRequestConfig = {
  headers: {
    "x-api-key": getValueBasedonEnv(
      "7uGegXGEq310z9KCEdLCiaTf3RWZKHQZ8ZqgwWIV",
      "7uGegXGEq310z9KCEdLCiaTf3RWZKHQZ8ZqgwWIV",
      ""
    )
  }
  
};
