import {
  IGlobalState,
  ICurrentUser,
  initialGlobalState
} from "../models/GlobalState";
import { ActionType } from "../models/Action.model";

export type globalStateReducerTypes =
  | { type: ActionType.OPEN_MENU_CHANGE; payload: boolean }
  | { type: ActionType.AUTHENTICATION; payload: ICurrentUser }
  | { type: ActionType.TOOLBAR_MENU_CHANGE_STATE; payload: any }
  | { type: ActionType.REFRESH_REQUIRED }
  | { type: ActionType.EXPIRED_SESSION; payload: boolean }
  | { type: ActionType.CUSTOMER_ADMIN; payload: boolean }
  | { type: ActionType.CLIENT_UPDATE }
  | { type: ActionType.LOGOUT }
  | { type: ActionType.IS_MASTER_ADMIN; payload: boolean }
  | { type: ActionType.NORMAL_USER; payload: boolean }
  | { type: ActionType.LOGIN_ERROR; payload: boolean }
  | { type: ActionType.RESET_USER_ACCESS_LEVEL };

export function globalStateReducer(
  state: IGlobalState = { ...initialGlobalState },
  action: globalStateReducerTypes
): IGlobalState {
  switch (action.type) {
    case ActionType.OPEN_MENU_CHANGE:
      state = {
        ...state,
        menuOpen: action.payload
      };
      break;
    case ActionType.REFRESH_REQUIRED:
      state = {
        ...state,
        refreshRequired: true
      };
      break;
    case ActionType.CLIENT_UPDATE:
      state = {
        ...state,
        refreshRequired: false
      };
      break;
    case ActionType.AUTHENTICATION:
      state = {
        ...state,
        user: action.payload
      };
      break;
    case ActionType.TOOLBAR_MENU_CHANGE_STATE:
      state = {
        ...state,
        toolbarMenuOpen: action.payload
      };
      break;
    case ActionType.EXPIRED_SESSION:
      state = {
        ...state,
        expiredSession: action.payload
      };
      break;
    case ActionType.CUSTOMER_ADMIN:
      state = {
        ...state,
        customerAdmin: action.payload
      };
      break;
    case ActionType.IS_MASTER_ADMIN:
      state = {
        ...state,
        masterAdmin: action.payload
      };
      break;
    case ActionType.NORMAL_USER:
      state = {
        ...state,
        normalUser: action.payload
      };
      break;
    case ActionType.LOGIN_ERROR:
      state = {
        ...state,
        loginError: action.payload
      };
      break;
    case ActionType.RESET_USER_ACCESS_LEVEL:
      state = {
        ...state,
        masterAdmin: false,
        customerAdmin: false,
        normalUser: false
      };
      break;
  }
  return state;
}
