import { theme } from "../util/withThemeProvider";

export interface ICurrentUser {
  expDate: string;
  roleId: string;
  subId: string;
  userId: string;
}

export interface IGlobalState {
  menuOpen: boolean;
  refreshRequired: boolean;
  expiredSession: boolean;
  customerAdmin: boolean;
  normalUser: boolean;
  toolbarMenuOpen: any;
  user: ICurrentUser;
  masterAdmin: boolean;
  loginError: boolean;
}

export const initialGlobalState: IGlobalState = {
  menuOpen: window.innerWidth > theme.breakpoints.values.md,
  refreshRequired: false,
  expiredSession: false,
  customerAdmin: false,
  normalUser: false,
  toolbarMenuOpen: null,
  user: {
    expDate: "",
    roleId: "",
    subId: "",
    userId: ""
  },
  masterAdmin: false,
  loginError: false
};
