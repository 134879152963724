import {
  initialUserBySubscriptionCollection,
  IUserBySubscriptionCollection
} from "../models/IUserBySubscription";
import { ActionType } from "../../models/Action.model";

export type userBySubscriptionCollectionReducerTypes =
  | {
      type: ActionType.GET_USER_BY_SUBSCRIPTION_COLLECTION;
      payload: IUserBySubscriptionCollection;
    }
  | {
      type: ActionType.CHANGE_USER_BY_SUBSCRIPTION_COLLECTION_PAGE;
      payload: IUserBySubscriptionCollection;
    }
  | {
      type: ActionType.USER_BY_SUBSCRIPTION_COLLECTION_LOADING;
      payload: boolean;
    }
  | { type: ActionType.CLEAR_USER_BY_SUBSCRIPTION_COLLECTION };

export function userBySubscriptionCollectionReducer(
  state: IUserBySubscriptionCollection = {
    ...initialUserBySubscriptionCollection
  },
  action: userBySubscriptionCollectionReducerTypes
): IUserBySubscriptionCollection {
  switch (action.type) {
    case ActionType.GET_USER_BY_SUBSCRIPTION_COLLECTION:
      state = {
        ...action.payload,
        page: 0
      };
      break;
    case ActionType.CHANGE_USER_BY_SUBSCRIPTION_COLLECTION_PAGE:
      state = {
        ...action.payload,
        page: action.payload.page - 1
      };
      break;
    case ActionType.USER_BY_SUBSCRIPTION_COLLECTION_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
    case ActionType.CLEAR_USER_BY_SUBSCRIPTION_COLLECTION:
      state = { ...initialUserBySubscriptionCollection };

      break;
  }
  return state;
}
