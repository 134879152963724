import React from "react";
import { connect } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { Formik } from "formik";
import { FormikActions } from "formik/dist/types";
import { RouteComponentProps } from "react-router";
import { withSnackbar, WithSnackbarProps } from "notistack";

import { withThemeProvider } from "../util/withThemeProvider";
import { IRootState } from "../store";
import { IDeviceModel } from "./models/IDeviceModel";
import { IGlobalState } from "../models/GlobalState";
import { CreateDeviceModelForm } from "./components/CreateDeviceModelForm";
import { EditDeviceModelForm } from "./components/EditDeviceModelForm";
import {
  clearDeviceModel,
  createDeviceModel,
  getDeviceModelById,
  initializeDeviceModelEdit,
  updateDeviceModelById
} from "./actions/deviceModelAction";
import {
  NoStyleLink,
  StyledTitle,
  StyledBackground,
  StyledFormWrapper,
  StyledListIcon
} from "../components/sharedStyledComponents";
import {
  alertMessages,
  SnackbarError,
  isSnackbarError,
  getStyledSnackbarOptions
} from "../core/utilities/AlertUtilities";

interface IPathParamsType {
  id: string;
}

interface IEditDeviceModelStateProps {
  deviceModel: IDeviceModel;
  global: IGlobalState;
}

interface IEditDeviceModelDispatchProps {
  initializeDeviceModel: (
    setLoadingState: boolean,
    subId: string
  ) => Promise<IDeviceModel | SnackbarError>;
  createDeviceModel: (
    deviceModel: IDeviceModel
  ) => Promise<IDeviceModel | SnackbarError>;
  getDeviceModel: (
    id: string,
    initializedDevice?: IDeviceModel
  ) => Promise<IDeviceModel | SnackbarError>;
  updateDeviceModel: (
    id: string,
    deviceModel: IDeviceModel
  ) => Promise<IDeviceModel | SnackbarError>;
  clearDeviceModel: () => void;
}

type EditDeviceModelType = IEditDeviceModelStateProps &
  IEditDeviceModelDispatchProps &
  RouteComponentProps<IPathParamsType> &
  WithSnackbarProps;

export class EditDeviceModel extends React.Component<EditDeviceModelType> {
  public async componentDidMount() {
    const {
      initializeDeviceModel,
      getDeviceModel,
      enqueueSnackbar,
      match: {
        params: { id }
      },
      global: {
        user: { subId }
      }
    } = this.props;

    const result = await initializeDeviceModel(!Boolean(id), subId);

    if (isSnackbarError(result)) {
      enqueueSnackbar(result.message, result.options);
    } else if (id && typeof result === "object") {
      const getDeviceModelResult = await getDeviceModel(id, result);

      if (isSnackbarError(getDeviceModelResult)) {
        enqueueSnackbar(
          getDeviceModelResult.message,
          getDeviceModelResult.options
        );
      }
    }
  }

  public componentWillUnmount() {
    const {
      deviceModel: { subscription },
      clearDeviceModel,
      match: {
        params: { id }
      },
      global: {
        user: { subId }
      }
    } = this.props;

    if (id || subscription.shortId !== subId) {
      clearDeviceModel();
    }
  }

  public onSubmit = async (
    values: IDeviceModel,
    { setSubmitting }: FormikActions<IDeviceModel>
  ) => {
    const {
      createDeviceModel,
      updateDeviceModel,
      history,
      enqueueSnackbar,
      global: {
        user: { subId }
      },
      match: {
        params: { id }
      }
    } = this.props;
    if (values) {
      if (id) {
        const updateResult = await updateDeviceModel(values.id, values);

        if (!isSnackbarError(updateResult) && updateResult !== undefined) {
          enqueueSnackbar(
            alertMessages.DEVICEMODEL_UPDATE_SUCCESS,
            getStyledSnackbarOptions("success")
          );
          if (updateResult.subscription.shortId !== subId) {
            history.push("/deviceModels");
          }
        } else if (isSnackbarError(updateResult)) {
          enqueueSnackbar(updateResult.message, updateResult.options);
        }
      } else {
        const createResult = await createDeviceModel(values);

        if (!isSnackbarError(createResult) && createResult !== undefined) {
          enqueueSnackbar(
            alertMessages.DEVICEMODEL_CREATE_SUCCESS,
            getStyledSnackbarOptions("success")
          );

          if (createResult.subscription.shortId === subId) {
            history.push("/deviceModels/" + createResult.shortId);
          } else {
            history.push("/deviceModels");
          }
        } else if (isSnackbarError(createResult)) {
          enqueueSnackbar(createResult.message, createResult.options);
        }
      }
      setSubmitting(false);
    }
  };

  public render() {
    const {
      deviceModel,
      match: {
        params: { id }
      }
    } = this.props;
    return (
      <React.Fragment>
        <StyledFormWrapper>
          <StyledTitle marginbottom="10px">
            {id
              ? deviceModel.isLoading
                ? "Loading..."
                : deviceModel.name
              : "New Device Model"}
          </StyledTitle>
          <StyledBackground>
            <Grid container={true} item={true} xs={12} justify="flex-end">
              <NoStyleLink to="/deviceModels">
                <Button color="default">
                  <StyledListIcon />
                  List
                </Button>
              </NoStyleLink>
            </Grid>
            {id ? (
              <Formik
                enableReinitialize={true}
                initialValues={deviceModel}
                onSubmit={this.onSubmit}
                render={EditDeviceModelForm}
              />
            ) : (
              <Formik
                enableReinitialize={true}
                initialValues={deviceModel}
                onSubmit={this.onSubmit}
                render={CreateDeviceModelForm}
              />
            )}
          </StyledBackground>
        </StyledFormWrapper>

        <br />
        <br />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IEditDeviceModelStateProps => ({
  deviceModel: state.deviceModel,
  global: state.globalState
});

const mapDispatchToProps = (dispatch: any): IEditDeviceModelDispatchProps => {
  return {
    initializeDeviceModel: (
      setLoadingState: boolean,
      subId: string
    ): Promise<IDeviceModel | SnackbarError> => {
      return dispatch(initializeDeviceModelEdit(setLoadingState, subId));
    },
    createDeviceModel: (
      deviceModel: IDeviceModel
    ): Promise<IDeviceModel | SnackbarError> => {
      return dispatch(createDeviceModel(deviceModel));
    },
    getDeviceModel: (
      id: string,
      initializedDeviceModel?: IDeviceModel
    ): Promise<IDeviceModel | SnackbarError> => {
      return dispatch(getDeviceModelById(id, initializedDeviceModel));
    },
    updateDeviceModel: (
      id: string,
      deviceModel: IDeviceModel
    ): Promise<IDeviceModel | SnackbarError> => {
      return dispatch(updateDeviceModelById(id, deviceModel));
    },
    clearDeviceModel: () => {
      dispatch(clearDeviceModel());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(EditDeviceModel)));
