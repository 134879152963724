import { initialUser, IUser } from "../models/IUser";
import { ActionType } from "../../models/Action.model";

export type userReducerType =
  | { type: ActionType.INITIALIZE_USER_EDIT; payload: IUser }
  | { type: ActionType.GET_USER; payload: IUser }
  | { type: ActionType.CREATE_USER; payload: IUser }
  | { type: ActionType.UPDATE_USER; payload: IUser }
  | { type: ActionType.USER_LOADING; payload: boolean }
  | { type: ActionType.CLEAR_USER }
  | { type: ActionType.SET_USER_FORM_VALUE; payload: IUser }
  | { type: ActionType.UPDATE_USERS_HASROLES_ROLELIST; payload: IUser };

export function userReducer(
  state: IUser = { ...initialUser },
  action: userReducerType
): IUser {
  switch (action.type) {
    case ActionType.GET_USER:
      state = {
        ...state,
        ...action.payload,
        subscriptionList: state.subscriptionList
      };

      break;
    case ActionType.CREATE_USER:
      state = {
        ...state,
        ...action.payload,
        subscriptionList: state.subscriptionList
      };
      break;
    case ActionType.UPDATE_USER:
      state = {
        ...state,
        ...action.payload,
        subscriptionList: state.subscriptionList
      };
      break;
    case ActionType.USER_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
    case ActionType.INITIALIZE_USER_EDIT: {
      state = {
        ...state,
        subscriptionList: action.payload.subscriptionList
      };
      break;
    }
    case ActionType.CLEAR_USER:
      state = { ...initialUser };
      break;
    case ActionType.SET_USER_FORM_VALUE:
      state = { ...action.payload };
      break;
    case ActionType.UPDATE_USERS_HASROLES_ROLELIST:
      state = { ...action.payload };
      break;
  }

  return state;
}
