import React from "react";
import { TableBody } from "@material-ui/core";

import { IDevice } from "../models/IDevice";
import { ITableDeleteCell, IPaginationView } from "../../models/shared/IShared";
import { StyledTable } from "../../components/sharedStyledComponents";
import { DeviceTableHeader } from "./DeviceTable/DeviceTableHeader";
import { DeviceTableRow } from "./DeviceTable/DeviceTableRow";
import { DeviceTableFooter } from "./DeviceTable/DeviceTableFooter";

export interface IDeviceTableProps {
  pagination?: IPaginationView;
  isLoading?: boolean;
  rows: IDevice[];
  totalItems?: number;
  onChangePage?: (page: string) => void;
  handleDialogOpen: (
    deviceInfo: ITableDeleteCell
  ) => (_: React.MouseEvent) => void;
}

export const DeviceTable: React.FunctionComponent<IDeviceTableProps> = (
  props: IDeviceTableProps
) => {
  return (
    <StyledTable>
      <DeviceTableHeader />
      {!props.isLoading && (
        <React.Fragment>
          <TableBody>
            {props.rows.map(row => (
              <DeviceTableRow
                key={row.id}
                device={row}
                handleDialogOpen={props.handleDialogOpen}
              />
            ))}
          </TableBody>
          <DeviceTableFooter {...props} />
        </React.Fragment>
      )}
    </StyledTable>
  );
};
