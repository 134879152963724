import {
  IDeviceModel,
  IDeviceModelApi,
  IDeviceModelCollection,
  IDeviceModelCollectionApi,
  initialDeviceModel,
  initialDeviceModelCollection
} from "../models/IDeviceModel";
import { ServiceUtilities } from "../../core/utilities/ServiceUtilities";
import {
  ISubscriptionCollectionApi,
  initialSubscription
} from "../../Subscriptions/models/ISubscription";
import {
  IParameters,
  ITemplate,
  IMobileTableRow
} from "../../models/shared/IShared";
import { subscriptionFromApi } from "../../Subscriptions/services/SubscriptionService";

export const deviceModelFromApi = (
  deviceModel: IDeviceModelApi,
  initializedDeviceModel?: IDeviceModel
): IDeviceModel => {
  const result = initializedDeviceModel
    ? initializedDeviceModel
    : { ...initialDeviceModel };

  result.description = deviceModel.description || "";
  result.id = deviceModel["@id"];
  result.isLoading = false;
  result.name = deviceModel.name;
  result.owner = deviceModel.owner;
  result.shortId = ServiceUtilities.getShortId(deviceModel["@id"]);
  result.subscription = {
    ...initialSubscription,
    id: deviceModel.subscription,
    shortId: ServiceUtilities.getShortId(deviceModel.subscription)
  };
  result.type = deviceModel["@type"];

  if (initializedDeviceModel) {
    result.subscriptionList = initializedDeviceModel.subscriptionList;
    const subscription = initializedDeviceModel.subscriptionList
      .filter(subscription => subscription.id === deviceModel.subscription)
      .pop();

    if (subscription) {
      result.subscription = subscription;
    }
  }

  result.template = deviceModel.template;
  result.payloadFormats = result.template.payloadFormats || [];
  result.downlinkFormats = result.template.downlinkFormats || [];
  result.payloadFieldProperties = [];
  result.basicCalculationProperties = [];
  result.valueParameters = [];

  if (result.template.parameters && result.template.parameters.length > 0) {
    result.template.parameters.forEach((property: IParameters) => {
      if (property.method.kind === "PayloadField") {
        result.payloadFieldProperties = [
          ...result.payloadFieldProperties,
          property
        ];
      } else if (property.method.kind === "BasicCalculation") {
        result.basicCalculationProperties = [
          ...result.basicCalculationProperties,
          property
        ];
      } else if (property.method.kind === "Value") {
        result.valueParameters = [...result.valueParameters, property];
      }
    });
  }

  return result;
};

export const deviceModelToApi = (
  deviceModel: IDeviceModel
): IDeviceModelApi => {
  let newParameters: IParameters[] = [];
  newParameters = [
    ...deviceModel.payloadFieldProperties,
    ...deviceModel.basicCalculationProperties,
    ...deviceModel.valueParameters
  ];

  let newTemplate: ITemplate = {};

  if (newParameters.length !== 0) {
    newTemplate.parameters = newParameters;
  }

  if (deviceModel.payloadFormats.length !== 0) {
    newTemplate.payloadFormats = deviceModel.payloadFormats;
  }

  if (deviceModel.downlinkFormats.length !== 0) {
    newTemplate.downlinkFormats = deviceModel.downlinkFormats;
  }

  return {
    "@id": deviceModel.id,
    "@type": deviceModel.type,
    name: deviceModel.name,
    owner: deviceModel.owner,
    description: deviceModel.description,
    subscription: deviceModel.subscription.id,
    networks: [],
    template: newTemplate
  };
};

export const deviceModelCollectionFromApi = (
  collection: IDeviceModelCollectionApi
): IDeviceModelCollection => ({
  ...initialDeviceModelCollection,
  context: collection["@context"],
  id: collection["@id"],
  isLoading: false,
  members: collection.member.map(deviceModel =>
    deviceModelFromApi(deviceModel)
  ),
  shortId: ServiceUtilities.getShortId(collection["@id"]),
  totalItems: collection.totalItems,
  type: collection["@type"],
  view: collection.view
});

export const addDeviceModelSubscription = (
  model: IDeviceModel,
  subscriptions: ISubscriptionCollectionApi
): IDeviceModel => {
  const filteredSubscription = subscriptions.member
    .filter(subs => subs["@id"] === model.subscription.id)
    .pop();
  return {
    ...model,
    subscription: filteredSubscription
      ? subscriptionFromApi(filteredSubscription)
      : model.subscription
  };
};

export const addDeviceModelCollectionSubscription = (
  collection: IDeviceModelCollection,
  subscriptions: ISubscriptionCollectionApi
) => {
  return {
    ...collection,
    members: collection.members.map(model =>
      addDeviceModelSubscription(model, subscriptions)
    )
  };
};

export const deviceModelsToMobileTableRow = (
  members: IDeviceModel[]
): IMobileTableRow[] => {
  const result = members.map(item => {
    return {
      id: item.id,
      title: item.name,
      fields: [{ label: "Subscription", value: item.subscription.name }],
      editPageLink: `/deviceModels/${ServiceUtilities.getShortId(item.id)}`
    };
  });

  return result;
};
