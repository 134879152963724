import React from "react";
import { TableBody } from "@material-ui/core";

import { IPolicy } from "../models/IPolicy";
import { StyledTable } from "../../components/sharedStyledComponents";
import { IPaginationView, ITableDeleteCell } from "../../models/shared/IShared";
import { PolicyTableFooter } from "./PolicyTable/PolicyTableFooter";
import { PolicyTableHeader } from "./PolicyTable/PolicyTableHeader";
import { PolicyTableRow } from "./PolicyTable/PolicyTableRow";

export interface IPoliciesTableProps {
  pagination?: IPaginationView;
  isLoading?: boolean;
  rows: IPolicy[];
  totalItems?: number;
  onChangePage?: (page: string) => void;
  handleDialogOpen: (
    policyInfo: ITableDeleteCell
  ) => (_: React.MouseEvent) => void;
}

export const PoliciesTable: React.FunctionComponent<
  IPoliciesTableProps
> = props => {
  return (
    <StyledTable>
      <PolicyTableHeader />
      {!props.isLoading && (
        <React.Fragment>
          <TableBody>
            {props.rows.map(row => (
              <PolicyTableRow
                key={row.id}
                policy={row}
                handleDialogOpen={props.handleDialogOpen}
              />
            ))}
          </TableBody>
          <PolicyTableFooter {...props} />
        </React.Fragment>
      )}
    </StyledTable>
  );
};
