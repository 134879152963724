import { initialUserCollection, IUserCollection } from "../models/IUser";
import { ActionType } from "../../models/Action.model";

export type userCollectionReducerTypes =
  | { type: ActionType.GET_USER_COLLECTION; payload: IUserCollection }
  | { type: ActionType.CHANGE_USER_COLLECTION_PAGE; payload: IUserCollection }
  | { type: ActionType.USER_COLLECTION_LOADING; payload: boolean };

export function userCollectionReducer(
  state: IUserCollection = { ...initialUserCollection },
  action: userCollectionReducerTypes
): IUserCollection {
  switch (action.type) {
    case ActionType.GET_USER_COLLECTION:
      state = {
        ...action.payload,
        page: 0
      };
      break;
    case ActionType.CHANGE_USER_COLLECTION_PAGE:
      state = {
        ...action.payload,
        page: action.payload.page - 1
      };
      break;
    case ActionType.USER_COLLECTION_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
  }
  return state;
}
