import React from "react";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { indigo, grey, red } from "@material-ui/core/colors";

export const theme = createMuiTheme({
  palette: {
    contrastThreshold: 3,
    error: red,
    primary: indigo,
    secondary: {
      main: "#89849b",
      light: grey["400"],
      dark: grey["900"],
      contrastText: grey["50"]
    },
    tonalOffset: 0.2
  }
});

export function withThemeProvider<P>(Component: React.ComponentType<P>) {
  function WithTheme(props: P) {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithTheme;
}
