import axios, { AxiosResponse } from "axios";

import { ActionType } from "../../models/Action.model";
import {
  IDevice,
  IDeviceApi,
  initialDevice,
  IDeviceCalibrationTable,
  IDeviceCalibration,
  IDeviceCalibrationApi,
  IDeviceCalibrationHistoryTable
} from "../models/IDevice";
import { ITableDeleteCell } from "../../models/shared/IShared";
import {
  Configuration,
  ediApiHeaders,
  esmaApiHeaders,
  devicesPrefixURL,
  subscriptionsPrefixURL,
  ecaApiHeaders,
  edsaApiHeaders
} from "../../config";
import { Thunk } from "../../store";
import { deviceReducerTypes } from "../reducers/deviceReducer";
import { createBlockableDispatch } from "../../core/utilities/ServiceUtilities";
import {
  deviceFromApi,
  deviceToApi,
  calibrationProcesstoGetNewFormula,
  deviceCalibrationHistoryCollectionFromApi,
  deviceCalibrationToApi,
  deviceCalibrationFromApi,
  sortDeviceCalibrationHistoryByDate,
  updateDeviceCalibrationValueToApi
} from "../services/DeviceService";
import {
  deviceModelCollectionFromApi,
  deviceModelFromApi
} from "../../DeviceModels/services/DeviceModelsService";
import {
  subscriptionCollectionFromApi,
  subscriptionFromApi
} from "../../Subscriptions/services/SubscriptionService";
import { checkError, SnackbarError } from "../../core/utilities/AlertUtilities";
import { getDeviceCollection } from "./deviceCollectionAction";
import {
  initialSubscription,
  ISubscription
} from "../../Subscriptions/models/ISubscription";
import { initialDeviceModel } from "../../DeviceModels/models/IDeviceModel";
import { deviceCalibrationReducerTypes } from "../reducers/deviceCalibrationReducer";
import { deviceCalibrationHistoryReducerTypes } from "../reducers/deviceCalibrationHistoryReducer";

export const getDeviceById: Thunk<deviceReducerTypes> = (
  id: string,
  initializedDevice?: IDevice
) => {
  return async (dispatch, _, opt): Promise<IDevice | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.DEVICE_LOADING,
      payload: true
    });
    try {
      const deviceResponse = await axios.get(
        Configuration.EdiAPIBaseUrl + devicesPrefixURL + id,
        ediApiHeaders
      );

      const device = deviceFromApi(deviceResponse.data, initializedDevice);

      try {
        const deviceModelResponse = await axios.get(
          Configuration.EdiAPIBaseUrl + device.deviceModel.id,
          ediApiHeaders
        );

        const model = deviceModelFromApi(deviceModelResponse.data);

        const getDeviceModelBySubResult = await axios.get(
          Configuration.EdiAPIUrl + "/devicemodels",
          {
            ...ediApiHeaders,
            params: {
              subscription: model.subscription.id
            }
          }
        );

        device.deviceModelList = deviceModelCollectionFromApi(
          getDeviceModelBySubResult.data
        ).members;
      } catch (err) {}

      const deviceModel =
        device.deviceModelList
          .filter(item => item.id === device.deviceModel.id)
          .pop() || device.deviceModel;

      blockableDispatch({
        type: ActionType.GET_DEVICE,
        payload: {
          ...device,
          deviceModel: {
            ...deviceModel,
            name: deviceModel.name || "<INACCESSIBLE>"
          },
          manufacturer:
            device.manufacturersList
              .filter(item => item.id === deviceModel.subscription.id)
              .pop() || initialSubscription
        }
      });

      return device;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.DEVICE_LOADING,
        payload: false
      });
    }
  };
};

export const initializeDeviceEdit: Thunk<deviceReducerTypes> = (
  setLoadingState: boolean,
  subId: string
) => {
  return async (dispatch, _, opt): Promise<IDevice | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.CLEAR_DEVICE
    });
    dispatch({
      type: ActionType.DEVICE_LOADING,
      payload: true
    });
    try {
      const device = { ...initialDevice };

      /* fetch the active subscription                                     */
      const response_active = await axios.all([
        axios.get(
          Configuration.EsmaAPIBaseUrl + subscriptionsPrefixURL + subId,
          esmaApiHeaders
        )
      ]);
      const activeSubscription = subscriptionFromApi(response_active[0].data);

      /* fetch all subscription                                           */
      var subPageId = '/v1/esma/subscriptions';

      device.subscriptionList = [];
      while(true){
        const response = await axios.all([
          axios.get(Configuration.EramaAPIBaseUrl + subPageId, esmaApiHeaders)
        ]);
        let subscriptions = subscriptionCollectionFromApi(response[0].data).members; 
        subscriptions.forEach(
          (item:ISubscription)=>{
            device.subscriptionList.push({...item})
          }
        )    
        let currPageId = (response[0].data).view['@id']; 
        let lastPageId = (response[0].data).view['last']; 
        let nextPageId = (response[0].data).view['next'];         
        if( currPageId ===  lastPageId ){
          break;
        }
        else{
          subPageId = nextPageId;
        }

      }


      /*
      const response = await axios.all([
        axios.get(
          Configuration.EsmaAPIBaseUrl + subscriptionsPrefixURL + subId,
          esmaApiHeaders
        ),
        axios.get(Configuration.EsmaAPIUrl + "/subscriptions", esmaApiHeaders)
      ]);
      let subscriptionList = subscriptionCollectionFromApi(response[1].data)
        .members;
      const activeSubscription = subscriptionFromApi(response[0].data);
      */
      if (
        !device.subscriptionList.filter(sub => sub.id === activeSubscription.id).pop()
      ) {
        device.subscriptionList = [...device.subscriptionList, activeSubscription];
      }
      
      const ellenexSharedModelsSub = {
        ...initialSubscription,
        id: subscriptionsPrefixURL + "000b34e1-b78c-444b-b2c4-b27e3200cafe",
        name: "Ellenex"
      };

      device.manufacturersList = [
        { ...activeSubscription, name: "My models" },
        ellenexSharedModelsSub
      ];
      //device.subscriptionList = subscriptionList;
      if (setLoadingState) {
        device.subscription = activeSubscription;
      }
      blockableDispatch({
        type: ActionType.INITIALIZE_DEVICE_EDIT,
        payload: device
      });

      return device;
    } catch (err) {
      return checkError(err);
    } finally {
      if (setLoadingState) {
        dispatch({
          type: ActionType.DEVICE_LOADING,
          payload: false
        });
      }
    }
  };
};

export const getDeviceModelsBySub: Thunk<deviceReducerTypes> = (
  sub: ISubscription,
  deviceFormValue: IDevice
) => {
  return async (dispatch, _, opt): Promise<IDevice | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    const device = {
      ...deviceFormValue,
      manufacturer: sub,
      deviceModel: initialDeviceModel,
      deviceModelListIsLoading: true
    };

    dispatch({
      type: ActionType.DEVICEMODEL_LIST_LOADING_FOR_DEVICE,
      payload: device
    });

    try {
      const response = await axios.get(
        Configuration.EdiAPIUrl + "/devicemodels",
        {
          ...ediApiHeaders,
          params: {
            subscription: sub.id
          }
        }
      );

      device.deviceModelList = deviceModelCollectionFromApi(
        response.data
      ).members;

      blockableDispatch({
        type: ActionType.GET_DEVICEMODEL_BY_SUB_FOR_DEVICE,
        payload: device
      });

      return device;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.DEVICEMODEL_LIST_LOADING_FOR_DEVICE,
        payload: {
          ...device,
          deviceModelListIsLoading: false
        }
      });
    }
  };
};

export const updateDeviceById: Thunk<deviceReducerTypes> = (
  id: string,
  device: IDevice
) => {
  return async (dispatch): Promise<IDevice | SnackbarError> => {
    dispatch({
      type: ActionType.SET_DEVICE_FORM_VALUE,
      payload: device
    });
    dispatch({
      type: ActionType.DEVICE_LOADING,
      payload: true
    });
    try {
      const response = await axios.put(
        Configuration.EdiAPIBaseUrl + id,
        deviceToApi(device),
        ediApiHeaders
      );

      const updatedDevice = deviceFromApi(response.data, device);
      dispatch({
        type: ActionType.UPDATE_DEVICE,
        payload: updatedDevice
      });
      return updatedDevice;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.DEVICE_LOADING,
        payload: false
      });
    }
  };
};

export const createDevice: Thunk<deviceReducerTypes> = (device: IDevice) => {
  return async (dispatch): Promise<IDevice | SnackbarError> => {
    dispatch({
      type: ActionType.SET_DEVICE_FORM_VALUE,
      payload: device
    });
    dispatch({
      type: ActionType.DEVICE_LOADING,
      payload: true
    });
    try {
      const response: AxiosResponse<IDeviceApi> = await axios.post(
        Configuration.EdiAPIUrl + "/devices",
        deviceToApi(device),
        ediApiHeaders
      );
      dispatch({
        type: ActionType.CREATE_DEVICE,
        payload: deviceFromApi(response.data, device)
      });
      return deviceFromApi(response.data);
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.DEVICE_LOADING,
        payload: false
      });
    }
  };
};

export const deleteDevice: Thunk<deviceReducerTypes> = (
  deviceInfo: ITableDeleteCell
) => {
  return async (dispatch): Promise<number | SnackbarError> => {
    dispatch({
      type: ActionType.DEVICE_LOADING,
      payload: true
    });
    try {
      const response = await axios.delete(
        Configuration.EdiAPIBaseUrl + deviceInfo.id,
        ediApiHeaders
      );

      dispatch({
        type: ActionType.DELETE_DEVICE_DIALOG,
        payload: { ...deviceInfo, visible: false }
      });

      dispatch(getDeviceCollection());

      return response.status;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.DEVICE_LOADING,
        payload: false
      });
    }
  };
};

export const clearDevice: Thunk<deviceReducerTypes> = () => {
  return async dispatch => {
    dispatch({ type: ActionType.CLEAR_DEVICE });
  };
};

export const deviceDeleteConfirmDialog: Thunk<deviceReducerTypes> = (
  deviceInfo: ITableDeleteCell
) => {
  return async dispatch => {
    dispatch({
      type: ActionType.DELETE_DEVICE_DIALOG,
      payload: deviceInfo
    });
  };
};

export const initializeCalibration: Thunk<deviceCalibrationReducerTypes> = (
  id: string
) => {
  return async (dispatch, _, opt): Promise<IDevice | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );

    dispatch({
      type: ActionType.CALIBRATION_LOADING,
      payload: true
    });
    try {
      const response = await axios.get(
        Configuration.EdiAPIBaseUrl + devicesPrefixURL + id,
        ediApiHeaders
      );

      const device = deviceFromApi(response.data);

      blockableDispatch({
        type: ActionType.INITIALIZE_CALIBRATION,
        payload: {
          ...device
        }
      });

      return device;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.CALIBRATION_LOADING,
        payload: false
      });
    }
  };
};

export const getDeviceCalibrationHistory: Thunk<
  deviceCalibrationReducerTypes | deviceCalibrationHistoryReducerTypes
> = (
  deviceId: string,
  parameter: string,
  calibrationTable: IDeviceCalibrationTable
) => {
  return async (
    dispatch,
    getState,
    opt
  ): Promise<IDeviceCalibrationHistoryTable[] | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );

    dispatch({
      type: ActionType.SET_DEVICE_CALIBRATION_TABLE_FORM_VALUE,
      payload: calibrationTable
    });

    dispatch({
      type: ActionType.CALIBRATION_HISTORY_LOADING,
      payload: true
    });
    try {
      const deviceCalibrationHistoryResponse = await axios.get(
        Configuration.EcaAPIUrl + "/calibrations",
        {
          ...ecaApiHeaders,
          params: {
            device: devicesPrefixURL + deviceId,
            parameter: parameter
          }
        }
      );

      const calibrationHistory = sortDeviceCalibrationHistoryByDate(
        deviceCalibrationHistoryCollectionFromApi(
          deviceCalibrationHistoryResponse.data
        )
      );

      blockableDispatch({
        type: ActionType.GET_INITIAL_CALIBRATION_HISTORY,
        payload: calibrationHistory
      });

      return calibrationHistory;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.CALIBRATION_HISTORY_LOADING,
        payload: false
      });
    }
  };
};

export const getDeviceCalibrationNewFormula: Thunk<
  deviceCalibrationReducerTypes
> = (calibrationTable: IDeviceCalibrationTable) => {
  return async (
    dispatch,
    _,
    opt
  ): Promise<IDeviceCalibrationTable | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.SET_DEVICE_CALIBRATION_TABLE_FORM_VALUE,
      payload: calibrationTable
    });
    dispatch({
      type: ActionType.CALIBRATION_NEW_FORMULA_LOADING,
      payload: true
    });
    try {
      const deviceCalibrationNewFormulaResponse = await axios.post(
        Configuration.EcaAPIUrl + "/calibrations/process",
        calibrationProcesstoGetNewFormula(calibrationTable),
        ecaApiHeaders
      );

      blockableDispatch({
        type: ActionType.GET_DEVICE_CALIBRATION_NEW_FORMULA,
        payload: deviceCalibrationNewFormulaResponse.data
      });
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.CALIBRATION_NEW_FORMULA_LOADING,
        payload: false
      });
    }
  };
};

export const createDeviceCalibration: Thunk<
  deviceCalibrationReducerTypes | deviceCalibrationHistoryReducerTypes
> = (calibration: IDeviceCalibration) => {
  return async (
    dispatch,
    getState
  ): Promise<IDeviceCalibrationTable | SnackbarError> => {
    dispatch({
      type: ActionType.SET_DEVICE_CALIBRATION_TABLE_FORM_VALUE,
      payload: calibration.currentCalibrationTable
    });
    dispatch({
      type: ActionType.CALIBRATION_LOADING,
      payload: true
    });
    try {
      const {
        data: calibrationData
      }: AxiosResponse<IDeviceCalibrationApi> = await axios.post(
        Configuration.EcaAPIUrl + "/calibrations",
        deviceCalibrationToApi(calibration),
        ecaApiHeaders
      );

      const createdCalibration = deviceCalibrationFromApi(calibrationData);

      dispatch({
        type: ActionType.GET_CREATE_CALIBRATION,
        payload: createdCalibration
      });

      const {
        deviceCalibrationHistory: { calibrationHistory }
      } = getState();
      const historyData = calibrationHistory;

      dispatch({
        type: ActionType.CLEAR_CALIBRATION_HISTORY
      });

      dispatch({
        type: ActionType.ADD_CREATED_CALIBRATION_TO_HISTORY,
        payload: {
          id: createdCalibration.id,
          date: createdCalibration.date,
          historyData
        }
      });

      return createdCalibration;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.CALIBRATION_LOADING,
        payload: false
      });
    }
  };
};

export const getDeviceCalibrationById: Thunk<
  deviceCalibrationHistoryReducerTypes
> = (calibrationId: string) => {
  return async (dispatch, _, opt): Promise<void | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );

    dispatch({
      type: ActionType.CALIBRATION_HISTORY_DETAILS_LOADING,
      payload: { historyId: calibrationId, state: true }
    });

    try {
      const { data: calibrationData } = await axios.get(
        Configuration.EcaAPIBaseUrl + calibrationId,
        ecaApiHeaders
      );

      blockableDispatch({
        type: ActionType.GET_CALIBRATION_HISTORY,
        payload: { historyId: calibrationId, calibrationData }
      });
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.CALIBRATION_HISTORY_DETAILS_LOADING,
        payload: { historyId: calibrationId, state: false }
      });
    }
  };
};

export const clearDeviceCalibration: Thunk<
  deviceCalibrationReducerTypes
> = () => {
  return async dispatch => {
    dispatch({ type: ActionType.CLEAR_CALIBRATION });
  };
};

export const clearDeviceCalibrationCurrentTable: Thunk<
  deviceCalibrationReducerTypes
> = () => {
  return async dispatch => {
    dispatch({ type: ActionType.CLEAR_CALIBRATION_CURRENT_TABLE });
  };
};

export const clearDeviceCalibrationHistory: Thunk<
  deviceCalibrationHistoryReducerTypes
> = () => {
  return async dispatch => {
    dispatch({ type: ActionType.CLEAR_CALIBRATION_HISTORY });
  };
};

export const updateDeviceCalibrationValue: Thunk<
  deviceCalibrationReducerTypes
> = (rowIndex: number, calibration: IDeviceCalibration) => {
  return async (dispatch): Promise<number | SnackbarError> => {
    dispatch({
      type: ActionType.SET_DEVICE_CALIBRATION_TABLE_FORM_VALUE,
      payload: calibration.currentCalibrationTable
    });
    dispatch({
      type: ActionType.UPDATE_DEVICE_CALIBRATION_VALUE_LOADING,
      payload: true
    });
    try {
      const {
        data: { values }
      } = await axios.post(
        Configuration.EdsaAPIUrl + "/observations/eql",
        updateDeviceCalibrationValueToApi(calibration.currentCalibrationTable),
        edsaApiHeaders
      );

      const deviceValue = values.length > 0 ? values.pop().pop() : "";

      dispatch({
        type: ActionType.GET_DEVICE_CALIBRATION_VALUE,
        payload: {
          index: rowIndex,
          value: deviceValue
        }
      });

      return deviceValue;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.UPDATE_DEVICE_CALIBRATION_VALUE_LOADING,
        payload: false
      });
    }
  };
};
