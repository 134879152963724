import axios from "axios";

import { ActionType } from "../../models/Action.model";
import { IRoleCollection } from "../models/IRole";
import {
  Configuration,
  eramaApiHeaders,
  esmaApiHeaders,
  subscriptionsPrefixURL
} from "../../config";
import { Thunk } from "../../store";
import { roleCollectionReducerTypes } from "../reducers/roleCollectionReducer";
import {
  createBlockableDispatch,
  ServiceUtilities
} from "../../core/utilities/ServiceUtilities";
import {
  addRoleCollectionSubscription,
  roleCollectionFromApi
} from "../services/RoleService";
import { checkError, SnackbarError } from "../../core/utilities/AlertUtilities";
import {
  getSubscriptionCollectionObject,
  getSubscriptionObject
} from "../../UsersBySubscription/services/UsersBySubscriptionService";
import {
  subscriptionCollectionFromApi,
  subscriptionFromApi
} from "../../Subscriptions/services/SubscriptionService";
import { ISubscriptionSelection } from "../../models/shared/IShared";
import { initialSubscription } from "../../Subscriptions/models/ISubscription";

export const getRoleCollection: Thunk<roleCollectionReducerTypes> = (
  subId: string,
  masterAdmin: boolean
) => {
  return async (dispatch, _, opt): Promise<IRoleCollection | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.ROLE_COLLECTION_LOADING,
      payload: true
    });
    try {
      let response;
      if (masterAdmin) {
        response = await axios.all([
          axios.get(Configuration.EramaAPIUrl + "/roles", {
            ...eramaApiHeaders,
            params: {
              subscription:
                subscriptionsPrefixURL + ServiceUtilities.getShortId(subId)
            }
          }),
          axios.get(Configuration.EsmaAPIUrl + "/subscriptions", esmaApiHeaders)
        ]);
      } else {
        response = await axios.all([
          axios.get(Configuration.EramaAPIUrl + "/roles", eramaApiHeaders),
          axios.get(Configuration.EsmaAPIUrl + "/subscriptions", esmaApiHeaders)
        ]);
      }

      let roles = {
        ...addRoleCollectionSubscription(
          roleCollectionFromApi(response[0].data),
          response[1].data
        )
      };

      let subCollection = getSubscriptionCollectionObject(
        subscriptionCollectionFromApi(response[1].data)
      );

      for (let roleIndex in roles.members) {
        if (
          roles.members.hasOwnProperty(roleIndex) &&
          roles.members[roleIndex].subscription.name === ""
        ) {
          const subId = roles.members[roleIndex].subscription.id;
          const SubExisted = subCollection
            .filter(sub => sub.value === subId)
            .pop();

          if (SubExisted) {
            roles.members[roleIndex].subscription = {
              ...initialSubscription,
              id: SubExisted.value,
              name: SubExisted.label
            };
          } else {
            try {
              const responseSubById = await axios.get(
                Configuration.EsmaAPIBaseUrl +
                  roles.members[roleIndex].subscription.id,
                esmaApiHeaders
              );

              const fetchedSub = subscriptionFromApi(responseSubById.data);

              roles.members[roleIndex].subscription = {
                ...fetchedSub
              };

              subCollection = [
                ...subCollection,
                { value: fetchedSub.id, label: fetchedSub.name }
              ];
            } catch (err) {
              const { response } = err;
              if (response && response.status === 404) {
                continue;
              }
              return checkError(err);
            }
          }
        }
      }

      roles = {
        ...roles,
        subscriptionSelection: getSubscriptionObject(
          subscriptionsPrefixURL + ServiceUtilities.getShortId(subId),
          subCollection
        ),
        subscriptionSelectionList: subCollection
      };

      blockableDispatch({
        type: ActionType.GET_ROLE_COLLECTION,
        payload: roles
      });

      return roles;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.ROLE_COLLECTION_LOADING,
        payload: false
      });
    }
  };
};

export const changeRoleCollectionPage: Thunk<roleCollectionReducerTypes> = (
  page: string,
  subId: string,
  masterAdmin: boolean
) => {
  return async (dispatch, _, opt): Promise<IRoleCollection | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.ROLE_COLLECTION_LOADING,
      payload: true
    });
    try {
      let response;
      if (masterAdmin) {
        response = await axios.all([
          axios.get(Configuration.EramaAPIBaseUrl + page, {
            ...eramaApiHeaders,
            params: {
              subscription:
                subscriptionsPrefixURL + ServiceUtilities.getShortId(subId)
            }
          }),
          axios.get(Configuration.EsmaAPIUrl + "/subscriptions", esmaApiHeaders)
        ]);
      } else {
        response = await axios.all([
          axios.get(Configuration.EramaAPIBaseUrl + "/roles", {
            ...eramaApiHeaders
          }),
          axios.get(Configuration.EsmaAPIUrl + "/subscriptions", esmaApiHeaders)
        ]);
      }

      let roles = {
        ...addRoleCollectionSubscription(
          roleCollectionFromApi(response[0].data),
          response[1].data
        )
      };

      let subCollection = getSubscriptionCollectionObject(
        subscriptionCollectionFromApi(response[1].data)
      );

      for (let roleIndex in roles.members) {
        if (
          roles.members.hasOwnProperty(roleIndex) &&
          roles.members[roleIndex].subscription.name === ""
        ) {
          const subId = roles.members[roleIndex].subscription.id;
          const SubExisted = subCollection
            .filter(sub => sub.value === subId)
            .pop();

          if (SubExisted) {
            roles.members[roleIndex].subscription = {
              ...initialSubscription,
              id: SubExisted.value,
              name: SubExisted.label
            };
          } else {
            try {
              const responseSubById = await axios.get(
                Configuration.EsmaAPIBaseUrl +
                  roles.members[roleIndex].subscription.id,
                esmaApiHeaders
              );

              const fetchedSub = subscriptionFromApi(responseSubById.data);

              roles.members[roleIndex].subscription = {
                ...fetchedSub
              };

              subCollection = [
                ...subCollection,
                { value: fetchedSub.id, label: fetchedSub.name }
              ];
            } catch (err) {
              const { response } = err;
              if (response && response.status === 404) {
                continue;
              }
              return checkError(err);
            }
          }
        }
      }

      roles = {
        ...roles,
        subscriptionSelection: getSubscriptionObject(
          subscriptionsPrefixURL + ServiceUtilities.getShortId(subId),
          subCollection
        ),
        subscriptionSelectionList: subCollection
      };

      blockableDispatch({
        type: ActionType.GET_ROLE_COLLECTION,
        payload: roles
      });

      return roles;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.ROLE_COLLECTION_LOADING,
        payload: false
      });
    }
  };
};

export const setSubscriptionSelectionValue: Thunk<
  roleCollectionReducerTypes
> = (selectedValue: ISubscriptionSelection) => {
  return async dispatch => {
    dispatch({
      type: ActionType.SET_SUBSCRIPTION_SELECTION,
      payload: selectedValue
    });
  };
};
