import React from "react";
import { connect } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { Formik } from "formik";
import { FormikActions, FormikProps } from "formik/dist/types";
import { RouteComponentProps } from "react-router";
import { withSnackbar, WithSnackbarProps } from "notistack";

import { withThemeProvider } from "../util/withThemeProvider";
import { IRootState } from "../store";
import { IRole, IRoleCollection } from "./models/IRole";
import { CreateRoleForm } from "./components/CreateRoleForm";
import { EditRoleForm } from "./components/EditRoleForm";
import {
  clearRole,
  createRole,
  getRoleById,
  initializeRole,
  updateRoleById
} from "./actions/roleAction";
import {
  NoStyleLink,
  StyledTitle,
  StyledBackground,
  StyledFormWrapper,
  StyledListIcon
} from "../components/sharedStyledComponents";
import {
  alertMessages,
  SnackbarError,
  isSnackbarError,
  getStyledSnackbarOptions
} from "../core/utilities/AlertUtilities";
import { IGlobalState } from "../models/GlobalState";

interface IPathParamsType {
  id: string;
}

interface IEditRoleStateProps {
  roleCollection: IRoleCollection;
  role: IRole;
  global: IGlobalState;
}

interface IEditRoleDispatchProps {
  initializeRole: (
    setLoadingState: boolean,
    subId: string,
    masterAdmin: boolean
  ) => Promise<IRole | SnackbarError>;
  createRole: (role: IRole) => Promise<IRole | SnackbarError>;
  getRole: (
    id: string,
    initializedRole?: IRole
  ) => Promise<IRole | SnackbarError>;
  updateRole: (id: string, role: IRole) => Promise<IRole | SnackbarError>;
  clearRole: () => void;
}

type EditRoleType = IEditRoleStateProps &
  IEditRoleDispatchProps &
  RouteComponentProps<IPathParamsType> &
  WithSnackbarProps;

export class EditRole extends React.Component<EditRoleType> {
  public async componentDidMount() {
    const {
      initializeRole,
      getRole,
      enqueueSnackbar,
      match: {
        params: { id }
      },
      global: { masterAdmin },
      roleCollection: {
        subscriptionSelection: { value: subscriptionSelectionValue }
      },
      history
    } = this.props;

    if (subscriptionSelectionValue) {
      const result = await initializeRole(
        !Boolean(id),
        subscriptionSelectionValue,
        masterAdmin
      );

      if (isSnackbarError(result)) {
        enqueueSnackbar(result.message, result.options);
      } else if (id) {
        const getRoleResult = await getRole(id, result);

        if (isSnackbarError(getRoleResult)) {
          enqueueSnackbar(getRoleResult.message, getRoleResult.options);
        }
      }
    } else {
      history.push("/roles");
    }
  }

  public componentWillUnmount() {
    const {
      clearRole,
      match: {
        params: { id }
      }
    } = this.props;

    if (id) {
      clearRole();
    }
  }

  public renderCreateRoleForm = (formikProps: FormikProps<IRole>) => {
    const {
      roleCollection: { subscriptionSelection }
    } = this.props;

    return (
      <CreateRoleForm
        {...formikProps}
        subscriptionSelection={subscriptionSelection}
      />
    );
  };

  public onSubmit = async (
    values: IRole,
    { setSubmitting }: FormikActions<IRole>
  ) => {
    const {
      createRole,
      updateRole,
      history,
      enqueueSnackbar,
      match: {
        params: { id }
      }
    } = this.props;
    if (values) {
      if (id) {
        const updateResult = await updateRole(values.id, values);

        if (!isSnackbarError(updateResult) && updateResult !== undefined) {
          enqueueSnackbar(
            alertMessages.ROLE_UPDATE_SUCCESS,
            getStyledSnackbarOptions("success")
          );
        } else if (isSnackbarError(updateResult)) {
          enqueueSnackbar(updateResult.message, updateResult.options);
        }
      } else {
        const createResult = await createRole(values);

        if (!isSnackbarError(createResult) && createResult !== undefined) {
          enqueueSnackbar(
            alertMessages.ROLE_CREATE_SUCCESS,
            getStyledSnackbarOptions("success")
          );
          history.push("/roles/" + createResult.shortId);
        } else if (isSnackbarError(createResult)) {
          enqueueSnackbar(createResult.message, createResult.options);
        }
      }
      setSubmitting(false);
    }
  };

  public render() {
    const {
      role,
      match: {
        params: { id }
      }
    } = this.props;

    return (
      <React.Fragment>
        <StyledFormWrapper>
          <StyledTitle marginbottom="10px">
            {id ? (role.isLoading ? "Loading..." : role.name) : "New Role"}
          </StyledTitle>
          <StyledBackground>
            <Grid container={true} item={true} xs={12} justify="flex-end">
              <NoStyleLink to="/roles">
                <Button color="default">
                  <StyledListIcon />
                  List
                </Button>
              </NoStyleLink>
            </Grid>
            {id ? (
              <Formik
                enableReinitialize={true}
                initialValues={role}
                onSubmit={this.onSubmit}
                render={EditRoleForm}
              />
            ) : (
              <Formik
                enableReinitialize={true}
                initialValues={role}
                onSubmit={this.onSubmit}
                render={this.renderCreateRoleForm}
              />
            )}
          </StyledBackground>
        </StyledFormWrapper>

        <br />
        <br />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IEditRoleStateProps => ({
  roleCollection: state.roleCollection,
  role: state.role,
  global: state.globalState
});

const mapDispatchToProps = (dispatch: any): IEditRoleDispatchProps => {
  return {
    initializeRole: (
      setLoadingState: boolean,
      subId: string,
      masterAdmin: boolean
    ): Promise<IRole | SnackbarError> => {
      return dispatch(initializeRole(setLoadingState, subId, masterAdmin));
    },
    createRole: (Role: IRole): Promise<IRole | SnackbarError> => {
      return dispatch(createRole(Role));
    },
    getRole: (
      id: string,
      initializedRole?: IRole
    ): Promise<IRole | SnackbarError> => {
      return dispatch(getRoleById(id, initializedRole));
    },
    updateRole: (id: string, Role: IRole): Promise<IRole | SnackbarError> => {
      return dispatch(updateRoleById(id, Role));
    },
    clearRole: () => {
      dispatch(clearRole());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(EditRole)));
