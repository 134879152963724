import * as React from "react";

import { TableFooter, TableRow, TableCell } from "@material-ui/core";

import { ISubscriptionTableProps } from "../SubTable";
import { Pagination } from "../../../components/Pagination";

export const SubscriptionTableFooter: React.FunctionComponent<
  ISubscriptionTableProps
> = ({ onChangePage, pagination, totalItems }) => (
  <TableFooter>
    {onChangePage && (
      <TableRow>
        {pagination && (
          <TableCell>
            <Pagination
              totalItems={totalItems || 0}
              pagination={pagination}
              onChangePage={onChangePage}
            />
          </TableCell>
        )}
      </TableRow>
    )}
  </TableFooter>
);
