import React from "react";
import { FieldProps, getIn } from "formik";
import styled from "styled-components";
import Select from "react-select";
import { Props } from "react-select/base";
import { SortEnd } from "react-sortable-hoc";
import {
  FormHelperText,
  FormControl,
  Chip,
  MenuItem,
  TextField
} from "@material-ui/core";
import { Cancel as CancelIcon } from "@material-ui/icons";

import { Omit } from "../core/models/types";
import { theme } from "../util/withThemeProvider";
import { SortableList } from "./OrderedList";
import { changeOrder, checkOrder } from "../util/OrderingService";

export interface ISelectProps extends FieldProps, Omit<Props, "value"> {
  labelField: string;
  identityKey: string;
  clearValue: any;
  sortableKey: string;
}

export const fieldToAutocomplete = ({
  field,
  form: { isSubmitting, setFieldValue },
  isDisabled,
  ...props
}: ISelectProps): Props => {
  return {
    isDisabled: isSubmitting || isDisabled,
    ...field,
    onChange: (value, action) => {
      if (action.action === "clear") {
        setFieldValue(field.name, props.clearValue);
      } else {
        setFieldValue(
          field.name,
          props.sortableKey ? checkOrder(props.sortableKey, value) : value
        );
      }
    },
    ...props
  };
};

export const StyledPlaceholder = styled.div`
  position: absolute;
  left: 2px;
  font-size: 16px;
  color: rgb(0, 0, 0, 0.38);
`;
StyledPlaceholder.displayName = "StyledPlaceholder";
export const StyledFieldText = styled.div`
  && {
    display: flex;
    padding: 0;
    height: unset;
  }
`;
StyledFieldText.displayName = "StyledFieldText";
export const StyledValueContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  overflow: hidden;
`;
StyledValueContainer.displayName = "StyledValueContainer";
export const StyledChip = styled(Chip)`
  margin: ${theme.spacing() / 2}px ${theme.spacing() / 4}px;
`;
StyledChip.displayName = "StyledChip";

export const Placeholder = (props: any) => {
  return (
    <StyledPlaceholder {...props.innerProps}>
      {props.children}
    </StyledPlaceholder>
  );
};
export const SingleValue = (props: any) => {
  return (
    <StyledFieldText {...props.innerProps}>{props.children}</StyledFieldText>
  );
};
export const InputComponent = (props: any) => {
  return <StyledFieldText ref={props.inputRef} {...props} />;
};
export const ValueContainer = (props: any) => {
  return <StyledValueContainer>{props.children}</StyledValueContainer>;
};
export const Control = (props: any) => {
  return (
    <TextField
      fullWidth
      error={props.selectProps.id === "error"}
      disabled={props.selectProps.isDisabled}
      InputProps={{
        inputComponent: InputComponent,
        inputProps: {
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
};
export const EmptyValue = () => <React.Fragment />;
export const MultiValue = (props: any) => {
  return (
    <StyledChip
      tabIndex={-1}
      label={props.children}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
};
export const Option = (props: any) => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
};

export const AutocompleteSelect: React.ComponentType<ISelectProps> = (
  props: ISelectProps
) => {
  const {
    options,
    sortableKey,
    labelField,
    identityKey,
    onChange,
    field: { name, value },
    form: { errors, touched, setFieldValue }
  } = props;
  const errorList = getIn(errors, name);
  const touchedList = getIn(touched, name);
  const getLabel = (option: any) => {
    return option[labelField];
  };
  const error = touchedList && !!errorList;
  const getValue = (option: any) => {
    return option[identityKey];
  };
  const removeItem = (itemId: string) => {
    setFieldValue(name, value.filter((item: any) => item.id !== itemId));
  };
  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    setFieldValue(name, changeOrder(oldIndex, newIndex, sortableKey, value));
  };
  return (
    <React.Fragment>
      <Select
        controlShouldRenderValue={
          !Boolean(sortableKey) &&
          (Boolean(value[identityKey]) ||
            (Array.isArray(value) && value.length > 0))
        }
        id={error ? "error" : ""}
        options={options}
        components={{
          SingleValue,
          Option,
          MultiValue: sortableKey ? EmptyValue : MultiValue,
          Placeholder,
          Control,
          ValueContainer
        }}
        getOptionLabel={getLabel}
        getOptionValue={getValue}
        value={value}
        onChange={onChange}
        {...fieldToAutocomplete(props)}
      />
      {error && <FormHelperText error={true}>{errorList}</FormHelperText>}
      {sortableKey && (
        <SortableList
          onSortEnd={onSortEnd}
          lockAxis="y"
          lockToContainerEdges={true}
          lockOffset={["0%", "0%"]}
          useDragHandle={true}
          helperClass="dnd-helper"
          identityKey={identityKey}
          removeItem={removeItem}
          items={value}
          sortableKey={sortableKey}
          valueKey={labelField}
        />
      )}
    </React.Fragment>
  );
};

AutocompleteSelect.displayName = "FormikMaterialUIAutocompleteSelect";

export const StyledAutocomplete = styled(FormControl)`
  &&& {
    width: 100%;
    margin-bottom: 15px;
    text-align: left;
  }
`;
StyledAutocomplete.displayName = "StyledAutocomplete";
