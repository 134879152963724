import {
  ISubscription,
  ISubscriptionApi,
  ISubscriptionCollection,
  ISubscriptionCollectionApi,
  initialSubscription,
  initialSubscriptionCollection
} from "../models/ISubscription";
import { ServiceUtilities } from "../../core/utilities/ServiceUtilities";
import {
  IUserCollectionApi,
  initialUser,
  IUserApi
} from "../../Users/models/IUser";
import { userFromApi } from "../../Users/services/UserService";
import { IMobileTableRow } from "../../models/shared/IShared";

export const subscriptionFromApi = (
  subscription: ISubscriptionApi,
  initializedSubscription?: ISubscription
): ISubscription => {
  const result = initializedSubscription
    ? initializedSubscription
    : { ...initialSubscription };

  result.type = subscription["@type"];
  result.id = subscription["@id"];
  result.isLoading = false;
  result.shortId = ServiceUtilities.getShortId(subscription["@id"]);
  result.name = subscription.name;
  result.owner = {
    ...initialUser,
    id: subscription.owner
  };

  if (initializedSubscription) {
    result.userList = initializedSubscription.userList;
    const owner = initializedSubscription.userList
      .filter(user => user.id === subscription.owner)
      .pop();

    if (owner) {
      result.owner = owner;
    }
  }

  return result;
};

export const subscriptionToApi = (
  subscription: ISubscription
): ISubscriptionApi => {
  return {
    "@id": subscription.id,
    "@type": subscription.type,
    name: subscription.name,
    owner: subscription.owner.id
  };
};

export const subscriptionCollectionFromApi = (
  collection: ISubscriptionCollectionApi
): ISubscriptionCollection => ({
  ...initialSubscriptionCollection,
  context: collection["@context"],
  id: collection["@id"],
  isLoading: false,
  members: collection.member.map(sub => subscriptionFromApi(sub)),
  shortId: ServiceUtilities.getShortId(collection["@id"]),
  totalItems: collection.totalItems,
  type: collection["@type"],
  view: collection.view
});

export const addSubscriptionOwner = (
  subscription: ISubscription,
  owners: IUserCollectionApi
): ISubscription => {
  const filteredUser = owners.member
    .filter(user => user["@id"] === subscription.owner.id)
    .pop();
  return {
    ...subscription,
    owner: filteredUser ? userFromApi(filteredUser) : subscription.owner
  };
};

export const addSubscriptionCollectionOwner = (
  collection: ISubscriptionCollection,
  owners: IUserCollectionApi
): ISubscriptionCollection => {
  return {
    ...collection,
    members: collection.members.map(model =>
      addSubscriptionOwner(model, owners)
    )
  };
};

export const addSubscriptionSingleOwner = (
  subscription: ISubscription,
  owner: IUserApi
): ISubscription => {
  if (subscription.owner.id === owner["@id"]) {
    return {
      ...subscription,
      owner: userFromApi(owner)
    };
  } else {
    return subscription;
  }
};

export const addSubscriptionCollectionSingleOwner = (
  collection: ISubscriptionCollection,
  owner: IUserApi
): ISubscriptionCollection => {
  return {
    ...collection,
    members: collection.members.map(model =>
      addSubscriptionSingleOwner(model, owner)
    )
  };
};

export const subscriptionsToMobileTableRow = (
  members: ISubscription[]
): IMobileTableRow[] => {
  const result = members.map(item => {
    return {
      id: item.id,
      title: item.name,
      fields: [{ label: "Owner", value: item.owner.name }],
      editPageLink: `/subscriptions/${ServiceUtilities.getShortId(item.id)}`
    };
  });

  return result;
};
