import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions
} from "@material-ui/core";

import { ITableDeleteCell } from "../../models/shared/IShared";
import {
  StyledDialogTitle,
  StyledDialogLinearProgress,
  StyledDialogContentText
} from "../../components/sharedStyledComponents";

interface IProps {
  policyInfo: ITableDeleteCell;
  handleDeleteDialogClose: (policyInfo: ITableDeleteCell) => void;
  deletePolicy: (policyInfo: ITableDeleteCell) => void;
  isLoading: boolean;
}

export const PolicyDeleteDialog: React.FunctionComponent<IProps> = ({
  policyInfo,
  handleDeleteDialogClose,
  deletePolicy,
  isLoading
}: IProps) => {
  const DeleteDialogClose = () => {
    handleDeleteDialogClose(policyInfo);
  };

  const DeletePolicy = () => {
    deletePolicy(policyInfo);
  };

  return (
    <Dialog
      open={policyInfo.visible}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <StyledDialogTitle id="alert-dialog-title">
        Are you sure you want to delete this policy?
      </StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText id="alert-dialog-description">
          {policyInfo.title}
        </StyledDialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={DeleteDialogClose}
          color="primary"
          variant="contained"
          disabled={isLoading}
          autoFocus>
          No
        </Button>
        <Button onClick={DeletePolicy} color="primary" disabled={isLoading}>
          Yes
        </Button>
      </DialogActions>

      {isLoading && <StyledDialogLinearProgress />}
    </Dialog>
  );
};
