import axios, { AxiosError } from "axios";

import { ActionType } from "../../models/Action.model";
import { Configuration, esmaApiHeaders, eramaApiHeaders } from "../../config";
import { Thunk } from "../../store";
import { createBlockableDispatch } from "../../core/utilities/ServiceUtilities";
import { checkError, SnackbarError } from "../../core/utilities/AlertUtilities";
import { userBySubscriptionCollectionReducerTypes } from "../reducers/userBySubscriptionCollectionReducer";
import {
  IUserBySubscriptionCollection,
  IUserBySubscriptionCollectionApi,
  IUserBySubscription
} from "../models/IUserBySubscription";
import {
  userBySubscriptionCollectionFromApi,
  getRoleName,
  onlyUnique
} from "../services/UsersBySubscriptionService";
import { roleFromApi } from "../../Roles/services/RoleService";

export const getUserCollection: Thunk<
  userBySubscriptionCollectionReducerTypes
> = (subId: string) => {
  return async (dispatch, _, opt): Promise<undefined | SnackbarError[]> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.USER_BY_SUBSCRIPTION_COLLECTION_LOADING,
      payload: true
    });
    const error: (AxiosError | Error)[] = [];
    try {
      const response = await axios.get(
        Configuration.EsmaAPIBaseUrl + subId + "/members",
        esmaApiHeaders
      );

      const users: IUserBySubscriptionCollection = userBySubscriptionCollectionFromApi(
        response.data as IUserBySubscriptionCollectionApi
      );

      let usersWithRole: IUserBySubscription[] = [];

      try {
        const roleQueries = users.members
          .map(member => {
            return member.roles.map(role => role.id);
          })
          .reduce((flatten, arr) => [...flatten, ...arr])
          .filter(onlyUnique)
          .map(roleId =>
            axios
              .get(Configuration.EramaAPIBaseUrl + roleId, {
                ...eramaApiHeaders
              })
              .catch(err => {
                if (err.response.status !== 404) {
                  error.push(err);
                }
              })
          );

        let roleQueriesResult = await Promise.all(roleQueries);

        usersWithRole = getRoleName(
          users.members,
          roleQueriesResult
            .filter(role => role)
            .map(role => roleFromApi(role && role.data))
        );
      } catch (err) {
        return [checkError(err)];
      }

      blockableDispatch({
        type: ActionType.GET_USER_BY_SUBSCRIPTION_COLLECTION,
        payload: { ...users, members: usersWithRole }
      });
    } catch (err) {
      return [checkError(err)];
    } finally {
      dispatch({
        type: ActionType.USER_BY_SUBSCRIPTION_COLLECTION_LOADING,
        payload: false
      });
    }
  };
};

export const changeUserCollectionPage: Thunk<
  userBySubscriptionCollectionReducerTypes
> = (page: string) => {
  return async (dispatch, _, opt): Promise<undefined | SnackbarError[]> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.USER_BY_SUBSCRIPTION_COLLECTION_LOADING,
      payload: true
    });
    const error: (AxiosError | Error)[] = [];
    try {
      const response = await axios.get(Configuration.EsmaAPIBaseUrl + page, {
        ...esmaApiHeaders
      });

      const users: IUserBySubscriptionCollection = userBySubscriptionCollectionFromApi(
        response.data as IUserBySubscriptionCollectionApi
      );

      let usersWithRole: IUserBySubscription[] = [];

      try {
        const roleQueries = users.members
          .map(member => {
            return member.roles.map(role => role.id);
          })
          .reduce((flatten, arr) => [...flatten, ...arr])
          .filter(onlyUnique)
          .map(roleId =>
            axios
              .get(Configuration.EramaAPIBaseUrl + roleId, {
                ...eramaApiHeaders
              })
              .catch(err => {
                if (err.response.status !== 404) {
                  error.push(err);
                }
              })
          );

        let roleQueriesResult = await Promise.all(roleQueries);

        usersWithRole = getRoleName(
          users.members,
          roleQueriesResult
            .filter(role => role)
            .map(role => roleFromApi(role && role.data))
        );
      } catch (err) {
        return [checkError(err)];
      }

      blockableDispatch({
        type: ActionType.GET_USER_BY_SUBSCRIPTION_COLLECTION,
        payload: { ...users, members: usersWithRole }
      });
    } catch (err) {
      return [checkError(err)];
    } finally {
      dispatch({
        type: ActionType.USER_BY_SUBSCRIPTION_COLLECTION_LOADING,
        payload: false
      });
    }
  };
};

export const clearUserBySubscriptionCollection: Thunk<
  userBySubscriptionCollectionReducerTypes
> = () => {
  return async dispatch => {
    dispatch({ type: ActionType.CLEAR_USER_BY_SUBSCRIPTION_COLLECTION });
  };
};
