import * as React from "react";
import styled from "styled-components";
import {
  TablePagination,
  TableFooter,
  TableRow,
  TableCell
} from "@material-ui/core";

import { IDeviceModelTableProps } from "../DeviceModelTable";
import { Pagination } from "../../../components/Pagination";

export const TablePaginationLeft = styled(TablePagination)<any>`
  && {
    display: flex;
    align-items: start;
  }
`;
TablePaginationLeft.displayName = "TablePaginationLeft";

export const DeviceModelTableFooter: React.FunctionComponent<
  IDeviceModelTableProps
> = ({ onChangePage, pagination, totalItems }) => (
  <TableFooter>
    {onChangePage && (
      <TableRow>
        {pagination && (
          <TableCell>
            <Pagination
              totalItems={totalItems || 0}
              pagination={pagination}
              onChangePage={onChangePage}
            />
          </TableCell>
        )}
      </TableRow>
    )}
  </TableFooter>
);
