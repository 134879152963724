import { initialSubscription, ISubscription } from "../models/ISubscription";
import { ActionType } from "../../models/Action.model";

export type subscriptionReducerTypes =
  | { type: ActionType.INITIALIZE_SUBSCRIPTION_EDIT; payload: ISubscription }
  | { type: ActionType.GET_SUBSCRIPTION; payload: ISubscription }
  | { type: ActionType.CREATE_SUBSCRIPTION; payload: ISubscription }
  | { type: ActionType.UPDATE_SUBSCRIPTION; payload: ISubscription }
  | { type: ActionType.SUBSCRIPTION_LOADING; payload: boolean }
  | { type: ActionType.CLEAR_SUBSCRIPTION }
  | { type: ActionType.SET_SUBSCRIPTION_FORM_VALUE; payload: ISubscription };

export function subscriptionReducer(
  state: ISubscription = { ...initialSubscription },
  action: subscriptionReducerTypes
): ISubscription {
  switch (action.type) {
    case ActionType.GET_SUBSCRIPTION:
      state = {
        ...state,
        ...action.payload,
        userList: state.userList
      };
      break;
    case ActionType.CREATE_SUBSCRIPTION:
      state = {
        ...state,
        ...action.payload,
        userList: state.userList
      };
      break;
    case ActionType.UPDATE_SUBSCRIPTION:
      state = {
        ...state,
        ...action.payload,
        userList: state.userList
      };
      break;
    case ActionType.SUBSCRIPTION_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
    case ActionType.INITIALIZE_SUBSCRIPTION_EDIT: {
      state = {
        ...state,
        userList: action.payload.userList
      };
      break;
    }
    case ActionType.CLEAR_SUBSCRIPTION:
      state = { ...initialSubscription };
      break;
    case ActionType.SET_SUBSCRIPTION_FORM_VALUE:
      state = { ...action.payload };
      break;
  }
  return state;
}
