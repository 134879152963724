import React from "react";
import { TableFooter, TableRow, TableCell } from "@material-ui/core";

import { IRolesTableProps } from "../RolesTable";
import { Pagination } from "../../../components/Pagination";

export const RoleTableFooter: React.FunctionComponent<IRolesTableProps> = ({
  onChangePage,
  pagination,
  totalItems
}) => (
  <TableFooter>
    {onChangePage && (
      <TableRow>
        {pagination && (
          <TableCell>
            <Pagination
              totalItems={totalItems || 0}
              pagination={pagination}
              onChangePage={onChangePage}
            />
          </TableCell>
        )}
      </TableRow>
    )}
  </TableFooter>
);
