import React from "react";
import { TableBody } from "@material-ui/core";

import { IDeviceModel } from "../models/IDeviceModel";
import { ITableDeleteCell } from "../../models/shared/IShared";
import { StyledTable } from "../../components/sharedStyledComponents";
import { IPaginationView } from "../../models/shared/IShared";
import { DeviceModelTableHeader } from "./DeviceModelTable/DeviceModelTableHeader";
import { DeviceModelTableRow } from "./DeviceModelTable/DeviceModelTableRow";
import { DeviceModelTableFooter } from "./DeviceModelTable/DeviceModelTableFooter";

export interface IDeviceModelTableProps {
  pagination?: IPaginationView;
  isLoading?: boolean;
  rows: IDeviceModel[];
  totalItems?: number;
  onChangePage?: (page: string) => void;
  handleDialogOpen: (
    deviceInfo: ITableDeleteCell
  ) => (_: React.MouseEvent) => void;
}

export const DeviceModelTable: React.FunctionComponent<
  IDeviceModelTableProps
> = props => {
  return (
    <StyledTable>
      <DeviceModelTableHeader />
      {!props.isLoading && (
        <React.Fragment>
          <TableBody>
            {props.rows.map(row => (
              <DeviceModelTableRow
                key={row.id}
                deviceModel={row}
                handleDialogOpen={props.handleDialogOpen}
              />
            ))}
          </TableBody>
          <DeviceModelTableFooter {...props} />
        </React.Fragment>
      )}
    </StyledTable>
  );
};
