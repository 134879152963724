import React from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { Formik } from "formik";
import { FormikActions } from "formik/dist/types";
import { RouteComponentProps } from "react-router";
import { withSnackbar, WithSnackbarProps } from "notistack";

import { withThemeProvider } from "../util/withThemeProvider";
import { IRootState } from "../store";
import { IUserProfile } from "./models/IUserProfile";
import { EditUserProfileForm } from "./components/EditUserProfileForm";
import { getUserById, updateUserById } from "./actions/userProfileAction";
import {
  StyledBackground,
  StyledTitle,
  StyledFormWrapper
} from "../components/sharedStyledComponents";
import {
  alertMessages,
  SnackbarError,
  isSnackbarError,
  getStyledSnackbarOptions
} from "../core/utilities/AlertUtilities";

interface IPathParamsType {
  id: string;
}

interface IUserStateProps {
  profile: IUserProfile;
}

interface IUserDispatchProps {
  getUser: (id: string) => Promise<undefined | SnackbarError[]>;
  updateUser: (profile: IUserProfile) => Promise<undefined | SnackbarError[]>;
}

type EditUserProps = IUserStateProps &
  IUserDispatchProps &
  RouteComponentProps<IPathParamsType> &
  WithSnackbarProps;

export class EditUserProfile extends React.Component<EditUserProps> {
  public async componentDidMount() {
    const {
      getUser,
      enqueueSnackbar,
      match: {
        params: { id }
      }
    } = this.props;

    if (id) {
      const getUserResult = await getUser(id);

      if (getUserResult && getUserResult.length > 0) {
        getUserResult.forEach(getUserResult => {
          if (isSnackbarError(getUserResult)) {
            enqueueSnackbar(getUserResult.message, getUserResult.options);
          }
        });
      }
    }
  }

  public onSubmit = async (
    values: IUserProfile,
    { setSubmitting }: FormikActions<IUserProfile>
  ) => {
    const { updateUser, enqueueSnackbar } = this.props;
    if (values) {
      const updateResult = await updateUser(values);

      if (updateResult && updateResult.length > 0) {
        updateResult.forEach(updateResult => {
          if (isSnackbarError(updateResult)) {
            enqueueSnackbar(updateResult.message, updateResult.options);
          }
        });
      } else {
        enqueueSnackbar(
          alertMessages.USERPROFILE_UPDATE_SUCCESS,
          getStyledSnackbarOptions("success")
        );
      }

      setSubmitting(false);
    }
  };

  public render() {
    const { profile } = this.props;

    return (
      <React.Fragment>
        <StyledFormWrapper>
          <StyledTitle marginbottom="10px">User Profile</StyledTitle>
          <StyledBackground>
            <Grid item={true} xs={12} sm={8} md={6} lg={5} xl={5}>
              <Formik
                enableReinitialize={true}
                initialValues={profile}
                onSubmit={this.onSubmit}
                render={EditUserProfileForm}
              />
            </Grid>
          </StyledBackground>
        </StyledFormWrapper>

        <br />
        <br />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IUserStateProps => ({
  profile: state.userProfileReducer
});

const mapDispatchToProps = (dispatch: any): IUserDispatchProps => {
  return {
    getUser: (id: string): Promise<undefined | SnackbarError[]> => {
      return dispatch(getUserById(id));
    },
    updateUser: (
      profile: IUserProfile
    ): Promise<undefined | SnackbarError[]> => {
      return dispatch(updateUserById(profile));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(EditUserProfile)));
