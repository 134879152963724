import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions
} from "@material-ui/core";

import { ITableDeleteCell } from "../../models/shared/IShared";
import {
  StyledDialogTitle,
  StyledDialogLinearProgress,
  StyledDialogContentText
} from "../../components/sharedStyledComponents";

interface IProps {
  userBySubInfo: ITableDeleteCell;
  handleRemoveDialogClose: (userBySubInfo: ITableDeleteCell) => void;
  removeUserFromSubscription: (userBySubInfo: ITableDeleteCell) => void;
  isLoading: boolean;
}

export const UsersBySubRemoveDialog: React.FunctionComponent<IProps> = ({
  userBySubInfo: { visible, title },
  userBySubInfo,
  handleRemoveDialogClose,
  removeUserFromSubscription,
  isLoading
}: IProps) => {
  const HandleRemoveDialog = (removeState: boolean) => (
    _: React.MouseEvent
  ) => {
    removeState
      ? removeUserFromSubscription(userBySubInfo)
      : handleRemoveDialogClose(userBySubInfo);
  };

  return (
    <React.Fragment>
      <Dialog
        open={visible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <StyledDialogTitle id="alert-dialog-title">
          Are you sure you want to remove this user from current subscription?
        </StyledDialogTitle>
        <DialogContent>
          <StyledDialogContentText id="alert-dialog-description">
            {title}
          </StyledDialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={HandleRemoveDialog(false)}
            color="primary"
            variant="contained"
            disabled={isLoading}
            autoFocus>
            No
          </Button>
          <Button
            onClick={HandleRemoveDialog(true)}
            color="primary"
            disabled={isLoading}>
            Yes
          </Button>
        </DialogActions>

        {isLoading && <StyledDialogLinearProgress />}
      </Dialog>
    </React.Fragment>
  );
};
