import axios, { AxiosError } from "axios";

import { ActionType } from "../../models/Action.model";
import { IDeviceCollection, IDeviceCollectionApi } from "../models/IDevice";
import { Configuration, ediApiHeaders } from "../../config";
import { Thunk } from "../../store";
import { deviceCollectionReducerTypes } from "../reducers/deviceCollectionReducer";
import { createBlockableDispatch } from "../../core/utilities/ServiceUtilities";
import {
  deviceCollectionFromApi,
  getDeviceModelName
} from "../services/DeviceService";
import { checkError, SnackbarError } from "../../core/utilities/AlertUtilities";
import { deviceModelFromApi } from "../../DeviceModels/services/DeviceModelsService";

export const getDeviceCollection: Thunk<deviceCollectionReducerTypes> = () => {
  return async (
    dispatch,
    getState,
    opt
  ): Promise<undefined | SnackbarError[]> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.DEVICE_COLLECTION_LOADING,
      payload: true
    });
    const error: (AxiosError | Error)[] = [];
    try {
      const {
        deviceCollection: { searchBySerial }
      } = getState();

      const devicesResult = await axios.get(
        Configuration.EdiAPIUrl + "/devices",
        {
          ...ediApiHeaders,
          params: {
            serial: searchBySerial ? searchBySerial : undefined
          }
        }
      );

      const devices: IDeviceCollection = deviceCollectionFromApi(
        devicesResult.data as IDeviceCollectionApi
      );

      const deviceModelsQueries = devices.members.map(
        ({ deviceModel: { id } }) =>
          axios
            .get(Configuration.EdiAPIBaseUrl + id, {
              ...ediApiHeaders
            })
            .catch(err => {
              if (err.response.status !== 404) {
                error.push(err);
              }
            })
      );

      const deviceModels = await Promise.all(deviceModelsQueries);

      const models = deviceModels
        .filter(model => model)
        .map(model => model && deviceModelFromApi(model.data));

      devices.members = devices.members.map(device => ({
        ...device,
        deviceModel: models
          .filter(model => model[`id`] === device.deviceModel.id)
          .pop() || { ...device.deviceModel, name: "<INACCESSIBLE>" }
      }));

      blockableDispatch({
        type: ActionType.GET_DEVICE_COLLECTION,
        payload: devices
      });

      if (error.length > 0) {
        return error.map(error => checkError(error));
      }
    } catch (err) {
      return [checkError(err)];
    } finally {
      dispatch({
        type: ActionType.DEVICE_COLLECTION_LOADING,
        payload: false
      });
    }
  };
};

export const changeDeviceCollectionPage: Thunk<deviceCollectionReducerTypes> = (
  page: string
) => {
  return async (
    dispatch,
    getState,
    opt
  ): Promise<undefined | SnackbarError[]> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.DEVICE_COLLECTION_LOADING,
      payload: true
    });
    const error: (AxiosError | Error)[] = [];
    try {
      const {
        deviceCollection: { searchBySerial }
      } = getState();

      const devicesResult = await axios.get(
        Configuration.EdiAPIBaseUrl + page,
        {
          ...ediApiHeaders,
          params: {
            serial: searchBySerial ? searchBySerial : undefined
          }
        }
      );

      const devices: IDeviceCollection = deviceCollectionFromApi(
        devicesResult.data as IDeviceCollectionApi
      );

      const deviceModelsQueries = devices.members.map(
        ({ deviceModel: { id } }) =>
          axios
            .get(Configuration.EdiAPIBaseUrl + id, {
              ...ediApiHeaders
            })
            .catch(err => {
              if (err.response.status !== 404) {
                error.push(err);
              }
            })
      );

      const deviceModels = await Promise.all(deviceModelsQueries);

      blockableDispatch({
        type: ActionType.GET_DEVICE_COLLECTION,
        payload: {
          ...devices,
          members: getDeviceModelName(devices.members, deviceModels)
        }
      });

      if (error.length > 0) {
        return error.map(error => checkError(error));
      }
    } catch (err) {
      return [checkError(err)];
    } finally {
      dispatch({
        type: ActionType.DEVICE_COLLECTION_LOADING,
        payload: false
      });
    }
  };
};

export const deviceSearchBySerialSetValue: Thunk<
  deviceCollectionReducerTypes
> = (searchInput: string) => {
  return async dispatch => {
    dispatch({
      type: ActionType.DEVICE_SEARCH_BY_SERIAL_SET_VALUE,
      payload: searchInput
    });
  };
};
