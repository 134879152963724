import { IPolicy, initialPolicy } from "../models/IPolicy";
import { ActionType } from "../../models/Action.model";
import { ITableDeleteCell } from "../../models/shared/IShared";

export type policyReducerTypes =
  | { type: ActionType.INITIALIZE_POLICY_EDIT; payload: IPolicy }
  | { type: ActionType.GET_POLICY; payload: IPolicy }
  | { type: ActionType.CREATE_POLICY; payload: IPolicy }
  | { type: ActionType.UPDATE_POLICY; payload: IPolicy }
  | { type: ActionType.POLICY_LOADING; payload: boolean }
  | { type: ActionType.CLEAR_POLICY }
  | { type: ActionType.SET_POLICY_FORM_VALUE; payload: IPolicy }
  | { type: ActionType.DELETE_POLICY_DIALOG; payload: ITableDeleteCell };

export function policyReducer(
  state: IPolicy = { ...initialPolicy },
  action: policyReducerTypes
): IPolicy {
  switch (action.type) {
    case ActionType.GET_POLICY:
      state = {
        ...state,
        ...action.payload,
        subscriptionList: state.subscriptionList
      };
      break;
    case ActionType.CREATE_POLICY:
      state = {
        ...state,
        ...action.payload,
        subscriptionList: state.subscriptionList
      };
      break;
    case ActionType.UPDATE_POLICY:
      state = {
        ...state,
        ...action.payload,
        subscriptionList: state.subscriptionList
      };
      break;
    case ActionType.POLICY_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
    case ActionType.INITIALIZE_POLICY_EDIT:
      state = {
        ...state,
        subscriptionList: action.payload.subscriptionList
      };
      break;
    case ActionType.CLEAR_POLICY:
      state = { ...initialPolicy };
      break;
    case ActionType.SET_POLICY_FORM_VALUE:
      state = { ...action.payload };
      break;
    case ActionType.DELETE_POLICY_DIALOG:
      state = {
        ...state,
        policyDelete: action.payload
      };
      break;
  }
  return state;
}
