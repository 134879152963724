import { AxiosResponse } from "axios";
import axios from "axios";
import { store } from "../../store";
import { ActionType } from "../../models/Action.model";
import { Configuration } from "../../config";

sessionStorage.removeItem("cachedIndexHTML");

export default function versionWatcher() {
  setInterval(versionChecker, Configuration.clientVersionCheckIntervalMs);
}

export const versionChecker = async () => {
  const response: AxiosResponse<string> = await axios.get("/");
  const cachedIndexHTML = sessionStorage.getItem("cachedIndexHTML");
  if (cachedIndexHTML) {
    if (response.data !== cachedIndexHTML) {
      store.dispatch({ type: ActionType.REFRESH_REQUIRED });
    }
  } else {
    sessionStorage.setItem("cachedIndexHTML", response.data);
  }
};
