import React, { FunctionComponent } from "react";
import { TableCell, TableFooter, TableRow } from "@material-ui/core";

export interface ICalibrationTableFooter {
  currentFormula: string;
  newFormula: string;
}

export const CalibrationTableFooter: FunctionComponent<
  ICalibrationTableFooter
> = ({ currentFormula, newFormula }) => (
  <TableFooter>
    <TableRow>
      <TableCell colSpan={2}>Current Formula: {currentFormula}</TableCell>
    </TableRow>
    <TableRow>
      <TableCell colSpan={2}>New Formula: {newFormula}</TableCell>
    </TableRow>
  </TableFooter>
);
CalibrationTableFooter.displayName = "CalibrationTableFooter";
