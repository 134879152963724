import { IApiResponseHeader } from "../../models/IApiResponseHeader";
import { IDetermination, ILoadable } from "../../core/models/MixinInterfaces";
import { IUser, initialUser } from "../../Users/models/IUser";
import { ICollection } from "../../models/shared/IShared";

export interface ISubscriptionApi extends IApiResponseHeader {
  name: string;
  owner: string;
}

export interface ISubscriptionCollectionApi
  extends IApiResponseHeader,
    ICollection {
  "@context": string;
  member: ISubscriptionApi[];
  totalItems: number;
  page?: number;
}

export interface ISubscription extends ILoadable, IDetermination {
  name: string;
  owner: IUser;
  userList: IUser[];
}

export interface ISubscriptionCollection
  extends ILoadable,
    IDetermination,
    ICollection {
  context: string;
  members: ISubscription[];
  totalItems: number;
  page: number;
}

export const initialSubscription: ISubscription = {
  id: "",
  isLoading: false,
  name: "",
  owner: { ...initialUser },
  userList: [],
  shortId: "",
  type: ""
};

export const initialSubscriptionCollection: ISubscriptionCollection = {
  context: "",
  id: "",
  isLoading: false,
  members: [],
  page: 0,
  shortId: "",
  totalItems: 0,
  type: "",
  view: {
    "@id": "",
    "@type": "",
    first: "",
    last: "",
    next: "",
    previous: ""
  }
};
