import axios from "axios";

import { ActionType } from "../../models/Action.model";
import { IPolicyCollection } from "../models/IPolicy";
import {
  Configuration,
  eramaApiHeaders,
  esmaApiHeaders,
  subscriptionsPrefixURL
} from "../../config";
import { Thunk } from "../../store";
import { policyCollectionReducerTypes } from "../reducers/policyCollectionReducer";
import {
  createBlockableDispatch,
  ServiceUtilities
} from "../../core/utilities/ServiceUtilities";
import {
  addPolicyCollectionSubscription,
  policyCollectionFromApi
} from "../services/PolicyService";
import { checkError, SnackbarError } from "../../core/utilities/AlertUtilities";
import {
  getSubscriptionCollectionObject,
  getSubscriptionObject
} from "../../UsersBySubscription/services/UsersBySubscriptionService";
import {
  subscriptionCollectionFromApi,
  subscriptionFromApi
} from "../../Subscriptions/services/SubscriptionService";
import { ISubscriptionSelection } from "../../models/shared/IShared";
import { initialSubscription } from "../../Subscriptions/models/ISubscription";

export const getPolicyCollection: Thunk<policyCollectionReducerTypes> = (
  subId: string,
  masterAdmin: boolean
) => {
  return async (
    dispatch,
    _,
    opt
  ): Promise<IPolicyCollection | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.POLICY_COLLECTION_LOADING,
      payload: true
    });
    try {
      let response;
      if (masterAdmin) {
        response = await axios.all([
          axios.get(Configuration.EramaAPIUrl + "/policies", {
            ...eramaApiHeaders,
            params: {
              subscription:
                subscriptionsPrefixURL + ServiceUtilities.getShortId(subId)
            }
          }),
          axios.get(Configuration.EsmaAPIUrl + "/subscriptions", esmaApiHeaders)
        ]);
      } else {
        response = await axios.all([
          axios.get(Configuration.EramaAPIUrl + "/policies", eramaApiHeaders),
          axios.get(Configuration.EsmaAPIUrl + "/subscriptions", esmaApiHeaders)
        ]);
      }

      let policies = {
        ...addPolicyCollectionSubscription(
          policyCollectionFromApi(response[0].data),
          response[1].data
        )
      };

      let subCollection = getSubscriptionCollectionObject(
        subscriptionCollectionFromApi(response[1].data)
      );

      for (let policyIndex in policies.members) {
        if (
          policies.members.hasOwnProperty(policyIndex) &&
          policies.members[policyIndex].subscription.name === ""
        ) {
          const subId = policies.members[policyIndex].subscription.id;
          const SubExisted = subCollection
            .filter(sub => sub.value === subId)
            .pop();

          if (SubExisted) {
            policies.members[policyIndex].subscription = {
              ...initialSubscription,
              id: SubExisted.value,
              name: SubExisted.label
            };
          } else {
            try {
              const responseSubById = await axios.get(
                Configuration.EsmaAPIBaseUrl +
                  policies.members[policyIndex].subscription.id,
                esmaApiHeaders
              );

              const fetchedSub = subscriptionFromApi(responseSubById.data);

              policies.members[policyIndex].subscription = {
                ...fetchedSub
              };

              subCollection = [
                ...subCollection,
                { value: fetchedSub.id, label: fetchedSub.name }
              ];
            } catch (err) {
              const { response } = err;
              if (response && response.status === 404) {
                continue;
              }
              return checkError(err);
            }
          }
        }
      }

      policies = {
        ...policies,
        policySubscriptionSelection: getSubscriptionObject(
          subscriptionsPrefixURL + ServiceUtilities.getShortId(subId),
          subCollection
        ),
        policySubscriptionSelectionList: subCollection
      };

      blockableDispatch({
        type: ActionType.GET_POLICY_COLLECTION,
        payload: policies
      });

      return policies;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.POLICY_COLLECTION_LOADING,
        payload: false
      });
    }
  };
};

export const changePolicyCollectionPage: Thunk<policyCollectionReducerTypes> = (
  page: string,
  subId: string,
  masterAdmin: boolean
) => {
  return async (
    dispatch,
    _,
    opt
  ): Promise<IPolicyCollection | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.POLICY_COLLECTION_LOADING,
      payload: true
    });
    try {
      let response;
      if (masterAdmin) {
        response = await axios.all([
          axios.get(Configuration.EramaAPIBaseUrl + page, {
            ...eramaApiHeaders,
            params: {
              subscription:
                subscriptionsPrefixURL + ServiceUtilities.getShortId(subId)
            }
          }),
          axios.get(Configuration.EsmaAPIUrl + "/subscriptions", esmaApiHeaders)
        ]);
      } else {
        response = await axios.all([
          axios.get(Configuration.EramaAPIBaseUrl + page, {
            ...eramaApiHeaders
          }),
          axios.get(Configuration.EsmaAPIUrl + "/subscriptions", esmaApiHeaders)
        ]);
      }

      let policies = {
        ...addPolicyCollectionSubscription(
          policyCollectionFromApi(response[0].data),
          response[1].data
        )
      };

      let subCollection = getSubscriptionCollectionObject(
        subscriptionCollectionFromApi(response[1].data)
      );

      for (let policyIndex in policies.members) {
        if (
          policies.members.hasOwnProperty(policyIndex) &&
          policies.members[policyIndex].subscription.name === ""
        ) {
          const subId = policies.members[policyIndex].subscription.id;
          const SubExisted = subCollection
            .filter(sub => sub.value === subId)
            .pop();

          if (SubExisted) {
            policies.members[policyIndex].subscription = {
              ...initialSubscription,
              id: SubExisted.value,
              name: SubExisted.label
            };
          } else {
            try {
              const responseSubById = await axios.get(
                Configuration.EsmaAPIBaseUrl +
                  policies.members[policyIndex].subscription.id,
                esmaApiHeaders
              );

              const fetchedSub = subscriptionFromApi(responseSubById.data);

              policies.members[policyIndex].subscription = {
                ...fetchedSub
              };

              subCollection = [
                ...subCollection,
                { value: fetchedSub.id, label: fetchedSub.name }
              ];
            } catch (err) {
              const { response } = err;
              if (response && response.status === 404) {
                continue;
              }
              return checkError(err);
            }
          }
        }
      }

      policies = {
        ...policies,
        policySubscriptionSelection: getSubscriptionObject(
          subscriptionsPrefixURL + ServiceUtilities.getShortId(subId),
          subCollection
        ),
        policySubscriptionSelectionList: subCollection
      };

      blockableDispatch({
        type: ActionType.GET_POLICY_COLLECTION,
        payload: policies
      });
      return policies;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.POLICY_COLLECTION_LOADING,
        payload: false
      });
    }
  };
};

export const setPolicySubscriptionSelectionValue: Thunk<
  policyCollectionReducerTypes
> = (selectedValue: ISubscriptionSelection) => {
  return async dispatch => {
    dispatch({
      type: ActionType.SET_POLICY_SUBSCRIPTION_SELECTION,
      payload: selectedValue
    });
  };
};
