import React from "react";
import { TableHead, TableRow } from "@material-ui/core";

import {
  ButtonTableCell,
  StyledTableCell
} from "../../../components/sharedStyledComponents";

export const DeviceTableHeader: React.FunctionComponent = () => (
  <TableHead>
    <TableRow>
      <StyledTableCell>Name</StyledTableCell>
      <StyledTableCell>Serial</StyledTableCell>
      <StyledTableCell>Device Model</StyledTableCell>
      <StyledTableCell>Note</StyledTableCell>
      <ButtonTableCell />
      <ButtonTableCell />
    </TableRow>
  </TableHead>
);
