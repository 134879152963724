import React from "react";
import { connect } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { Formik } from "formik";
import { FormikActions, FormikProps } from "formik/dist/types";
import { RouteComponentProps } from "react-router";
import { withSnackbar, WithSnackbarProps } from "notistack";

import { withThemeProvider } from "../util/withThemeProvider";
import { IRootState } from "../store";
import { IUser, ISubscriptionWithRole } from "./models/IUser";
import { CreateUserForm } from "./components/CreateUserForm";
import { EditUserForm } from "./components/EditUserForm";
import {
  clearUser,
  createUser,
  getUserById,
  initializeUser,
  updateUserById,
  getRolesBySubForUserHasRoles
} from "./actions/UserAction";
import {
  NoStyleLink,
  StyledTitle,
  StyledBackground,
  StyledFormWrapper,
  StyledListIcon
} from "../components/sharedStyledComponents";
import {
  alertMessages,
  SnackbarError,
  isSnackbarError,
  getStyledSnackbarOptions
} from "../core/utilities/AlertUtilities";

interface IPathParamsType {
  id: string;
}

interface IEditUserStateProps {
  user: IUser;
}

interface IEditUserDispatchProps {
  initializeUser: (setLoadingState: boolean) => Promise<IUser | SnackbarError>;
  createUser: (user: IUser) => Promise<IUser | SnackbarError>;
  getUser: (id: string) => Promise<undefined | SnackbarError[]>;
  updateUser: (id: string, user: IUser) => Promise<undefined | SnackbarError[]>;
  getRolesBySubForUserHasRoles: (
    selectedSub: ISubscriptionWithRole[],
    userFormValue: IUser
  ) => Promise<IUser | SnackbarError>;
  clearUser: () => void;
}

type EditUserType = IEditUserStateProps &
  IEditUserDispatchProps &
  RouteComponentProps<IPathParamsType> &
  WithSnackbarProps;

export class EditUser extends React.Component<EditUserType> {
 public checkCollectionErrors = (response?: SnackbarError[]) => {
    const { enqueueSnackbar } = this.props;
    if (response && response.length > 0) {
      response.forEach(response => {
        if (isSnackbarError(response)) {
          enqueueSnackbar(response.message, response.options);
        }
      });
    }
  };

  public async componentDidMount() {
    const {
      initializeUser,
      getUser,
      enqueueSnackbar,
      match: {
        params: { id }
      }
    } = this.props;

    const result = await initializeUser(!Boolean(id));

    if (isSnackbarError(result)) {
      enqueueSnackbar(result.message, result.options);
    } else if (id) {
      const getUserResult = await getUser(id);
      this.checkCollectionErrors(getUserResult);
    }
  }

  public componentWillUnmount() {
    const {
      clearUser,
      match: {
        params: { id }
      }
    } = this.props;

    if (id) {
      clearUser();
    }
  }

  public onSubmit = async (
    values: IUser,
    { setSubmitting }: FormikActions<IUser>
  ) => {
    const {
      createUser,
      updateUser,
      history,
      enqueueSnackbar,
      match: {
        params: { id }
      }
    } = this.props;
    if (values) {
      if (id) {
        const updateResult = await updateUser(values.id, values);

        if (updateResult) {
          this.checkCollectionErrors(updateResult);
        } else {
          enqueueSnackbar(
            alertMessages.USER_UPDATE_SUCCESS,
            getStyledSnackbarOptions("success")
          );
        }
      } else {
        const createResult = await createUser(values);

        if (!isSnackbarError(createResult) && createResult !== undefined) {
          enqueueSnackbar(
            alertMessages.USER_CREATE_SUCCESS,
            getStyledSnackbarOptions("success")
          );
          history.push("/users/" + createResult.shortId);
        } else if (isSnackbarError(createResult)) {
          enqueueSnackbar(createResult.message, createResult.options);
        }
      }
      setSubmitting(false);
    }
  };

  public handleSubChange = async (
    selectedSub: ISubscriptionWithRole[],
    userFormValue: IUser
  ) => {
    const { getRolesBySubForUserHasRoles, enqueueSnackbar } = this.props;
    const result = await getRolesBySubForUserHasRoles(
      selectedSub,
      userFormValue
    );
    if (isSnackbarError(result)) {
      enqueueSnackbar(result.message, result.options);
    }
  };

  public renderEditUserForm = (formikProps: FormikProps<IUser>) => {
    return <EditUserForm {...formikProps} onSubChange={this.handleSubChange} />;
  };

  public renderCreateUserForm = (formikProps: FormikProps<IUser>) => {
    return (
      <CreateUserForm {...formikProps} onSubChange={this.handleSubChange} />
    );
  };

  public render() {
    const {
      user,
      match: {
        params: { id }
      }
    } = this.props;

    return (
      <React.Fragment>
        <StyledFormWrapper>
          <StyledTitle marginbottom="10px">
            {id ? (user.isLoading ? "Loading..." : user.name) : "New User"}
          </StyledTitle>
          <StyledBackground>
            <Grid container={true} item={true} xs={12} justify="flex-end">
              <NoStyleLink to="/users">
                <Button color="default">
                  <StyledListIcon />
                  List
                </Button>
              </NoStyleLink>
            </Grid>
            {id ? (
              <Formik
                enableReinitialize={true}
                initialValues={user}
                onSubmit={this.onSubmit}
                render={this.renderEditUserForm}
              />
            ) : (
              <Formik
                enableReinitialize={true}
                initialValues={user}
                onSubmit={this.onSubmit}
                render={this.renderCreateUserForm}
              />
            )}
          </StyledBackground>
        </StyledFormWrapper>

        <br />
        <br />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IEditUserStateProps => ({
  user: state.userReducer
});

const mapDispatchToProps = (dispatch: any): IEditUserDispatchProps => {
  return {
    initializeUser: (
      setLoadingState: boolean
    ): Promise<IUser | SnackbarError> => {
      return dispatch(initializeUser(setLoadingState));
    },
    createUser: (user: IUser): Promise<IUser | SnackbarError> => {
      return dispatch(createUser(user));
    },
    getUser: (id: string): Promise<undefined | SnackbarError[]> => {
      return dispatch(getUserById(id));
    },
    updateUser: (
      id: string,
      user: IUser
    ): Promise<undefined | SnackbarError[]> => {
      return dispatch(updateUserById(id, user));
    },
    getRolesBySubForUserHasRoles: (
      selectedSub: ISubscriptionWithRole[],
      userFormValue: IUser
    ): Promise<IUser | SnackbarError> => {
      return dispatch(getRolesBySubForUserHasRoles(selectedSub, userFormValue));
    },
    clearUser: () => {
      dispatch(clearUser());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(EditUser)));
