import React from "react";
import { Field, FormikProps } from "formik";
import styled from "styled-components";
import {
  Button,
  LinearProgress,
  Grid,
  Typography,
  NoSsr
} from "@material-ui/core";

import { Validators } from "../../util/validators";
import { IUserProfile } from "../models/IUserProfile";
import { StyledTextField, TextField } from "../../components/TextField";
import { CheckboxWithLabel } from "../../components/CheckboxWithLabel";
import { StyledAutocompleteSelectLabel } from "../../components/sharedStyledComponents";
import {
  AutocompleteSelect,
  StyledAutocomplete
} from "../../components/AutocompleteSelect";
import { ISubscriptionWithRole } from "../../Users/models/IUser";
import { HighlightedBox } from "./HighlightedBox";

export const CheckboxSegmentLabel = styled(Typography)`
  margin-top: 20px;
`;
CheckboxSegmentLabel.displayName = "CheckboxSegmentLabel";

export const UserProfileCheckbox = styled(Field)`
  height: 35px;
`;
UserProfileCheckbox.displayName = "UserProfileCheckbox";

export const EditUserProfileForm: React.FunctionComponent<
  FormikProps<IUserProfile>
> = ({
  submitForm,
  setFieldValue,
  isSubmitting,
  values
}: FormikProps<IUserProfile>) => {
  const { isLoading, activeSubscription, hasRoles } = values;
  const {
    editUserProfile: { email, name, role }
  } = Validators;

  const activeSubscriptionHandleChange = (
    selectedSub: ISubscriptionWithRole
  ) => {
    setFieldValue("activeSubscription", selectedSub);

    if (selectedSub.roles.length === 1) {
      setFieldValue("activeRole", selectedSub.roles[0]);
    } else {
      setFieldValue("activeRole", "");
    }
  };

  return (
    <form>
      <HighlightedBox title="Switch Role">
        <NoSsr>
          <StyledAutocompleteSelectLabel htmlFor="activeSubscription">
            Active Subscription
          </StyledAutocompleteSelectLabel>

          <StyledAutocomplete>
            <Field
              name="activeSubscription"
              options={hasRoles}
              component={AutocompleteSelect}
              isDisabled={isSubmitting || isLoading}
              labelField="name"
              identityKey="id"
              placeholder="Active Subscription"
              onChange={activeSubscriptionHandleChange}
            />
          </StyledAutocomplete>
        </NoSsr>
        <br />

        <NoSsr>
          <StyledAutocompleteSelectLabel htmlFor="activeRole">
            Active Role
          </StyledAutocompleteSelectLabel>

          <StyledAutocomplete>
            <Field
              name="activeRole"
              options={activeSubscription[`roles`]}
              component={AutocompleteSelect}
              isDisabled={isSubmitting || isLoading}
              labelField="name"
              identityKey="id"
              placeholder="Active Role"
              validate={role}
            />
          </StyledAutocomplete>
        </NoSsr>
      </HighlightedBox>
      <br />

      <StyledTextField
        type="text"
        label="Full Name"
        name="name"
        validate={name}
        disabled={isLoading || isSubmitting}
        component={TextField}
      />
      <br />
      <StyledTextField
        type="text"
        label="Email"
        name="email"
        validate={email}
        disabled={true}
        component={TextField}
      />
      <br />
      <StyledTextField
        type="text"
        label="Mobile"
        name="mobile"
        disabled={isLoading || isSubmitting}
        component={TextField}
      />
      <br />
      <br />
      <CheckboxSegmentLabel align="left" variant="caption">
        How do you prefer to receive alerts?
      </CheckboxSegmentLabel>
      <br />
      <UserProfileCheckbox
        color="primary"
        Label={{ label: "SMS" }}
        name="smsNotification"
        component={CheckboxWithLabel}
        disabled={isLoading || isSubmitting}
      />
      <br />
      <UserProfileCheckbox
        color="primary"
        Label={{ label: "Email" }}
        name="emailNotification"
        component={CheckboxWithLabel}
        disabled={isLoading || isSubmitting}
      />
      <br />
      <br />

      {(isLoading || isSubmitting) && <LinearProgress />}
      <br />
      <Grid
        container={true}
        item={true}
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Button
          variant="contained"
          color="primary"
          disabled={isLoading || isSubmitting}
          onClick={submitForm}
        >
          Save
        </Button>
      </Grid>
    </form>
  );
};
EditUserProfileForm.displayName = "EditUserProfileForm";
