import { IApiResponseHeader } from "../../models/IApiResponseHeader";
import { IDetermination, ILoadable } from "../../core/models/MixinInterfaces";
import {
  ISubscription,
  initialSubscription
} from "../../Subscriptions/models/ISubscription";
import { IPolicy } from "../../Policies/models/IPolicy";
import {
  ISubscriptionSelection,
  ITableDeleteCell,
  ICollection
} from "../../models/shared/IShared";

export interface IAppliedPoliciesApi {
  order: number;
  policy: string;
}

export interface IAppliedPolicies extends IPolicy {
  order: number;
}

export interface IRoleApi extends IApiResponseHeader {
  name: string;
  subscription: string;
  appliedPolicies: IAppliedPoliciesApi[];
}

export interface IRoleCollectionApi extends IApiResponseHeader, ICollection {
  "@context": string;
  member: IRoleApi[];
  totalItems: number;
  page?: number;
}

export interface IRole extends ILoadable, IDetermination {
  name: string;
  subscription: ISubscription;
  subscriptionList: ISubscription[];
  policyList: IPolicy[];
  appliedPolicies: IAppliedPolicies[];
  roleDelete: ITableDeleteCell;
}
export interface IRoleCollection
  extends ILoadable,
    IDetermination,
    ICollection {
  context?: string;
  members: IRole[];
  totalItems: number;
  page: number;
  subscriptionSelection: ISubscriptionSelection;
  subscriptionSelectionList: ISubscriptionSelection[];
}

export const initialRole: IRole = {
  id: "",
  shortId: "",
  isLoading: false,
  type: "",
  name: "",
  subscription: { ...initialSubscription },
  subscriptionList: [],
  policyList: [],
  appliedPolicies: [],
  roleDelete: {
    visible: false,
    id: "",
    title: ""
  }
};

export const initialRoleCollection: IRoleCollection = {
  id: "",
  shortId: "",
  isLoading: false,
  type: "",
  members: [],
  page: 0,
  totalItems: 0,
  subscriptionSelection: { label: "", value: "" },
  subscriptionSelectionList: [],
  view: {
    "@id": "",
    "@type": "",
    first: "",
    last: "",
    next: "",
    previous: ""
  }
};
