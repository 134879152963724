import React from "react";
import { Grid, Button } from "@material-ui/core";

import {
  NoStyleLink,
  SmallDeleteIcon,
  MobileViewInnerGrid,
  MobileViewTypography,
  MobileViewWrapper,
  ItemValuesMobileViewGrid
} from "../sharedStyledComponents";
import { ITableDeleteCell, IMobileTableRow } from "../../models/shared/IShared";

interface IProps {
  item: IMobileTableRow;
  DeleteDialog?: (itemInfo: ITableDeleteCell) => (_: React.MouseEvent) => void;
}

export const MobileTableRow: React.FunctionComponent<IProps> = ({
  item: { id, title, fields, editPageLink },
  DeleteDialog
}: IProps) => {
  return (
    <MobileViewWrapper>
      <MobileViewInnerGrid container={true} variant="title">
        <Grid item={true} xs={DeleteDialog ? 8 : 12}>
          <NoStyleLink to={editPageLink} id="Title">
            <MobileViewTypography variant="subtitle1" color="primary">
              {title}
            </MobileViewTypography>
          </NoStyleLink>
        </Grid>
        {DeleteDialog && (
          <React.Fragment>
            <Grid item={true} xs={2} />
            <Grid item={true} xs={2} sm={1}>
              <Button
                color="primary"
                onClick={DeleteDialog({
                  visible: true,
                  id: id,
                  title: title
                })}
              >
                <SmallDeleteIcon />
              </Button>
            </Grid>
          </React.Fragment>
        )}
      </MobileViewInnerGrid>
      {fields.map(({ label, value, noWrap }, index) => {
        return (
          <MobileViewInnerGrid container={true} variant="property" key={index}>
            <Grid container={true}>
              <Grid item={true} xs={noWrap ? 2 : 4}>
                <MobileViewTypography variant="subtitle1" color="secondary">
                  {label}
                </MobileViewTypography>
              </Grid>
              <Grid item={true} xs={1} />
              <ItemValuesMobileViewGrid item={true} xs={noWrap ? 9 : 7}>
                <MobileViewTypography variant="subtitle1" noWrap={noWrap}>
                  {value}
                </MobileViewTypography>
              </ItemValuesMobileViewGrid>
            </Grid>
          </MobileViewInnerGrid>
        );
      })}
    </MobileViewWrapper>
  );
};
