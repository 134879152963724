import React from "react";
import { TableBody } from "@material-ui/core";

import { IRole } from "../models/IRole";
import { StyledTable } from "../../components/sharedStyledComponents";
import { IPaginationView, ITableDeleteCell } from "../../models/shared/IShared";
import { RoleTableFooter } from "./RoleTable/RoleTableFooter";
import { RoleTableHeader } from "./RoleTable/RoleTableHeader";
import { RoleTableRow } from "./RoleTable/RoleTableRow";

export interface IRolesTableProps {
  pagination?: IPaginationView;
  isLoading?: boolean;
  rows: IRole[];
  totalItems?: number;
  onChangePage?: (page: string) => void;
  handleDialogOpen: (
    roleInfo: ITableDeleteCell
  ) => (_: React.MouseEvent) => void;
}

export const RolesTable: React.FunctionComponent<IRolesTableProps> = props => {
  return (
    <StyledTable>
      <RoleTableHeader />
      {!props.isLoading && (
        <React.Fragment>
          <TableBody>
            {props.rows.map(row => (
              <RoleTableRow
                key={row.id}
                role={row}
                handleDialogOpen={props.handleDialogOpen}
              />
            ))}
          </TableBody>
          <RoleTableFooter {...props} />
        </React.Fragment>
      )}
    </StyledTable>
  );
};
