import * as React from "react";

import { TableRow, Button } from "@material-ui/core";

import { ISubscription } from "../../models/ISubscription";
import {
  NoStyleLink,
  ButtonTableCell,
  SmallIcon,
  StyledTableCell
} from "../../../components/sharedStyledComponents";

export const SubscriptionTableRow: React.FunctionComponent<ISubscription> = ({
  id,
  shortId,
  name,
  owner: { name: ownerName }
}: ISubscription) => (
  <TableRow key={id}>
    <StyledTableCell> {name} </StyledTableCell>
    <StyledTableCell> {ownerName} </StyledTableCell>
    <ButtonTableCell>
      <NoStyleLink to={"/subscriptions/" + shortId}>
        <Button color="primary">
          Edit <SmallIcon />
        </Button>
      </NoStyleLink>
    </ButtonTableCell>
  </TableRow>
);
