import React from "react";
import { FieldArray, Form, FormikProps } from "formik";
import { Button, LinearProgress, Grid } from "@material-ui/core";

import { StyledTextField, TextField } from "../../components/TextField";
import { Validators } from "../../util/validators";
import { IPolicy } from "../models/IPolicy";
import { PermissionsFormArray } from "./PolicyPermissions";

export const EditPolicyForm: React.FunctionComponent<FormikProps<IPolicy>> = ({
  submitForm,
  isSubmitting,
  values,
  setFieldValue
}: FormikProps<IPolicy>) => {
  const { isLoading } = values;
  const { name } = Validators.editPolicy;

  return (
    <Form>
      <StyledTextField
        type="text"
        label="Name"
        name="name"
        multiline={false}
        component={TextField}
        disabled={isSubmitting || isLoading}
        value={values.name}
        validate={name}
      />
      <br />

      <StyledTextField
        type="text"
        label="Subscription"
        name="subscription.name"
        multiline={false}
        component={TextField}
        disabled={true}
      />

      <FieldArray name="permissions" render={PermissionsFormArray} />

      <br />
      {(isSubmitting || isLoading) && <LinearProgress />}
      <br />
      <Grid
        container={true}
        item={true}
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting || isLoading}
          onClick={submitForm}
        >
          Save
        </Button>
      </Grid>
    </Form>
  );
};
