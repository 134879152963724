import axios from "axios";

import { ActionType } from "../../models/Action.model";
import {
  ISubscriptionCollection,
  ISubscriptionCollectionApi
} from "../models/ISubscription";
import { Configuration, esmaApiHeaders } from "../../config";

import { IUserCollectionApi, IUserApi } from "../../Users/models/IUser";
import { Thunk } from "../../store";
import { subscriptionCollectionReducerTypes } from "../reducers/subscriptionCollectionReducer";
import { createBlockableDispatch } from "../../core/utilities/ServiceUtilities";
import {
  addSubscriptionCollectionOwner,
  subscriptionCollectionFromApi,
  addSubscriptionCollectionSingleOwner
} from "../services/SubscriptionService";
import { checkError, SnackbarError } from "../../core/utilities/AlertUtilities";

export const getSubscriptionCollection: Thunk<
  subscriptionCollectionReducerTypes
> = () => {
  return async (
    dispatch,
    getState,
    opt
  ): Promise<ISubscriptionCollection | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.SUBSCRIPTION_COLLECTION_LOADING,
      payload: true
    });
    try {
      const responseSubs = await axios.get(
        Configuration.EsmaAPIUrl + "/subscriptions",
        esmaApiHeaders
      );
      const subscriptions: ISubscriptionCollection = subscriptionCollectionFromApi(
        responseSubs.data as ISubscriptionCollectionApi
      );

      try {
        const responseUsers = await axios.get(
          Configuration.EsmaAPIUrl + "/users",
          esmaApiHeaders
        );

        const users: IUserCollectionApi = responseUsers.data as IUserCollectionApi;

        const subscriptionsWithOwners = addSubscriptionCollectionOwner(
          subscriptions,
          users
        );

        blockableDispatch({
          type: ActionType.GET_SUBSCRIPTION_COLLECTION,
          payload: subscriptionsWithOwners
        });
        return subscriptionsWithOwners;
      } catch (err) {
        if (err.response.status === 403) {
          const {
            globalState: {
              user: { userId }
            }
          } = getState();

          const responseUser = await axios.get(
            Configuration.EsmaAPIUrl + "/users/" + userId,
            esmaApiHeaders
          );

          const user: IUserApi = responseUser.data as IUserApi;

          const subscriptionsWithOwners = addSubscriptionCollectionSingleOwner(
            subscriptions,
            user
          );
          blockableDispatch({
            type: ActionType.GET_SUBSCRIPTION_COLLECTION,
            payload: subscriptionsWithOwners
          });
          return subscriptionsWithOwners;
        } else {
          return checkError(err);
        }
      }
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.SUBSCRIPTION_COLLECTION_LOADING,
        payload: false
      });
    }
  };
};

export const changeSubscriptionCollectionPage: Thunk<
  subscriptionCollectionReducerTypes
> = (page: string) => {
  return async (
    dispatch,
    getState,
    opt
  ): Promise<ISubscriptionCollection | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.SUBSCRIPTION_COLLECTION_LOADING,
      payload: true
    });
    try {
      const responseSubs = await axios.get(
        Configuration.EsmaAPIBaseUrl + page,
        {
          ...esmaApiHeaders
        }
      );
      const subscriptions: ISubscriptionCollection = subscriptionCollectionFromApi(
        responseSubs.data as ISubscriptionCollectionApi
      );

      try {
        const responseUsers = await axios.get(
          Configuration.EsmaAPIUrl + "/users",
          esmaApiHeaders
        );

        const users: IUserCollectionApi = responseUsers.data as IUserCollectionApi;

        const subscriptionsWithOwners = addSubscriptionCollectionOwner(
          subscriptions,
          users
        );

        blockableDispatch({
          type: ActionType.GET_SUBSCRIPTION_COLLECTION,
          payload: subscriptionsWithOwners
        });
        return subscriptionsWithOwners;
      } catch (err) {
        if (err.response.status === 403) {
          const {
            globalState: {
              user: { userId }
            }
          } = getState();

          const responseUser = await axios.get(
            Configuration.EsmaAPIUrl + "/users/" + userId,
            esmaApiHeaders
          );

          const user: IUserApi = responseUser.data as IUserApi;

          const subscriptionsWithOwners = addSubscriptionCollectionSingleOwner(
            subscriptions,
            user
          );

          blockableDispatch({
            type: ActionType.GET_SUBSCRIPTION_COLLECTION,
            payload: subscriptionsWithOwners
          });
          return subscriptionsWithOwners;
        } else {
          return checkError(err);
        }
      }
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.SUBSCRIPTION_COLLECTION_LOADING,
        payload: false
      });
    }
  };
};
